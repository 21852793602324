<template>
    <AdminViewPayment></AdminViewPayment>
</template>
<script>
    import AdminViewPayment from '@/components/AdminViewPayment.vue'
    export default {
        name: 'employee-view-request',
        components: {
            AdminViewPayment
        }
    }
</script>