<template>
    <EditPayment3></EditPayment3>
</template>
<script>
    import EditPayment3 from '@/components/EditPayment3.vue'
    export default {
        name: 'create-payment-3',
        components: {
            EditPayment3
        }
    }
</script>