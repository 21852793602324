<template>
    <ApproveLiquidation></ApproveLiquidation>
</template>
<script>
    import ApproveLiquidation from '@/components/ApproveLiquidation.vue'
    export default {
        name: 'employee-view-request',
        components: {
            ApproveLiquidation
        }
    }
</script>