<template>
    <Create25Liquidation3></Create25Liquidation3>
</template>
<script>
    import Create25Liquidation3 from '@/components/Create25Liquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create25Liquidation3
        }
    }
</script>