<template>
    <CreateRequest5></CreateRequest5>
</template>
<script>
    import CreateRequest5 from '@/components/CreateRequest5.vue'
    export default {
        name: 'create-request-5',
        components: {
            CreateRequest5
        }
    }
</script>