<template>
    <EmployeeViewPayment></EmployeeViewPayment>
</template>
<script>
    import EmployeeViewPayment from '@/components/EmployeeViewPayment.vue'
    export default {
        name: 'employee-view-payment',
        components: {
            EmployeeViewPayment
        }
    }
</script>