<template>
    <CreatePaymentLiquidation4></CreatePaymentLiquidation4>
</template>
<script>
    import CreatePaymentLiquidation4 from '@/components/CreatePaymentLiquidation4.vue'
    export default {
        name: 'create-payment-liquidation',
        components: {
            CreatePaymentLiquidation4
        }
    }
</script>