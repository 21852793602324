<template>
    <PositionManagement></PositionManagement>
</template>
<script>
    import PositionManagement from '@/components/PositionManagement.vue'
    export default {
        name: 'funding-management',
        components: {
            PositionManagement
        }
    }
</script>