<template>
    <EditPaymentLiquidation3></EditPaymentLiquidation3>
</template>
<script>
    import EditPaymentLiquidation3 from '@/components/EditPaymentLiquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditPaymentLiquidation3
        }
    }
</script>