<template>
    <EgpafPerDiem></EgpafPerDiem>
</template>
<script>
    import EgpafPerDiem from '@/components/EgpafPerDiem.vue'
    export default {
        name: 'funding-management',
        components: {
            EgpafPerDiem
        }
    }
</script>