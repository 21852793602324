<template>
    <AdminViewLiquidation></AdminViewLiquidation>
</template>
<script>
    import AdminViewLiquidation from '@/components/AdminViewLiquidation.vue'
    export default {
        name: 'employee-view-request',
        components: {
            AdminViewLiquidation
        }
    }
</script>