<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Create 75% Liquidation Step 2</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Create 75% Liquidation</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer animate-pulse text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 text-left py-8 px-6 pl-6 cursor-pointer ring-gray-200 shadow-md mr-6"
              >
                <!-- {{ liquidation75Request }} -->
                <div v-if="showSheet" class="space-y-4">
                        <div class="flex justify-between">
                          <span></span>
                          <button @click="reset()" class=" bg-emerald-500 px-10 py-0.5 shadow-md text-size text-white">Reset</button>
                        </div>
                        <!-- the voucher type -->
                        <div class="col-span-full sm:col-span-1 mb-6">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Voucher Type <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <div class=" w-full">
                              <CustomDropDown
                                :elementId="'voucher'"
                                :url="'/voucher/search'"
                                :persistedSelection="selectedType"
                                :multipleSelection="false"
                                @selectedItems="(items) => {
                                  selectedType = items
                                }"
                                :display="{
                                  id: 'id',
                                  name: ['name']
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- transaction no -->
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Transaction No <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              v-model="transactionNo"
                              required
                              placeholder="Transaction Number"
                              type="text"
                              class="bg-gray-50 text-size-md text-gray-600 w-full border mb-2 px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                        <!-- title -->
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Title <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              v-model="voucherTitle"
                              required
                              placeholder="Title"
                              type="text"
                              class="bg-gray-50 text-size-md text-gray-600 w-full border mb-2 px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                        <!-- the currency -->
                        <div class="col-span-full sm:col-span-1 mb-4">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Currency <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <div class=" w-full">
                              <CustomDropDown
                                :elementId="'currency'"
                                :url="'/currency/search'"
                                :persistedSelection="selectedCurrency"
                                :multipleSelection="false"
                                @selectedItems="(items) => {
                                  selectedCurrency = items
                                }"
                                :display="{
                                  id: 'id',
                                  name: ['name']
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- exchange rate -->
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Quarterly Average exc <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              v-model="exchangeRate"
                              required
                              placeholder="Rate"
                              type="number"
                              class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 mb-2 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                        <!-- {{ paymentVoucherDetails }} -->
                        <!-- pay to -->
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Pay To Employee <span class="font-bold text-red-500">*</span></label>
                          <div class=" w-full">
                              <CustomDropDown
                                :elementId="'payTo'"
                                :url="'/employee/search'"
                                :persistedSelection="payTo"
                                :multipleSelection="false"
                                @selectedItems="(items) => {
                                  payTo = items
                                }"
                                :display="{
                                  id: 'id',
                                  name: ['firstname', 'surname']
                                }"
                              />
                          </div>
                        </div>
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Pay To Vendor <span class="font-bold text-red-500">*</span></label>
                          <div class=" w-full">
                              <CustomDropDown
                                :elementId="'payToVendor'"
                                :url="'/vendor/search'"
                                :persistedSelection="payToVendor"
                                :multipleSelection="false"
                                @selectedItems="(items) => {
                                  payToVendor = items
                                }"
                                :display="{
                                  id: 'id',
                                  name: ['name']
                                }"
                              />
                          </div>
                        </div>
                        <!-- location -->
                        <div class="col-span-full sm:col-span-1 ">
                          <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Location <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              v-model="location"
                              required
                              placeholder="Location"
                              type="text"
                              class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 mb-2 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                      </div>
              </div>
              <div class="w-full flex text-center mx-auto pt-6">
                    <div class="flex-1">
                      <button
                        @click="back()"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Back
                      </button>
                      <button
                        @click="next()"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { interval } from 'rxjs'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import CustomDropDown from './CustomDropDown.vue';

export default {
  computed: {
    ...mapState([
      'employee',
      'liquidation75RequestData',
      'liquidation75VoucherDetails'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    CustomDropDown
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      showSheet: false,
      selectedType: {},
      transactionNo: '',
      voucherTitle: '',
      selectedCurrency: {},
      exchangeRate: '',
      payTo: {},
      payToVendor: {},
      location: '',


      title: 'Error Message',
      processMessage: '',
      selectedRequest: {},
      mieAdvance: '',
      lodgingAdvance: '',
      otherAdvance: '',
      showLiquidation: false
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 500)
      this.$store.commit('mutateSelectedSubMenuItem', 500)
      // load the vuex data
      this.loadLiquidation()
      // start saving the data
      this.autosave()
    }
  },
  methods: {
    // load the vuex data
    loadLiquidation () {
      this.showSheet = false
      // load the selected budget source
      if (this.liquidation75VoucherDetails !== null) {

        if (this.liquidation75VoucherDetails.type !== null && this.liquidation75VoucherDetails.type !== undefined) {
          this.selectedType = this.liquidation75VoucherDetails.type
        }

        if (this.liquidation75VoucherDetails.transactionNo !== null && this.liquidation75VoucherDetails.transactionNo !== undefined) {
          this.transactionNo = this.liquidation75VoucherDetails.transactionNo
        }

        if (this.liquidation75VoucherDetails.voucherTitle !== null && this.liquidation75VoucherDetails.voucherTitle !== undefined) {
          this.voucherTitle = this.liquidation75VoucherDetails.voucherTitle
        }

        if (this.liquidation75VoucherDetails.currency !== null && this.liquidation75VoucherDetails.currency !== undefined) {
          this.selectedCurrency = this.liquidation75VoucherDetails.currency
        }

        if (this.liquidation75VoucherDetails.exchangeRate !== null && this.liquidation75VoucherDetails.exchangeRate !== undefined) {
          this.exchangeRate = this.liquidation75VoucherDetails.exchangeRate
        }

        if (this.liquidation75VoucherDetails.payTo !== null && this.liquidation75VoucherDetails.payTo !== undefined) {
          this.payTo = this.liquidation75VoucherDetails.payTo
        }

        if (this.liquidation75VoucherDetails.payToVendor !== null && this.liquidation75VoucherDetails.payToVendor !== undefined) {
          this.payToVendor = this.liquidation75VoucherDetails.payToVendor
        }

        if (this.liquidation75VoucherDetails.location !== null && this.liquidation75VoucherDetails.location !== undefined) {
          this.location = this.liquidation75VoucherDetails.location
        }
      } else {
        this.reset()
      }

      this.showSheet = true
    },
    reset () {
      if (this.liquidation75RequestData !== null && this.liquidation75RequestData !== undefined) {
        // console.log(this.liquidation75RequestData)
        const advance = this.liquidation75RequestData.advance.advance
        const reasonOfTravel = advance.reasonOfTravel
        const currency = advance.currency
        const destinations = advance.destinations
        const destinationsSize = destinations.length
        const employeeId = this.liquidation75RequestData.employee_id
        const firstname = this.liquidation75RequestData.firstname
        const surname = this.liquidation75RequestData.surname

        const destinationNames = []
        if (!isNaN(destinationsSize)) {
          // iterate through the destinations
          for (let i = 0; i < destinationsSize; i++) {
            const item = destinations[i]
            // console.log(item)
            const name = item.allData.name
            destinationNames.push(name)
          }
        }

        const destinationSet = [...new Set(destinationNames)]
        // console.log(destinationSet)

        //  set the title, currency, locations
        this.voucherTitle = reasonOfTravel
        this.selectedCurrency = currency
        this.location = destinationSet.toString()
        this.payTo = { id: employeeId, name: `${firstname} ${surname}`, allData: { id: employeeId, firstname: firstname, surname: surname } }
      }
    },
    autosave () {
       // save details every 8 milliseconds
       const observable = interval(900 * 1 * 1)
      const observer = {
        next: () => {
          const data = {
            type: this.selectedType,
            transactionNo: this.transactionNo,
            voucherTitle: this.voucherTitle,
            currency: this.selectedCurrency,
            exchangeRate: this.exchangeRate,
            payTo: this.payTo,
            payToVendor: this.payToVendor,
            location: this.location
          }
          this.$store.commit('mutateLiquidation75VoucherDetails', data)
        },
        error: () => {}
      }
      observable.subscribe(observer)
    },
    // getting the pending activity approvals
    getRequest (id) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findById?id=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })
      const observer = {
        next: (response) => {
          const data = response.response
          const size = data.length
          // check the size
          if (size > 0) {
            // this.$store.commit('mutateLiquidation75RequestData', data[0])
          }
          // get the mie, other & lodging advances
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      },
      // move back
      back () {
        this.$router.push('/create-75liquidation-1')
      },
    // move to the next page
    next () {
      // save the data
      this.autosave()
      // validate the data
      if (this.selectedType.id !== undefined && this.transactionNo.trim() !== '' && this.voucherTitle.trim() !== '' && this.selectedCurrency.id !== undefined
        && !isNaN(this.exchangeRate) && (this.payTo.id !== undefined || this.payToVendor.id !== undefined) && this.location.trim() !== ''
      ) {
        this.$router.push('/create-75liquidation-3')
      } else {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please select the request to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
