<template>
    <CreateRequest4></CreateRequest4>
</template>
<script>
    import CreateRequest4 from '@/components/CreateRequest4.vue'
    export default {
        name: 'create-request-4',
        components: {
            CreateRequest4
        }
    }
</script>