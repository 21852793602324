<template>
    <Create75Liquidation4></Create75Liquidation4>
</template>
<script>
    import Create75Liquidation4 from '@/components/Create75Liquidation4.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create75Liquidation4
        }
    }
</script>