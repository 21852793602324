<template>
    <PendingRequests></PendingRequests>
</template>
<script>
    import PendingRequests from '@/components/PendingRequests.vue'
    export default {
        name: 'pending-requests',
        components: {
            PendingRequests
        }
    }
</script>