<template>
    <Edit75Liquidation3></Edit75Liquidation3>
</template>
<script>
    import Edit75Liquidation3 from '@/components/Edit75Liquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit75Liquidation3
        }
    }
</script>