<template>
    <Create25Liquidation1></Create25Liquidation1>
</template>
<script>
    import Create25Liquidation1 from '@/components/Create25Liquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create25Liquidation1
        }
    }
</script>