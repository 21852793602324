<template>
    <PendingLiquidations></PendingLiquidations>
</template>
<script>
    import PendingLiquidations from '@/components/PendingLiquidations.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            PendingLiquidations
        }
    }
</script>