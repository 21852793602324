<template>
    <BankManagement></BankManagement>
</template>
<script>
    import BankManagement from '@/components/BankManagement.vue'
    export default {
        name: 'funding-management',
        components: {
            BankManagement
        }
    }
</script>