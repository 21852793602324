<template>
    <PaymentsApprovalHistory></PaymentsApprovalHistory>
</template>
<script>
    import PaymentsApprovalHistory from '@/components/PaymentsApprovalHistory.vue'
    export default {
        name: 'payments-approval-history',
        components: {
            PaymentsApprovalHistory
        }
    }
</script>