<template>
    <div>
        <!-- The loader dialog box -->
        <TransitionRoot appear :show="isOpen" as="template">
            <Dialog as="div">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-black opacity-40" />
                </TransitionChild>
    
                <span class="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>
    
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                >
                    <div
                    class="inline-block border w-full bg-white max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-2xl"
                    >
                    <div class="mt-2 justify-center">
                        <div class="items-center">
                            <div class="mt-4 flex justify-center">
                                <!--- The login loader -->
                                <div>
                                    <div class="spinner">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div class=" text-center mt-5">
                                    <button
                                        type="button"
                                        class="text-md text-gray-500 text-sm focus:outline-none focus-visible:ring-2
                                                focus-visible:ring-offset-2 focus-visible:ring-white"
                                        >
                                            Processing please wait....
                                    </button>
                                </div>
                        </div>
                    </div>
                    </div>
                </TransitionChild>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

    export default {
        components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay
        // DialogTitle
    },
    setup () {
      const isOpen = ref(false)
      return {
        isOpen,
        closeModal () {
          isOpen.value = false
        },
        openModal () {
          isOpen.value = true
        }
      }
    },
    name: 'loader-modal',
    props: {
    },
    data: () => {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  }
</script>