<template>
    <EditLiquidation3></EditLiquidation3>
</template>
<script>
    import EditLiquidation3 from '@/components/EditLiquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditLiquidation3
        }
    }
</script>