<template>
    <Edit75Liquidation2></Edit75Liquidation2>
</template>
<script>
    import Edit75Liquidation2 from '@/components/Edit75Liquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit75Liquidation2
        }
    }
</script>