<template>
    <Approve75Liquidations></Approve75Liquidations>
</template>
<script>
    import Approve75Liquidations from '@/components/Approve75Liquidations.vue'
    export default {
        name: 'approve-requests',
        components: {
            Approve75Liquidations
        }
    }
</script>