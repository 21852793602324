<template>
    <Edit25Liquidation1></Edit25Liquidation1>
</template>
<script>
    import Edit25Liquidation1 from '@/components/Edit25Liquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit25Liquidation1
        }
    }
</script>