<template>
    <RejectedRequests></RejectedRequests>
</template>
<script>
    import RejectedRequests from '@/components/RejectedRequests.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            RejectedRequests
        }
    }
</script>