<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style> 
   .text-title {
      font-size: 0.68em;
   }

   .text-size {
      font-size: 0.80em;
   }

   .text-size-input {
      font-size: 0.78em;
   }

   .text-size-sm {
      font-size: 0.68em;
   }

   .text-size-md {
      font-size: 0.70em;
   }

   .text-size-xs {
      font-size: 0.66em;
   }

   /* for sm devices */
   @media (min-width: 640px) {
   .margin {
      margin-left: 0rem;
   } 
   }

   /* for md devices */
   @media (min-width: 768px) {
   .margin {
      margin-left: 19rem;
   }
   }

   #app {
      /* font-family: Avenir, Helvetica, Arial, sans-serif; */
      /* font-family: "Roboto", sans-serif; */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
   }

   .login-bg {
      background-image: url('./assets/stacked-steps-haikei.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
   }

  .spinner {
      position: relative;
      width: 10px;
      height: 10px;
   }

   .spinner div {
      animation: spinner-4t3wzl 1.875s infinite backwards;
      background-color: #10b981;
      border-radius: 50%;
      height: 100%;
      position: absolute;
      width: 100%;
   }

   .spinner div:nth-child(1) {
      animation-delay: 0.15s;
      background-color: rgba(5, 150, 105, 0.9);
   }

   .spinner div:nth-child(2) {
      animation-delay: 0.3s;
      background-color: rgba(5, 150, 105, 0.8);
   }

   .spinner div:nth-child(3) {
      animation-delay: 0.45s;
      background-color: rgba(5, 150, 105, 0.7);
   }

   .spinner div:nth-child(4) {
      animation-delay: 0.6s;
      background-color: rgba(5, 150, 105, 0.6);
   }

   .spinner div:nth-child(5) {
      animation-delay: 0.75s;
      background-color: rgba(5, 150, 105, 0.5);
   }

   @keyframes spinner-4t3wzl {
      0% {
         transform: rotate(0deg) translateY(-200%);
      }

      60%, 100% {
         transform: rotate(360deg) translateY(-200%);
      }
   }

   #datatable {
  border-collapse: collapse;
  width: 100%;
}

#datatable td, #datatable th {
  border: 1px solid #ddd;
  padding: 12px;
}

#datatable tr:nth-child(even){background-color: rgba(249, 250, 251, 1);}

#datatable tr:hover {background-color: rgba(243, 244, 246, 1);}

#datatable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #059669;
  color: white;
}
</style>
