<template>
    <CreateLiquidation4></CreateLiquidation4>
</template>
<script>
    import CreateLiquidation4 from '@/components/CreateLiquidation4.vue'
    export default {
        name: 'create-liquidation',
        components: {
            CreateLiquidation4
        }
    }
</script>