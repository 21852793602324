<template>
    <EmployeeProfile></EmployeeProfile>
</template>
<script>
    import EmployeeProfile from '@/components/EmployeeProfile.vue'
    export default {
        name: 'employee-home',
        components: {
            EmployeeProfile
        }
    }
</script>