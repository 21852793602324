<template>
    <CreateLiquidation3></CreateLiquidation3>
</template>
<script>
    import CreateLiquidation3 from '@/components/CreateLiquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreateLiquidation3
        }
    }
</script>