<template>
    <CreateRequest2></CreateRequest2>
</template>
<script>
    import CreateRequest2 from '@/components/CreateRequest2.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreateRequest2
        }
    }
</script>