<template>
    <EmployeeTrackPayment></EmployeeTrackPayment>
</template>
<script>
    import EmployeeTrackPayment from '@/components/EmployeeTrackPayment.vue'
    export default {
        name: 'employee-track-payment',
        components: {
            EmployeeTrackPayment
        }
    }
</script>