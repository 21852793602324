<template>
    <Edit25Liquidation2></Edit25Liquidation2>
</template>
<script>
    import Edit25Liquidation2 from '@/components/Edit25Liquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit25Liquidation2
        }
    }
</script>