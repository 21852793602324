<template>
    <AdminViewRequest></AdminViewRequest>
</template>
<script>
    import AdminViewRequest from '@/components/AdminViewRequest.vue'
    export default {
        name: 'admin-view-request',
        components: {
            AdminViewRequest
        }
    }
</script>