import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login'
import AdminHome from '../views/AdminHome'
import FundingManagement from '../views/FundingManagement.vue'
import EmployeeManagement from '@/views/EmployeeManagement.vue'
import DeactivatedAccounts from '@/views/DeactivatedAccounts.vue'
import DelegateApprovals from '@/views/DelegateApprovals.vue'
import PositionManagement from '@/views/PositionManagement.vue'
import EmployeeBanking from '@/views/EmployeeBanking.vue'
import SupervisorManagement from '@/views/SupervisorManagement.vue'
import ApprovedLiquidations from '@/views/ApprovedLiquidations.vue'
import PendingLiquidations from '@/views/PendingLiquidations.vue'
import RejectedLiquidations from '@/views/RejectedLiquidations.vue'
import ApprovedPayments from '@/views/ApprovedPayments.vue'
import PendingPayments from '@/views/PendingPayments.vue'
import RejectedPayments from '@/views/RejectedPayments.vue'
import ApprovedRequests from '@/views/ApprovedRequests.vue'
import PendingRequests from '@/views/PendingRequests.vue'
import RejectedRequests from '@/views/RejectedRequests.vue'
import WithdrawnRequests from '@/views/WithdrawnRequests.vue'
import EgpafPerDiem from '@/views/EgpafPerDiem.vue'
import MohPerDiem from '@/views/MohPerDiem.vue'
import BankManagement from '@/views/BankManagement.vue'
import SpendPlanManagement from '@/views/SpendPlanManagement.vue'
import EmployeeHome from '@/views/EmployeeHome.vue'
import EmployeeProfile from '@/views/EmployeeProfile.vue'
import EmployeeSign from '@/views/EmployeeSign.vue'
import EmployeeUploadSignature from '@/views/EmployeeUploadSignature.vue'
import EmployeeDelegateApprovals from '@/views/EmployeeDelegateApprovals.vue'
import CreateRequest1 from '@/views/CreateRequest1.vue'
import CreateRequest2 from '@/views/CreateRequest2.vue'
import CreateRequest3 from '@/views/CreateRequest3.vue'
import CreateRequest4 from '@/views/CreateRequest4.vue'
import CreateRequest5 from '@/views/CreateRequest5.vue'
import CreateRequest6 from '@/views/CreateRequest6.vue'
import EmployeeRequests from '@/views/EmployeeRequests.vue'
import EmployeeViewRequest from '@/views/EmployeeViewRequest.vue'
import EmployeeTrackRequest from '@/views/EmployeeTrackRequest.vue'
import ApproveRequests from '@/views/ApproveRequests.vue'
import EmployeeApproveRequest from '@/views/EmployeeApproveRequest.vue'
import RequestApprovalHistory from '@/views/RequestApprovalHistory.vue'
import CreateLiquidation1 from '@/views/CreateLiquidation1.vue'
import CreateLiquidation2 from '@/views/CreateLiquidation2.vue'
import CreateLiquidation3 from '@/views/CreateLiquidation3.vue'
import CreateLiquidation4 from '@/views/CreateLiquidation4.vue'
import CreateLiquidation5 from '@/views/CreateLiquidation5.vue'
import EmployeeLiquidations from '@/views/EmployeeLiquidations.vue'
import EmployeeViewLiquidation from '@/views/EmployeeViewLiquidation.vue'
import EmployeeTrackLiquidation from '@/views/EmployeeTrackLiquidation.vue'
import EmployeeApproveLiquidation from '@/views/EmployeeApproveLiquidation.vue'
import ApproveLiquidation from '@/views/ApproveLiquidation.vue'
import LiquidationApprovalHistory from '@/views/LiquidationApprovalHistory.vue'
import AdminViewLiquidation from '@/views/AdminViewLiquidation.vue'
import AdminTrackLiquidation from '@/views/AdminTrackLiquidation.vue'
import AdminViewRequest from '@/views/AdminViewRequest.vue'
import AdminTrackRequest from '@/views/AdminTrackRequest.vue'
import CreatePayment1 from '@/views/CreatePayment1.vue'
import CreatePayment2 from '@/views/CreatePayment2.vue'
import OtherPerDiem from '@/views/OtherPerDiem.vue'
import CreatePayment3 from '@/views/CreatePayment3.vue'
import AdminViewPayment from '@/views/AdminViewPayment.vue'
import AdminTrackPayment from '@/views/AdminTrackPayment.vue'
import EmployeePayments from '@/views/EmployeePayments.vue'
import EmployeeTrackPayment from '@/views/EmployeeTrackPayment.vue'
import EmployeeViewPayment from '@/views/EmployeeViewPayment.vue'
import ApprovePayments from '@/views/ApprovePayments.vue'
import EmployeeApprovePayment from '@/views/EmployeeApprovePayment.vue'
import PaymentsApprovalHistory from '@/views/PaymentsApprovalHistory.vue'
import CreatePaymentLiquidation1 from '@/views/CreatePaymentLiquidation1.vue'
import CreatePaymentLiquidation2 from '@/views/CreatePaymentLiquidation2.vue'
import CreatePaymentLiquidation3 from '@/views/CreatePaymentLiquidation3.vue'
import CreatePaymentLiquidation4 from '@/views/CreatePaymentLiquidation4.vue'
import PaymentLiquidations from '@/views/PaymentLiquidations.vue'
import EmployeeViewPaymentLiquidation from '@/views/EmployeeViewPaymentLiquidation.vue'
import ApprovePaymentLiquidations from '@/views/ApprovePaymentLiquidations.vue'
import EmployeeTrackPaymentLiquidation from '@/views/EmployeeTrackPaymentLiquidation.vue'
import EmployeeApprovePaymentLiquidations from '@/views/EmployeeApprovePaymentLiquidations.vue'
import EditRequest1 from '@/views/EditRequest1.vue'
import EditRequest2 from '@/views/EditRequest2.vue'
import EditRequest3 from '@/views/EditRequest3.vue'
import EditRequest4 from '@/views/EditRequest4.vue'
import EditRequest5 from '@/views/EditRequest5.vue'
import EditRequest6 from '@/views/EditRequest6.vue'
import EditLiquidation1 from '@/views/EditLiquidation1.vue'
import EditLiquidation2 from '@/views/EditLiquidation2.vue'
import EditLiquidation3 from '@/views/EditLiquidation3.vue'
import EditLiquidation4 from '@/views/EditLiquidation4.vue'
import EditLiquidation5 from '@/views/EditLiquidation5.vue'
import EditPayment1 from '@/views/EditPayment1.vue'
import EditPayment2 from '@/views/EditPayment2.vue'
import EditPayment3 from '@/views/EditPayment3.vue'
import EditPaymentLiquidation1 from '@/views/EditPaymentLiquidation1.vue'
import EditPaymentLiquidation2 from '@/views/EditPaymentLiquidation2.vue'
import EditPaymentLiquidation3 from '@/views/EditPaymentLiquidation3.vue'
import EditPaymentLiquidation4 from '@/views/EditPaymentLiquidation4.vue'
import PaymentLiquidationsApprovalHistory from '@/components/PaymentLiquidationsApprovalHistory.vue'
import Create75Liquidation1 from '@/views/Create75Liquidation1.vue'
import Create75Liquidation2 from '@/views/Create75Liquidation2.vue'
import Create75Liquidation3 from '@/views/Create75Liquidation3.vue'
import Create75Liquidation4 from '@/views/Create75Liquidation4.vue'
import Employee75Liquidations from '@/views/Employee75Liquidations.vue'
import EmployeeView75Liquidation from '@/views/EmployeeView75Liquidation.vue'
import Approve75Liquidations from '@/views/Approve75Liquidations.vue'
import EmployeeTrack75Liquidation from '@/views/EmployeeTrack75Liquidation.vue'
import EmployeeApprove75Liquidation from '@/views/EmployeeApprove75Liquidation.vue'
import Liquidations75ApprovalHistory from '@/views/Liquidations75ApprovalHistory.vue'
import Create25Liquidation1 from '@/views/Create25Liquidation1.vue'
import Create25Liquidation2 from '@/views/Create25Liquidation2.vue'
import Create25Liquidation3 from '@/views/Create25Liquidation3.vue'
import Create25Liquidation4 from '@/views/Create25Liquidation4.vue'
import Employee25Liquidations from '@/views/Employee25Liquidations.vue'
import EmployeeView25Liquidation from '@/views/EmployeeView25Liquidation.vue'
import EmployeeTrack25Liquidation from '@/views/EmployeeTrack25Liquidation.vue'
import Approve25Liquidations from '@/views/Approve25Liquidations.vue'
import EmployeeApprove25Liquidation from '@/views/EmployeeApprove25Liquidation.vue'
import Liquidations25ApprovalHistory from '@/views/Liquidations25ApprovalHistory.vue'
import Edit75Liquidation1 from '@/views/Edit75Liquidation1.vue'
import Edit75Liquidation2 from '@/views/Edit75Liquidation2.vue'
import Edit75Liquidation3 from '@/views/Edit75Liquidation3.vue'
import Edit75Liquidation4 from '@/views/Edit75Liquidation4.vue'
import Edit25Liquidation1 from '@/views/Edit25Liquidation1.vue'
import Edit25Liquidation2 from '@/views/Edit25Liquidation2.vue'
import Edit25Liquidation3 from '@/views/Edit25Liquidation3.vue'
import Edit25Liquidation4 from '@/views/Edit25Liquidation4.vue'
import AdminProfile from '@/views/AdminProfile.vue'


const routes = [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin-home',
      name: 'admin-home',
      component: AdminHome
    },
    {
      path: '/funding-management',
      name: 'funding-management',
      component: FundingManagement
    },
    {
      path: '/employee-management',
      name: 'employee-management',
      component: EmployeeManagement
    },
    {
      path: '/deactivated-accounts',
      name: 'deactivated-accounts',
      component: DeactivatedAccounts
    },
    {
      path: '/delegate-approvals',
      name: 'delegate-approvals',
      component: DelegateApprovals
    },
    {
      path: '/position-management',
      name: 'position-management',
      component: PositionManagement
    },
    {
      path: '/employee-banking',
      name: 'employee-banking',
      component: EmployeeBanking
    },
    {
      path: '/supervisor-management',
      name: 'supervisor-management',
      component: SupervisorManagement
    },
    {
      path: '/approved-liquidations',
      name: 'approved-liquidations',
      component: ApprovedLiquidations
    },
    {
      path: '/pending-liquidations',
      name: 'pending-liquidations',
      component: PendingLiquidations
    },
    {
      path: '/rejected-liquidations',
      name: 'rejected-liquidations',
      component: RejectedLiquidations
    },
    {
      path: '/approved-payments',
      name: 'approved-payments',
      component: ApprovedPayments
    },
    {
      path: '/pending-payments',
      name: 'pending-payments',
      component: PendingPayments
    },
    {
      path: '/rejected-payments',
      name: 'rejected-payments',
      component: RejectedPayments
    },
    {
      path: '/approved-requests',
      name: 'approved-requests',
      component: ApprovedRequests
    },
    {
      path: '/pending-requests',
      name: 'pending-requests',
      component: PendingRequests
    },
    {
      path: '/rejected-requests',
      name: 'rejected-requests',
      component: RejectedRequests
    },
    {
      path: '/withdrawn-requests',
      name: 'withdrawn-requests',
      component: WithdrawnRequests
    },
    {
      path: '/egpaf-per-diem',
      name: 'egpaf-per-diem',
      component: EgpafPerDiem
    },
    {
      path: '/moh-per-diem',
      name: 'moh-per-diem',
      component: MohPerDiem
    },
    {
      path: '/bank-management',
      name: 'bank-management',
      component: BankManagement
    },
    {
      path: '/spend-plan-management',
      name: 'spend-plan-management',
      component: SpendPlanManagement
    },
    {
      path: '/employee-home',
      name: 'employee-home',
      component: EmployeeHome
    },
    {
      path: '/employee-profile',
      name: 'employee-profile',
      component: EmployeeProfile
    },
    {
      path: '/employee-sign',
      name: 'employee-sign',
      component: EmployeeSign
    },
    {
      path: '/employee-upload-signature',
      name: 'employee-upload-signature',
      component: EmployeeUploadSignature
    },
    {
      path: '/employee-delegate-approvals',
      name: 'employee-delegate-approvals',
      component: EmployeeDelegateApprovals
    },
    {
      path: '/create-request-1',
      name: 'create-request-1',
      component: CreateRequest1
    },
    {
      path: '/create-request-2',
      name: 'create-request-2',
      component: CreateRequest2
    },
    {
      path: '/create-request-3',
      name: 'create-request-3',
      component: CreateRequest3
    },
    {
      path: '/create-request-4',
      name: 'create-request-4',
      component: CreateRequest4
    },
    {
      path: '/create-request-5',
      name: 'create-request-5',
      component: CreateRequest5
    },
    {
      path: '/create-request-6',
      name: 'create-request-6',
      component: CreateRequest6
    },
    {
      path: '/employee-requests',
      name: 'employee-requests',
      component: EmployeeRequests
    },
    {
      path: '/employee-view-request',
      name: 'employee-view-request',
      component: EmployeeViewRequest
    },
    {
      path: '/employee-track-request',
      name: 'employee-track-request',
      component: EmployeeTrackRequest
    },
    {
      path: '/approve-requests',
      name: 'approve-requests',
      component: ApproveRequests
    },
    {
      path: '/employee-approve-request',
      name: 'employee-approve-request',
      component: EmployeeApproveRequest
    },
    {
      path: '/request-approval-history',
      name: 'request-approval-history',
      component: RequestApprovalHistory
    },
    {
      path: '/create-liquidation-1',
      name: 'create-liquidation-1',
      component: CreateLiquidation1
    },
    {
      path: '/create-liquidation-2',
      name: 'create-liquidation-2',
      component: CreateLiquidation2
    },
    {
      path: '/create-liquidation-3',
      name: 'create-liquidation-3',
      component: CreateLiquidation3
    },
    {
      path: '/create-liquidation-4',
      name: 'create-liquidation-4',
      component: CreateLiquidation4
    },
    {
      path: '/create-liquidation-5',
      name: 'create-liquidation-5',
      component: CreateLiquidation5
    },
    {
      path: '/employee-liquidations',
      name: 'employee-liquidations',
      component: EmployeeLiquidations
    },
    {
      path: '/employee-view-liquidation',
      name: 'employee-view-liquidation',
      component: EmployeeViewLiquidation
    },
    {
      path: '/employee-track-liquidation',
      name: 'employee-track-liquidation',
      component: EmployeeTrackLiquidation
    },
    {
      path: '/employee-approve-liquidation',
      name: 'employee-approve-liquidation',
      component: EmployeeApproveLiquidation
    },
    {
      path: '/approve-liquidation',
      name: 'approve-liquidation',
      component: ApproveLiquidation
    },
    {
      path: '/liquidation-approval-history',
      name: 'liquidation-approval-history',
      component: LiquidationApprovalHistory
    },
    {
      path: '/admin-view-liquidation',
      name: 'admin-view-liquidation',
      component: AdminViewLiquidation
    },
    {
      path: '/admin-track-liquidation',
      name: 'admin-track-liquidation',
      component: AdminTrackLiquidation
    },
    {
      path: '/admin-view-request',
      name: 'admin-view-request',
      component: AdminViewRequest
    },
    {
      path: '/admin-track-request',
      name: 'admin-track-request',
      component: AdminTrackRequest
    },
    {
      path: '/other-per-diem',
      name: 'other-per-diem',
      component: OtherPerDiem
    },
    {
      path: '/create-payment-1',
      name: 'create-payment-1',
      component: CreatePayment1
    },
    {
      path: '/create-payment-2',
      name: 'create-payment-2',
      component: CreatePayment2
    },
    {
      path: '/create-payment-3',
      name: 'create-payment-3',
      component: CreatePayment3
    },
    {
      path: '/admin-view-payment',
      name: 'admin-view-payment',
      component: AdminViewPayment
    },
    {
      path: '/admin-track-payment',
      name: 'admin-track-payment',
      component: AdminTrackPayment
    },
    {
      path: '/employee-payments',
      name: 'employee-payments',
      component: EmployeePayments
    },
    {
      path: '/employee-track-payment',
      name: 'employee-track-payment',
      component: EmployeeTrackPayment
    },
    {
      path: '/employee-view-payment',
      name: 'employee-view-payment',
      component: EmployeeViewPayment
    },
    {
      path: '/approve-payments',
      name: 'approve-payments',
      component: ApprovePayments
    },
    {
      path: '/employee-approve-payment',
      name: 'employee-approve-payment',
      component: EmployeeApprovePayment
    },
    {
      path: '/payments-approval-history',
      name: 'payments-approval-history',
      component: PaymentsApprovalHistory
    },
    {
      path: '/create-payment-liquidation-1',
      name: 'create-payment-liquidation-1',
      component: CreatePaymentLiquidation1
    },
    {
      path: '/create-payment-liquidation-2',
      name: 'create-payment-liquidation-2',
      component: CreatePaymentLiquidation2
    },
    {
      path: '/create-payment-liquidation-3',
      name: 'create-payment-liquidation-3',
      component: CreatePaymentLiquidation3
    },
    {
      path: '/create-payment-liquidation-4',
      name: 'create-payment-liquidation-4',
      component: CreatePaymentLiquidation4
    },
    {
      path: '/payment-liquidations',
      name: 'payment-liquidations',
      component: PaymentLiquidations
    },
    {
      path: '/view-payment-liquidation',
      name: 'view-payment-liquidation',
      component: EmployeeViewPaymentLiquidation
    },
    {
      path: '/approve-payment-liquidations',
      name: 'approve-payment-liquidations',
      component: ApprovePaymentLiquidations
    },
    {
      path: '/employee-track-paymentliquidation',
      name: 'employee-track-paymentliquidation',
      component: EmployeeTrackPaymentLiquidation
    },
    {
      path: '/employee-approve-paymentliquidations',
      name: 'employee-approve-paymentliquidations',
      component: EmployeeApprovePaymentLiquidations
    },
    {
      path: '/edit-request-1',
      name: 'edit-request-1',
      component: EditRequest1
    },
    {
      path: '/edit-request-2',
      name: 'edit-request-2',
      component: EditRequest2
    },
    {
      path: '/edit-request-3',
      name: 'edit-request-3',
      component: EditRequest3
    },
    {
      path: '/edit-request-4',
      name: 'edit-request-4',
      component: EditRequest4
    },
    {
      path: '/edit-request-5',
      name: 'edit-request-5',
      component: EditRequest5
    },
    {
      path: '/edit-request-6',
      name: 'edit-request-6',
      component: EditRequest6
    },
    {
      path: '/edit-liquidation-1',
      name: 'edit-liquidation-1',
      component: EditLiquidation1
    },
    {
      path: '/edit-liquidation-2',
      name: 'edit-liquidation-2',
      component: EditLiquidation2
    },
    {
      path: '/edit-liquidation-3',
      name: 'edit-liquidation-3',
      component: EditLiquidation3
    },
    {
      path: '/edit-liquidation-4',
      name: 'edit-liquidation-4',
      component: EditLiquidation4
    },
    {
      path: '/edit-liquidation-5',
      name: 'edit-liquidation-5',
      component: EditLiquidation5
    },
    {
      path: '/edit-payment-1',
      name: 'edit-payment-1',
      component: EditPayment1
    },
    {
      path: '/edit-payment-2',
      name: 'edit-payment-2',
      component: EditPayment2
    },
    {
      path: '/edit-payment-3',
      name: 'edit-payment-3',
      component: EditPayment3
    },
    {
      path: '/edit-payment-liquidation-1',
      name: 'edit-payment-liquidation-1',
      component: EditPaymentLiquidation1
    },
    {
      path: '/edit-payment-liquidation-2',
      name: 'edit-payment-liquidation-2',
      component: EditPaymentLiquidation2
    },
    {
      path: '/edit-payment-liquidation-3',
      name: 'edit-payment-liquidation-3',
      component: EditPaymentLiquidation3
    },
    {
      path: '/edit-payment-liquidation-4',
      name: 'edit-payment-liquidation-4',
      component: EditPaymentLiquidation4
    },
    {
      path: '/payment-liquidation-approvalHistory',
      name: 'payment-liquidation-approvalHistory',
      component: PaymentLiquidationsApprovalHistory
    },
    {
      path: '/create-75liquidation-1',
      name: 'create-75liquidation-1',
      component: Create75Liquidation1
    },
    {
      path: '/create-75liquidation-2',
      name: 'create-75liquidation-2',
      component: Create75Liquidation2
    },
    {
      path: '/create-75liquidation-3',
      name: 'create-75liquidation-3',
      component: Create75Liquidation3
    },
    {
      path: '/create-75liquidation-4',
      name: 'create-75liquidation-4',
      component: Create75Liquidation4
    },
    {
      path: '/employee-75liquidations',
      name: 'employee-75liquidations',
      component: Employee75Liquidations
    },
    {
      path: '/employee-view-75liquidation',
      name: 'employee-view-75liquidation',
      component: EmployeeView75Liquidation
    },
    {
      path: '/approve-75liquidations',
      name: 'approve-75liquidations',
      component: Approve75Liquidations
    },
    {
      path: '/employee-track-75liquidation',
      name: 'employee-track-75liquidation',
      component: EmployeeTrack75Liquidation
    },
    {
      path: '/employee-approve-75liquidation',
      name: 'employee-approve-75liquidation',
      component: EmployeeApprove75Liquidation
    },
    {
      path: '/liquidations75-approval-history',
      name: 'liquidations75-approval-history',
      component: Liquidations75ApprovalHistory
    },
    {
      path: '/create-25liquidation-1',
      name: 'create-25liquidation-1',
      component: Create25Liquidation1
    },
    {
      path: '/create-25liquidation-2',
      name: 'create-25liquidation-2',
      component: Create25Liquidation2
    },
    {
      path: '/create-25liquidation-3',
      name: 'create-25liquidation-3',
      component: Create25Liquidation3
    },
    {
      path: '/create-25liquidation-4',
      name: 'create-25liquidation-4',
      component: Create25Liquidation4
    },
    {
      path: '/employee-25liquidations',
      name: 'employee-25liquidations',
      component: Employee25Liquidations
    },
    {
      path: '/employee-view-25liquidation',
      name: 'employee-view-25liquidation',
      component: EmployeeView25Liquidation
    },
    {
      path: '/employee-track-25liquidation',
      name: 'employee-track-25liquidation',
      component: EmployeeTrack25Liquidation
    },
    {
      path: '/approve-25liquidations',
      name: 'approve-25liquidations',
      component: Approve25Liquidations
    },
    {
      path: '/employee-approve-25liquidation',
      name: 'employee-approve-25liquidation',
      component: EmployeeApprove25Liquidation
    },
    {
      path: '/liquidations25-approval-history',
      name: 'liquidations25-approval-history',
      component: Liquidations25ApprovalHistory
    },
    {
      path: '/edit-75liquidation-1',
      name: 'edit-75liquidation-1',
      component: Edit75Liquidation1
    },
    {
      path: '/edit-75liquidation-2',
      name: 'edit-75liquidation-2',
      component: Edit75Liquidation2
    },
    {
      path: '/edit-75liquidation-3',
      name: 'edit-75liquidation-3',
      component: Edit75Liquidation3
    },
    {
      path: '/edit-75liquidation-4',
      name: 'edit-75liquidation-4',
      component: Edit75Liquidation4
    },
    {
      path: '/edit-25liquidation-1',
      name: 'edit-25liquidation-1',
      component: Edit25Liquidation1
    },
    {
      path: '/edit-25liquidation-2',
      name: 'edit-25liquidation-2',
      component: Edit25Liquidation2
    },
    {
      path: '/edit-25liquidation-3',
      name: 'edit-25liquidation-3',
      component: Edit25Liquidation3
    },
    {
      path: '/edit-25liquidation-4',
      name: 'edit-25liquidation-4',
      component: Edit25Liquidation4
    },
    {
      path: '/admin-profile',
      name: 'admin-profile',
      component: AdminProfile
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    meta: {
      reload: true
    }
  })

export default router