<template>
    <RequestApprovalHistory></RequestApprovalHistory>
</template>
<script>
    import RequestApprovalHistory from '@/components/RequestApprovalHistory.vue'
    export default {
        name: 'request-approval-history',
        components: {
            RequestApprovalHistory
        }
    }
</script>