<template>
    <Liquidations25ApprovalHistory></Liquidations25ApprovalHistory>
</template>
<script>
    import Liquidations25ApprovalHistory from '@/components/Liquidations25ApprovalHistory.vue'
    export default {
        name: 'approve-requests',
        components: {
            Liquidations25ApprovalHistory
        }
    }
</script>