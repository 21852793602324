<template>
    <EmployeeView75Liquidation></EmployeeView75Liquidation>
</template>
<script>
    import EmployeeView75Liquidation from '@/components/EmployeeView75Liquidation.vue'
    export default {
        name: 'employee-sign',
        components: {
            EmployeeView75Liquidation
        }
    }
</script>