<template>
    <RejectedPayments></RejectedPayments>
</template>
<script>
    import RejectedPayments from '@/components/RejectedPayments.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            RejectedPayments
        }
    }
</script>