<template>
    <CreateLiquidation2></CreateLiquidation2>
</template>
<script>
    import CreateLiquidation2 from '@/components/CreateLiquidation2.vue'
    export default {
        name: 'employee-liquidation',
        components: {
            CreateLiquidation2
        }
    }
</script>