<template>
    <EditPaymentLiquidation4></EditPaymentLiquidation4>
</template>
<script>
    import EditPaymentLiquidation4 from '@/components/EditPaymentLiquidation4.vue'
    export default {
        name: 'create-payment-liquidation',
        components: {
            EditPaymentLiquidation4
        }
    }
</script>