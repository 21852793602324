<template>
    <EmployeeHome></EmployeeHome>
</template>
<script>
    import EmployeeHome from '@/components/EmployeeHome.vue'
    export default {
        name: 'employee-home',
        components: {
            EmployeeHome
        }
    }
</script>