<template>
    <PaymentLiquidations></PaymentLiquidations>
</template>
<script>
    import PaymentLiquidations from '@/components/PaymentLiquidations.vue'
    export default {
        name: 'payment-liquidations',
        components: {
            PaymentLiquidations
        }
    }
</script>