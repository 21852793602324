<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Edit Request Step 2</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Edit Request</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer animate-pulse border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-6 space-y-2.5">
                  <div class="mx-4 mb-5 mt-0.5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 gap-6">
                    <!-- hotel need -->
                    <div v-if="showHotelNeed" class="col-span-full sm:col-span-1">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Hotel Need at the Destination <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'budgetSource'"
                            :url="'/choice/search'"
                            :persistedSelection="selectedHotelChoice"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedHotelChoice = items
                              saveHotelNeed()
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the lodging details -->
                    <div v-if="showHotelNeed && selectedHotelChoice.name === 'Yes'" class="mb-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-2 sm:gap-y-1.5">
                      <div class="space-x-0.5 text-right mb-2">
                        <button @click="clearHotelData()" class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-red-600 focus:outline-none focus:ring-0 active:bg-red-800 transition duration-150 ease-in-out">Delete All</button>
                        <button @click="openAddModal()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add Details</button>
                      </div>
                      <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                          <thead>
                            <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                              <td class="py-3 px-2 text-left text-white font-bold">Arrival Date</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Departure Date</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Place</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">No of Nights</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Reservation No</td>
                              <td class="py-3 px-2 border-l text-left text-white font-bold">Value</td>
                              <td class="py-3 px-2 border-l text-center text-white font-bold">Action</td>
                            </tr>
                          </thead>
                          <tbody  v-if="showHotel">
                            <tr v-for="(data, i) in hotelData" :key="i" class=" text-gray-600 text-size-sm" style="">
                              <td class="py-3 px-2 border text-left">{{ formatData(data.arrivalDate) }}</td>
                              <td class="py-3 px-2 border text-left">{{ formatData(data.departureDate) }}</td>
                              <td class="py-3 px-2 border text-left">{{ data.place.name }}</td>
                              <td class="py-3 px-2 border text-left">{{ data.nights }}</td>
                              <td v-if="data.reservationNumber !== 'Total Cost'" class="py-3 px-2 border text-left">{{ data.reservationNumber }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ data.reservationNumber }}</td>
                              <td v-if="data.reservationNumber !== 'Total Cost'" class="py-3 px-2 border text-left">{{ formatData(data.value) }}</td>
                              <td v-else class="py-3 px-2 border text-left font-medium">{{ formatData(data.value) }}</td>
                              <td v-if="data.reservationNumber !== 'Total Cost'" class="py-3 px-2 border text-center flex">
                                <div class="flex mx-auto space-x-4">
                                  <svg @click="deleteItem(i)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                                  <svg @click="loadEditModal(i)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                                </div>
                              </td>
                              <td v-else></td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1 mb-2">
                      <button
                        @click="back"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Back
                      </button>
                      <button
                        @click="next"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- add schedule modal -->
     <TransitionRoot appear :show="isAddOpen" @close="closeAddModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Add Acccommodation Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                    <!-- Arrival Date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Arrival Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="arrivalDate"
                          placeholder="Description"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Arrival Date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Departure Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="departureDate"
                          placeholder="Description"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Place <span class="font-bold text-red-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data}"
                          :elementId="'place'"
                          :url="'/accommodation-rate/search'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['venue_name', 'formatted_cost']
                          }"
                        />
                      </div>
                    </div>
                    <!-- No of Nights -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Number of Nights <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="nights"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Reservation Number -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Reservation Number <span class="text-red-500 font-normal">(optional)</span></label>
                      <div class="flex ">
                        <input
                          v-model="reservationNumber"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Value -->
                    <!-- <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Estimated Value <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="value"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div> -->
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeAddModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="addHotelData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
       <!-- edit schedule modal -->
     <TransitionRoot appear :show="isEditOpen" @close="closeEditModal()" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-gray-600"
                  >
                  Edit Acccommodation Details
                  </DialogTitle>
                  <div class="grid grid-flow-row grid-cols-2 grid-rows-2 gap-4 py-4">
                    <!-- Arrival Date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Arrival Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="arrivalDate"
                          placeholder="Description"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Arrival Date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Departure Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="departureDate"
                          placeholder="Description"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Place -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Place/District <span class="font-bold text-red-500">*</span></label>
                      <div class=" w-full">
                        <CustomDropDown
                          @selectedItems="(data) => { place = data}"
                          :elementId="'place'"
                          :url="'/accommodation-rate/search'"
                          :persistedSelection="place"
                          :multipleSelection="false"
                          :display="{
                            id: 'id',
                            name: ['venue_name', 'formatted_cost']
                          }"
                        />
                      </div>
                    </div>
                    <!-- No of Nights -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Number of Nights <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="nights"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Reservation Number -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Reservation Number <span class="font-normal text-red-500">(optional)</span></label>
                      <div class="flex ">
                        <input
                          v-model="reservationNumber"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- Value -->
                    <!-- <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Estimated Value <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="value"
                          required
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 shadow-sm w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div> -->
                  </div>
                  <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                    <button
                      @click="closeEditModal()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Cancel
                    </button>
                    <button
                      @click="editHotelData()"
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                      border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                      focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { ref } from 'vue'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import CustomDropDown from './CustomDropDown.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

export default {
  computed: {
    ...mapState([
      'employee',
      'hotelNeed',
      'hotel'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    CustomDropDown,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay
  },
  setup () {
    const isAddOpen = ref(false)
    const isEditOpen = ref(false)
    return {
      isAddOpen,
      isEditOpen,
      closeAddModal () {
        isAddOpen.value = false
      },
      openAddModal () {
        isAddOpen.value = true
      },
      closeEditModal () {
        isEditOpen.value = false
      },
      openEditModal () {
        isEditOpen.value = true
      }
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      title: 'Error Message',
      processMessage: '',
      showHotelNeed: false,
      showHotel: false,
      hotelData: [],
      selectedHotelChoice: {},
      arrivalDate: '',
      departureDate: '',
      place: {},
      nights: 0,
      reservationNumber: 0,
      value: 0,
      selectedEditIndex: null,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 400)
      this.$store.commit('mutateSelectedSubMenuItem', 400)
      // load the stored vuex data
      this.loadHotelVuex()
    }
  },
  methods: {
    saveHotelNeed () {
      this.$store.commit('mutateHotelNeed', this.selectedHotelChoice)
      // console.log(this.selectedHotelChoice)
      if (this.selectedHotelChoice.id === undefined || this.selectedHotelChoice.id === 2) {
        // clear the hotel data
        this.showHotel = false
        this.hotelData = []
        this.showHotel = false
        this.$store.commit('mutateHotel', this.hotelData)
      }
    },
    // add a schedule data
    editHotelData () {
        // if (this.arrivalDate !== '' && this.departureDate !== '' && this.place.name !== undefined && this.nights !== '' && !isNaN(this.nights) && parseInt(this.nights) > 0 
        // && this.reservationNumber !== '' && this.isDateGreaterThan(this.departureDate, this.arrivalDate)) {
        // check if the details are empty
        if (this.arrivalDate !== '' && this.departureDate !== '' && this.place.name !== undefined && this.nights !== '' && !isNaN(this.nights) && parseInt(this.nights) > 0 
          && this.isDateGreaterThan(this.departureDate, this.arrivalDate)) {
          // remove the grand total
          this.removeGrandTotal()
          // hide the hotel
          this.showHotel = false
          // calculating the value
          const value = parseFloat(this.place.allData.estimated_cost) * parseInt(this.nights)

          // adding the data
          const rowData = {
            arrivalDate: this.arrivalDate,
            departureDate: this.departureDate,
            place: this.place,
            nights: this.nights,
            reservationNumber: this.reservationNumber,
            value: value
          }
          // add it in the hotel
          this.hotelData[this.selectedEditIndex] = rowData

          // calculate the grand total
          this.calculateGrandTotal()

          //  close the add a schedule modal
          this.closeEditModal()
        }
    },
    // load the edit modal
    loadEditModal (index) {
      // get the row details
      const rowData = this.hotelData[index]
      this.arrivalDate = rowData.arrivalDate
      this.departureDate = rowData.departureDate
      this.place = rowData.place
      this.nights = rowData.nights
      this.reservationNumber = rowData.reservationNumber
      this.value = rowData.value
      // set the index to be edited
      this.selectedEditIndex = index
      this.openEditModal()
    },
    // delete an item from the
    deleteItem (index) {
      // remove the selected item
      this.showHotel = false
      // remove the grand total
      this.removeGrandTotal()
      // delete the item
      this.hotelData.splice(index, 1)
      this.calculateGrandTotal()
    }, 
    // load vuex hotel data
    loadHotelVuex () {
      if (this.hotel !== null) {
        this.showHotel = false
        this.hotelData = this.hotel
      }
      if (this.hotelNeed !== null) {
        this.showHotelNeed = false
        this.selectedHotelChoice = this.hotelNeed
      }
      this.showHotel = true
      this.showHotelNeed = true
    },
    // remove the last row
    removeGrandTotal () {
      // only if the data is > 0
      const size = this.hotelData.length
      if (size > 0) {
        this.hotelData.splice((size -1), 1)
      }
    },
    // calulates the grand total
    calculateGrandTotal () {
      // get the data size
      const size = this.hotelData.length

      // calculate the grand total
      let grandTotal = 0

      for (let i = 0; i < size; i++) {
        const rowData = this.hotelData[i]
        const rowValue = parseFloat(rowData.value)

        grandTotal += rowValue
      }

      const rowData = {
            arrivalDate: '',
            departureDate: '',
            place: '',
            nights: '',
            reservationNumber: 'Total Cost',
            value: grandTotal
          }
      // add it in the hotel
      this.hotelData.push(rowData)
      // show the hotel
      this.showHotel = true
      // save the hotel state
      this.$store.commit('mutateHotel', this.hotelData)
    },
    // check the start and end date
    isDateGreaterThan(endDate, startDate) {
        // Ensure both dates are Date objects
        const date1 = new Date(endDate);
        const date2 = new Date(startDate);
        if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
          throw new Error("Both arguments must be Date objects.");
        }

        // Compare the timestamps
        return date1.getTime() >= date2.getTime();
    },
    // add a schedule data
    addHotelData () {
        // if (this.arrivalDate !== '' && this.departureDate !== '' && this.place.name !== undefined && this.nights !== '' && !isNaN(this.nights) && parseInt(this.nights) > 0
        // && this.reservationNumber !== '' && this.isDateGreaterThan(this.departureDate, this.arrivalDate)) 
        // check if the details are empty
        if (this.arrivalDate !== '' && this.departureDate !== '' && this.place.name !== undefined && this.nights !== '' && !isNaN(this.nights) && parseInt(this.nights) > 0
         && this.isDateGreaterThan(this.departureDate, this.arrivalDate)) {
          // remove the grand total
          this.removeGrandTotal()
          // hide the hotel
          this.showHotel = false
          // calculating the value
          const value = parseFloat(this.place.allData.estimated_cost) * parseInt(this.nights)

          // adding the data
          const rowData = {
            arrivalDate: this.arrivalDate,
            departureDate: this.departureDate,
            place: this.place,
            nights: this.nights,
            reservationNumber: this.reservationNumber,
            value: value
          }
          // add it in the hotel
          this.hotelData.push(rowData)

          // calculate the grand total
          this.calculateGrandTotal()

          //  close the add a schedule modal
          this.closeAddModal()
        }
    },
    // edit a schedule row
    editScheduleData () {
      // check if the details are empty
      if (this.description !== '' && this.place !== undefined && this.startDate !== '' && this.endDate !== '') {
        // hide the schedule
        this.showSchedule = false
        // adding the data
        const rowData = {
          startDate: this.startDate,
          endDate: this.endDate,
          description: this.description,
          place: this.place
        }
        // edit it in the schedule
        this.scheduleData[this.selectedEditIndex] = rowData
        // show the schedule
        this.showSchedule = true
        //  close the add a schedule modal
        this.closeEditScheduleModal()
      }
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // clear the hotel data
    clearHotelData () {
      this.hotelData = []
    },
    // move to the previous page
    back () {
      this.$router.push('/edit-request-1')
    },
    // move to the next page
    next () {
      if (this.selectedHotelChoice.name === 'No' || (this.selectedHotelChoice.name === 'Yes' && this.hotelData.length > 0)) {
        this.$router.push('/edit-request-3')
      } else {
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'If there is no need of a hotel select no or else select yes and fill \n the hotel accommodation details, to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
