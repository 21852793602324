<template>
    <Edit25Liquidation4></Edit25Liquidation4>
</template>
<script>
    import Edit25Liquidation4 from '@/components/Edit25Liquidation4.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit25Liquidation4
        }
    }
</script>