<template>
    <Create25Liquidation4></Create25Liquidation4>
</template>
<script>
    import Create25Liquidation4 from '@/components/Create25Liquidation4.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create25Liquidation4
        }
    }
</script>