<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Create Payment Step 1</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Create Payment - Focal Person & Attachments</p>
            </div>
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto animate-pulse cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-0.5 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-6 space-y-2.5">
                  <div class="mx-4 mb-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 gap-2">
                    <!-- event name -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Event Name <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="eventNameData"
                          required
                          placeholder="Event Name"
                          type="text"
                          @change="saveData()"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- venueData -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Venue <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="venueData"
                          required
                          placeholder="Venue"
                          type="text"
                          @change="saveData()"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- departure date -->
                    <div class="col-span-full sm:col-span-1 mb-2">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Start Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="departureDateData"
                          required
                          type="date"
                          @change="saveData()"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- return date -->
                    <div class="col-span-full sm:col-span-1 mb-2">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">End Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="returnDateData"
                          required
                          type="date"
                          @change="saveData()"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- the focal person -->
                    <div v-if="showFocalPerson" class="col-span-full sm:col-span-1 mb-6">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Employee / Focal Person <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'employee'"
                            :url="'/employee/search'"
                            :persistedSelection="selectedEmployee"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedEmployee = items
                              saveFocalPerson(items)
                            }"
                            :display="{
                              id: 'id',
                              name: ['firstname', 'surname', 'position']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the payment method -->
                    <div v-if="showFocalPerson" class="col-span-full sm:col-span-1 mb-6">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Payment Method <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'payment-method'"
                            :url="'/payment-method/search'"
                            :persistedSelection="selectedPaymentMethod"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedPaymentMethod = items
                              savePaymentMethod(items)
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- the document upload -->
                    <div class="space-x-0.5 mb-2 flex justify-center">
                      <div class="shadow cursor-pointer inline-block box-shadow text-size-sm px-24 py-2.5 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                        <form
                          :action="`${BASE_URL}/payment/upload`"
                          id="form-2">
                          Click Here To Select & Upload a Files
                        </form>
                      </div>
                </div>
                <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                        <td class="py-2.5 px-2 text-left text-white font-bold">#</td>
                        <td class="py-2.5 px-2 text-left text-white font-bold">File Name</td>
                        <td class="py-2.5 px-2 border-l text-left text-white font-bold">File Size</td>
                        <td class="py-2.5 px-2 border-l text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in uploadedFiles" :key="i" class=" text-gray-600 text-size-sm" style="">
                        <td class="py-2.5 px-2 border text-left">{{ (i + 1) }}</td>
                        <td class="py-2.5 px-2 border text-left">{{ data.realName }}</td>
                        <td class="py-2.5 px-2 border text-left">{{ fileSizeFormatter(data.size) }}</td>
                        <td class="py-2.5 px-2 border text-center flex">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(i)" class="h-5 w-5 text-red-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                  <div class="flex-1 mb-2">
                      <button
                        @click="next()"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import Dropzone from 'dropzone';
import CustomDropDown from './CustomDropDown.vue';

export default {
  computed: {
    ...mapState([
      'employee',
      'paymentAttachments',
      'focalPerson',
      'paymentMethod',
      'eventName',
      'venue',
      'departureDate',
      'returnDate'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    CustomDropDown
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      eventNameData: '',
      venueData: '',
      departureDateData: '',
      returnDateData: '',
      showFocalPerson: false,
      selectedEmployee: {},
      selectedPaymentMethod: {},
      processMessage: '',
      uploadedFiles: [],
      BASE_URL: process.env.VUE_APP_BASE_URL
    }
  },
  mounted () {
    // the second dropzone
    const myDropzone2 = new Dropzone('#form-2', {
        headers: { Authorization: `Bearer ${this.employee.token}` },
        disablePreviews: true
      })
      // callback for the file added
      myDropzone2.on('addedfile', () => {
        this.$refs.loaderRef.openModal()
      })
      // the second drop zone
      myDropzone2.on('complete', (file) => {
        const fileDetails = JSON.parse(file.xhr.response)
        const realFilename = fileDetails.realName
        const renamedFilename = fileDetails.renamedFilename

        // get the file details
        const fileData = { realName: realFilename, name: renamedFilename, size: file.size }
        // push the file into the uploaded files []

        this.uploadedFiles.push(fileData)
        // save the uploaded files data in vuex
        this.$store.commit('mutatePaymentAttachments', this.uploadedFiles)
        myDropzone2.removeFile(file)
        this.$refs.loaderRef.closeModal()
      })

      myDropzone2.on("error", () => {
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Failed to submit the signature, please try again.'
        // open the error modal
        this.$refs.messageRef.openModal()
      });
      // load the vuex data
      if (this.paymentAttachments !== null) {
        this.uploadedFiles = this.paymentAttachments
      // this.showAttachments = true
      }
      // load the payment method
      if (this.paymentMethod !== null) {
        this.selectedPaymentMethod = this.paymentMethod
      }
      // load the focal person
      if (this.focalPerson !== null) {
        this.selectedEmployee = this.focalPerson
      }
      if (this.eventName !== null) {
        this.eventNameData = this.eventName
      }
      if (this.venue !== null) {
        this.venueData = this.venue
      }
      if (this.departureDate !== null) {
        this.departureDateData = this.departureDate
      }
      if (this.returnDate !== null) {
        this.returnDateData = this.returnDate
      }
      this.showFocalPerson = true
  },
  methods: {
    // check the start and end date
    isDateGreaterThan(endDate, startDate) {
      // Ensure both dates are Date objects
      const date1 = new Date(endDate);
      const date2 = new Date(startDate);
      if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        throw new Error("Both arguments must be Date objects.");
      }

      // Compare the timestamps
      return date1.getTime() >= date2.getTime();
    },
    saveData() {
      this.$store.commit('mutateEventName', this.eventNameData)
      this.$store.commit('mutateVenue', this.venueData)
      this.$store.commit('mutateDepartureDate', this.departureDateData)
      this.$store.commit('mutateReturnDate', this.returnDateData)
    },
    // save the focal person
    saveFocalPerson (data) {
      // save the focal person
      this.$store.commit('mutateFocalPerson', data)
    },
    // save the payment methods
    savePaymentMethod (data) {
      // save the focal person
      this.$store.commit('mutatePaymentMethod', data)
    },
    // deletes an item at an index
    deleteItem (index) {
      this.showAttachments = false
      this.uploadedFiles.splice(index, 1)
      this.showAttachments = true
    },
    // file size converter
    fileSizeFormatter (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) {
          return '0 Bytes'
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    // move to the previous step
    next () {
      if (this.selectedEmployee.id !== undefined && this.selectedPaymentMethod.id !== undefined && Array.isArray(this.uploadedFiles) 
        && this.uploadedFiles.length > 0 && this.eventNameData.trim() !== '' && this.venueData.trim() !== '' && this.departureDateData.trim() !== ''
        && this.returnDateData.trim() !== '' && this.isDateGreaterThan(this.returnDate, this.departureDate)
      ) {
        this.saveData()
        this.$router.push('/create-payment-2')
      } else {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please make sure you have filled all the fields,\n the payment attachment documents, to continue.\n and the end date is greater or equal to the start date.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
