<template>
    <EmployeeApproveRequest></EmployeeApproveRequest>
</template>
<script>
    import EmployeeApproveRequest from '@/components/EmployeeApproveRequest.vue'
    export default {
        name: 'employee-view-request',
        components: {
            EmployeeApproveRequest
        }
    }
</script>