<template>
    <CreateLiquidation1></CreateLiquidation1>
</template>
<script>
    import CreateLiquidation1 from '@/components/CreateLiquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreateLiquidation1
        }
    }
</script>