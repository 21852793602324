<template>
    <EmployeeApprove75Liquidation></EmployeeApprove75Liquidation>
</template>
<script>
    import EmployeeApprove75Liquidation from '@/components/EmployeeApprove75Liquidation.vue'
    export default {
        name: 'employee-sign',
        components: {
            EmployeeApprove75Liquidation
        }
    }
</script>