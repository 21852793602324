<template>
    <EditPayment2></EditPayment2>
</template>
<script>
    import EditPayment2 from '@/components/EditPayment2.vue'
    export default {
        name: 'employee-liquidation',
        components: {
            EditPayment2
        }
    }
</script>