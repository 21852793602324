<template>
    <AdminHome></AdminHome>
</template>
<script>
    import AdminHome from '@/components/AdminHome.vue'
    export default {
        name: 'admin-home',
        components: {
            AdminHome
        }
    }
</script>