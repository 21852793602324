<template>
    <ApprovePayments></ApprovePayments>
</template>
<script>
    import ApprovePayments from '@/components/ApprovePayments.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            ApprovePayments
        }
    }
</script>