<template>
    <EditRequest4></EditRequest4>
</template>
<script>
    import EditRequest4 from '@/components/EditRequest4.vue'
    export default {
        name: 'create-request-4',
        components: {
            EditRequest4
        }
    }
</script>