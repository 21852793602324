<template>
    <EmployeeApproveLiquidation></EmployeeApproveLiquidation>
</template>
<script>
    import EmployeeApproveLiquidation from '@/components/EmployeeApproveLiquidation.vue'
    export default {
        name: 'employee-view-request',
        components: {
            EmployeeApproveLiquidation
        }
    }
</script>