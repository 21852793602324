<template>
    <DeactivatedAccounts></DeactivatedAccounts>
</template>
<script>
    import DeactivatedAccounts from '@/components/DeactivatedAccounts.vue'
    export default {
        name: 'funding-management',
        components: {
            DeactivatedAccounts
        }
    }
</script>