<template>
    <Approve25Liquidations></Approve25Liquidations>
</template>
<script>
    import Approve25Liquidations from '@/components/Approve25Liquidations.vue'
    export default {
        name: 'approve-requests',
        components: {
            Approve25Liquidations
        }
    }
</script>