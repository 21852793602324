<template>
    <Liquidations75ApprovalHistory></Liquidations75ApprovalHistory>
</template>
<script>
    import Liquidations75ApprovalHistory from '@/components/Liquidations75ApprovalHistory.vue'
    export default {
        name: 'approve-requests',
        components: {
            Liquidations75ApprovalHistory
        }
    }
</script>