<template>
    <LiquidationApprovalHistory></LiquidationApprovalHistory>
</template>
<script>
    import LiquidationApprovalHistory from '@/components/LiquidationApprovalHistory.vue'
    export default {
        name: 'liquidation-approval-history',
        components: {
            LiquidationApprovalHistory
        }
    }
</script>