<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin' : 'main px-5 space-y-2']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">My Requests</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions"
                :series="chartSeries"
              />
            </div>
          </div>
        </div>
         <!-- the payments charts -->
         <div class="grid gap-8 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">My Payments</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions2"
                :series="chartSeries2"
              />
            </div>
          </div>
        </div>
         <!-- the requests charts -->
         <div class="grid gap-8 grid-rows-1 grid-cols-2 pb-4">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">My Liquidations</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions3"
                :series="chartSeries3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message=" 'Hi, ' + firstname + ' ' + surname" />
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import VueApexCharts from 'vue3-apexcharts'
import { ajax } from 'rxjs/ajax'
import { retry, zip, map } from 'rxjs'
import PopUp from './PopUp.vue'

export default {
  computed: {
    ...mapState([
      'id',
      'employee'
    ])
  },
  components: {
    PopUp,
    Navbar,
    Sidebar,
    apexchart: VueApexCharts
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      tree: [],
      showTree: false,
      expandBody: false,
      counterData: 10,
      counter: 0, 
      chartOptions: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries: [],
      chartOptions2: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries2: [],
      chartOptions3: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries3: [],
      headers: []
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 0)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      // getting the charts data
      this.getChartData()
      // getting the payments charts data
      this.getPaymentsChartData()
      // getting the liquidation chart data
      this.getLiquidationChartData()
    }
  },
  methods: {
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // get the requests chart data
    getChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/request/findSubmissionChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/approved-request/findChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Requests',
              data: response[0].data
            },
            {
              name: 'Approved Requests',
              data: response[1].data
            }
          ]

          this.chartOptions = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    },
    // get the payments chart data
    getPaymentsChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/payment/findSubmissionChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/approved-payment/findChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Payments',
              data: response[0].data
            },
            {
              name: 'Approved Payments',
              data: response[1].data
            }
          ]

          this.chartOptions2 = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries2 = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    },
    getLiquidationChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/liquidation/findSubmissionChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/approved-liquidation/findChartData?employeeId=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Liquidation',
              data: response[0].data
            },
            {
              name: 'Approved Liquidation',
              data: response[1].data
            }
          ]

          this.chartOptions3 = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries3 = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
