<template>
    <Create75Liquidation2></Create75Liquidation2>
</template>
<script>
    import Create75Liquidation2 from '@/components/Create75Liquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create75Liquidation2
        }
    }
</script>