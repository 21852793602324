<template>
    <EmployeeTrackPaymentLiquidation></EmployeeTrackPaymentLiquidation>
</template>
<script>
    import EmployeeTrackPaymentLiquidation from '@/components/EmployeeTrackPaymentLiquidation.vue'
    export default {
        name: 'employee-track-payment',
        components: {
            EmployeeTrackPaymentLiquidation
        }
    }
</script>