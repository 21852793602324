<template>
    <ApproveRequests></ApproveRequests>
</template>
<script>
    import ApproveRequests from '@/components/ApproveRequests.vue'
    export default {
        name: 'approve-requests',
        components: {
            ApproveRequests
        }
    }
</script>