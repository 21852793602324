<template>
    <Create25Liquidation2></Create25Liquidation2>
</template>
<script>
    import Create25Liquidation2 from '@/components/Create25Liquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create25Liquidation2
        }
    }
</script>