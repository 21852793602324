<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-4 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the employee overall statistics -->
        <div class="">
          <!-- <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white"> -->
          <div class="text-gray-400 bg-white grid md:grid-rows-1 md:grid-flow-col gap-4">
            <!-- start -->
            <div @click="headerClick(i)" v-for=" (item, i) in headers" :key="i" class="shadow-md cursor-pointer text-gray-400 border hover:shadow-lg bg-white">
              <div class="text-left p-4 space-y-4">
                <div class="flex justify-between">
                  <span class="text-xs font-medium text-gray-600">{{ item.title }}</span>
                  <!-- <svg v-if="i==0" class="h-4 w-4 fill-red-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,8V4c0-2.206-1.794-4-4-4h-6c-2.206,0-4,1.794-4,4v4c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5ZM7,4c0-1.103,.897-2,2-2h6c1.103,0,2,.897,2,2V12H7V4Zm-2,6v3c0,.552,.448,1,1,1h12c.553,0,1-.448,1-1v-3c1.654,0,3,1.346,3,3v4H2v-4c0-1.654,1.346-3,3-3Zm14,12H5c-1.654,0-3-1.346-3-3H22c0,1.654-1.346,3-3,3Z"/></svg> -->
                  <svg v-if="i==0" class="h-4 w-4 fill-red-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="m.213,9.145c-.341-.435-.264-1.063.171-1.404L8.919,1.062c1.814-1.419,4.348-1.42,6.162,0l8.535,6.679c.435.34.512.969.171,1.404-.197.252-.491.384-.788.384-.215,0-.433-.069-.615-.212L13.849,2.638c-1.088-.852-2.609-.852-3.697,0L1.616,9.316c-.436.34-1.063.262-1.403-.171Zm3.524,8.89c-2.166.591-3.737,2.679-3.737,4.965,0,.553.447,1,1,1s1-.447,1-1c0-1.379.973-2.684,2.263-3.035.533-.146.848-.695.702-1.228-.146-.534-.699-.847-1.228-.702Zm16.525,0c-.526-.146-1.082.168-1.228.702-.146.532.169,1.082.702,1.228,1.29.352,2.263,1.656,2.263,3.035,0,.553.447,1,1,1s1-.447,1-1c0-2.286-1.571-4.374-3.737-4.965Zm-15.763-7.035c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5,2.5-1.119,2.5-2.5-1.119-2.5-2.5-2.5Zm17.5,2.5c0-1.381-1.119-2.5-2.5-2.5s-2.5,1.119-2.5,2.5,1.119,2.5,2.5,2.5,2.5-1.119,2.5-2.5Zm-10-5.5c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5,2.5-1.119,2.5-2.5-1.119-2.5-2.5-2.5Zm0,7c-2.757,0-5,2.243-5,5v3c0,.553.447,1,1,1s1-.447,1-1v-3c0-1.654,1.346-3,3-3s3,1.346,3,3v3c0,.553.447,1,1,1s1-.447,1-1v-3c0-2.757-2.243-5-5-5Z"/>
                  </svg>
                  <svg v-if="i==1" class="h-4 w-4 fill-yellow-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,23.994l-7.063-6.908c-3.906-3.907-3.906-10.251-.008-14.15C6.818,1.046,9.329,.006,12,.006s5.182,1.04,7.071,2.929h0c3.898,3.899,3.898,10.243,0,14.142l-7.071,6.916Zm0-21.987c-2.137,0-4.146,.832-5.657,2.343-3.119,3.119-3.119,8.194,0,11.314l5.657,5.532,5.665-5.54c3.111-3.111,3.11-8.187-.008-11.306-1.512-1.511-3.521-2.343-5.657-2.343Zm-.094,11.413l5.793-5.707-1.404-1.425-5.809,5.701-2.793-2.707-1.393,1.437,2.782,2.696c.391,.391,.903,.585,1.416,.585s1.021-.193,1.407-.58Z"/></svg>
                  <svg v-if="i==2" class="h-4 w-4 fill-emerald-500"  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                    <path d="M24,4c0-2.206-1.794-4-4-4H4C1.794,0,0,1.794,0,4c0,1.858,1.279,3.411,3,3.858V24H21V7.858c1.721-.447,3-2,3-3.858ZM2,4c0-.737,.405-1.375,1-1.722v3.443c-.595-.347-1-.985-1-1.722Zm3-2h3V22h-3V2Zm5,20V2h9V22H10ZM21,5.722V2.278c.595,.347,1,.985,1,1.722s-.405,1.375-1,1.722Zm-4,12.778c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Z"/>
                  </svg>
                  <!-- <svg v-if="i==2" class="h-4 w-4 fill-emerald-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                  <!-- <svg v-if="i==3" class="h-4 w-4 fill-red-600" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M21,12h-1V5c0-1.65-1.35-3-3-3H7c-1.65,0-3,1.35-3,3v7h-1c-1.65,0-3,1.35-3,3v7H24v-7c0-1.65-1.35-3-3-3ZM6,5c0-.55,.45-1,1-1h10c.55,0,1,.45,1,1v11H6V5Zm16,15H2v-5c0-.55,.45-1,1-1h1v4H20v-4h1c.55,0,1,.45,1,1v5ZM15.52,6.91l1.41,1.41-3.94,3.94c-.47,.47-1.1,.73-1.77,.73s-1.3-.26-1.77-.73l-1.94-1.94,1.41-1.41,1.94,1.94c.13,.13,.28,.15,.35,.15s.23-.02,.35-.15l3.94-3.94Z"/></svg> -->
                  <svg v-if="i==3" class="h-4 w-4 fill-red-600" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m24,12c0,.553-.448,1-1,1h-10v10c0,.553-.448,1-1,1s-1-.447-1-1v-10H1c-.552,0-1-.447-1-1s.448-1,1-1h10V1c0-.553.448-1,1-1s1,.447,1,1v10h10c.552,0,1,.447,1,1Zm-1,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-6c-.552,0-1,.447-1,1s.448,1,1,1h6c.552,0,1-.447,1-1s-.448-1-1-1ZM1,6h6c.552,0,1-.447,1-1s-.448-1-1-1H1c-.552,0-1,.447-1,1s.448,1,1,1Zm16,0h2v2c0,.553.448,1,1,1s1-.447,1-1v-2h2c.552,0,1-.447,1-1s-.448-1-1-1h-2v-2c0-.553-.448-1-1-1s-1,.447-1,1v2h-2c-.552,0-1,.447-1,1s.448,1,1,1Zm-9.293,10.293c-.391-.391-1.023-.391-1.414,0l-1.793,1.793-1.793-1.793c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l1.793,1.793-1.793,1.793c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l1.793-1.793,1.793,1.793c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-1.793-1.793,1.793-1.793c.391-.391.391-1.023,0-1.414Z"/></svg>
                </div>
                <div class="flex justify-between text-xs text-gray-500 font-medium">
                  <span>{{ item.subtitle }}</span>
                  <span class="mr-1">{{ item.count }}</span>
                </div>
                <div v-if="i==0" class=" bg-red-500 h-1 w-full opacity-70" style="height: 2.4px;"></div>
                <div v-if="i==1" class=" bg-yellow-500 h-1 w-full opacity-50 " style="height: 2.4px;"></div>
                <div v-if="i==2" class=" bg-emerald-500 h-1 w-full opacity-60" style="height: 2.4px;"></div>
                <div v-if="i==3" class=" bg-red-600 h-1 w-full opacity-50" style="height: 2.4px;"></div>
              </div>
            </div>
            <!-- the end of the card -->
          </div>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-8 grid-rows-1 grid-cols-2 pb-4">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Employee Requests</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions"
                :series="chartSeries"
              />
            </div>
          </div>
        </div>
         <!-- the payments charts -->
         <div class="grid gap-8 grid-rows-1 grid-cols-2 pb-4">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Employee Payments</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions2"
                :series="chartSeries2"
              />
            </div>
          </div>
        </div>
         <!-- the requests charts -->
         <div class="grid gap-8 grid-rows-1 grid-cols-2 pb-4">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg>
              <p class="text-left text-size-md font-medium text-gray-600">Employee Liquidations</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <apexchart
                class="justify-center h-64 ring-1 py-2 cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
                width="100%"
                height="300px"
                type="bar"
                :options="chartOptions3"
                :series="chartSeries3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message=" 'Hi, ' + firstname + ' ' + surname" />
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import VueApexCharts from 'vue3-apexcharts'
import { ajax } from 'rxjs/ajax'
import { retry, zip, map, interval } from 'rxjs'
import PopUp from './PopUp.vue'

export default {
  computed: {
    ...mapState([
      'id',
      'employee'
    ])
  },
  components: {
    PopUp,
    Navbar,
    Sidebar,
    apexchart: VueApexCharts
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      tree: [],
      showTree: false,
      expandBody: false,
      counterData: 10,
      counter: 0, 
      chartOptions: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries: [],
      chartOptions2: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries2: [],
      chartOptions3: {
        chart: {
          id: 'request-chart',
          stacked: false
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        markers: {
          colors: ['#dc626b', '#E91E63']
        },
        stroke: {
          curve: 'smooth',
          width: 0,
          colors: ['#dc626b', '#339966']
        },
        fill: {
          type: 'solid',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1.0,
            opacityTo: 0.9,
            stops: [60, 90, 100]
          },
          colors: ['#dc626b', '#339966']
        },
        colors: ['#dc626b', '#37a292']
      },
      chartSeries3: [],
      headers: []
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 0)
      this.$store.commit('mutateSelectedSubMenuItem', null)
      // getting the charts data
      this.getChartData()
      // getting the payments charts data
      this.getPaymentsChartData()
      // getting the liquidation chart data
      this.getLiquidationChartData()
      // the counter statistics
      this.getCounterStatistics()
    }
  },
  methods: {
    // header click navigation
    headerClick (index) {
      switch (index) {
        case 0:
        this.$router.push('/employee-management')
          break
        case 1:
        this.$router.push('/approved-requests')
          break
        case 2:
        this.$router.push('/approved-payments')
          break
        default:
          this.$router.push('/approved-liquidations')
      }
    },
    // gets the home page's statistice
    getCounterStatistics () {
      const submittedRequests = ajax({
        url: process.env.VUE_APP_BASE_URL + '/employee/count',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const approvedRequests = ajax({
        url: process.env.VUE_APP_BASE_URL + '/request/count',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const pendingPayments = ajax({
        url: process.env.VUE_APP_BASE_URL + '/payment/count',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const pendingLiquidations = ajax({
        url: process.env.VUE_APP_BASE_URL + '/liquidation/count',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observable = zip(submittedRequests, approvedRequests, pendingPayments, pendingLiquidations)
      const observer = {
        next: (result) => {
          const employees = result[0].response
          const requests = result[1].response
          const payments = result[2].response
          const liquidations = result[3].response
          this.headers = [
            { 
              title: 'Employees Available', 
              subtitle: 'Total Employees', 
              count: employees
            },
            { 
              title: 'Request Submitted', 
              subtitle: 'Total Requests', 
              count: requests
            },
            { 
              title: 'Payments Submitted', 
              subtitle: 'Total Payments', 
              count: payments
            },
            { 
              title: 'Liquidations Submitted', 
              subtitle: 'Total Liquidations', 
              count: liquidations
            }
          ]
        },
        error: () => {},
        complete: () => {}
      }

      observable.subscribe(observer)
    },
    countAnimator () {
      const observable = interval(500)
      const observer = {
        next: () => {

        }
      }
      observable.subscribe(observer)
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // get the requests chart data
    getChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/request/findAllSubmissionChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/approved-request/findAllChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Requests',
              data: response[0].data
            },
            {
              name: 'Approved Requests',
              data: response[1].data
            }
          ]

          this.chartOptions = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    },
    // get the payments chart data
    getPaymentsChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/payment/findAllSubmissionChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/approved-payment/findAllChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Payments',
              data: response[0].data
            },
            {
              name: 'Approved Payments',
              data: response[1].data
            }
          ]

          this.chartOptions2 = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries2 = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    },
    getLiquidationChartData () {
      const stockInObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/liquidation/findAllSubmissionChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response)
      )

      const stockOutObservable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/approved-liquidation/findAllChartData',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        map((response) => response.response),
        retry(1)
      )

      const observable = zip(stockInObservable, stockOutObservable)

      const observer = {
        next: (response) => {
          const data = [
            {
              name: 'Submitted Liquidation',
              data: response[0].data
            },
            {
              name: 'Approved Liquidation',
              data: response[1].data
            }
          ]

          this.chartOptions3 = {
            chart: {
              id: 'request-chart',
              stacked: false
            },
            xaxis: {
              categories: response[0].labels
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'top'
            },
            markers: {
              colors: ['#dc626b', '#E91E63']
            },
            stroke: {
              curve: 'smooth',
              width: 0,
              colors: ['#dc626b', '#339966']
            },
            fill: {
              type: 'solid',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 1.0,
                opacityTo: 0.98,
                stops: [60, 90, 100]
              },
              colors: ['#8ADAB2', '#f19752']
            },
            colors: ['#8ADAB2', '#f19752']
          }

          this.chartSeries3 = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
