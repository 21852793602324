<template>
    <CreateRequest1></CreateRequest1>
</template>
<script>
    import CreateRequest1 from '@/components/CreateRequest1.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreateRequest1
        }
    }
</script>