<template>
    <AdminTrackPayment></AdminTrackPayment>
</template>
<script>
    import AdminTrackPayment from '@/components/AdminTrackPayment.vue'
    export default {
        name: 'admin-track-payment',
        components: {
            AdminTrackPayment
        }
    }
</script>