<template>
    <CreatePaymentLiquidation2></CreatePaymentLiquidation2>
</template>
<script>
    import CreatePaymentLiquidation2 from '@/components/CreatePaymentLiquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreatePaymentLiquidation2
        }
    }
</script>