<template>
    <EditRequest1></EditRequest1>
</template>
<script>
    import EditRequest1 from '@/components/EditRequest1.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditRequest1
        }
    }
</script>