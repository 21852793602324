<template>
    <div id="navbar-x" class="border-0 text-left shadow-lg flex items-center justify-between bg-gradient-to-r from-emerald-600 via-emerald-500 to-emerald-700">
      <div class="flex items-center pl-4 md:px-2 py-3 space-x-1 transform">
        <div @click="home()" class="flex space-x-1 cursor-pointer items-center">
          <img src="../assets/round-logo.png" class="ml-2 h-5 w-5 font-bold text-white hidden  md:block cursor-pointer"/>
          <span class="text-title font-bold text-white">EGPAF Mocambique</span>
        </div>
        <div class="md:pl-32 h-full">
          <svg @click="sidebarDismiss()" class="h-4 w-4 cursor-pointer text-white fill-current ml-6 hover:fill-red-200" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z"/><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><circle cx="2" cy="5" r="2"/><circle cx="2" cy="12" r="2"/><circle cx="2" cy="19" r="2"/></svg>
        </div>
      </div>
      <div class="px-6 py-3 items-center flex space-x-6">
        <svg @click="loadProfile()" class="h-4 w-4 fill-white cursor-pointer hover:fill-red-200" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
          <path d="m17,11c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-8,0c0-1.654,1.346-3,3-3s3,1.346,3,3-1.346,3-3,3-3-1.346-3-3Zm10,11.5v1.5h-2v-1.5c0-1.378-1.121-2.5-2.5-2.5h-5c-1.379,0-2.5,1.122-2.5,2.5v1.5h-2v-1.5c0-2.481,2.019-4.5,4.5-4.5h5c2.481,0,4.5,2.019,4.5,4.5Zm3.038-6.757l-1.091,1.677h0s-3.401-2.162-3.401-2.162c.909-1.18,1.455-2.653,1.455-4.257,0-3.866-3.134-7-7-7s-7,3.134-7,7c0,1.604.546,3.078,1.455,4.257l-3.401,2.161h0s-1.091-1.676-1.091-1.676l1.785-1.162c-.478-1.098-.747-2.308-.747-3.581,0-1.451.353-2.819.966-4.034l-1.732-1.127,1.092-1.677,1.727,1.124c1.452-1.762,3.561-2.961,5.947-3.226V0h2v2.059c2.387.265,4.495,1.464,5.947,3.226l1.727-1.124,1.092,1.677-1.732,1.127c.613,1.215.966,2.583.966,4.034,0,1.273-.269,2.482-.747,3.581l1.785,1.162Z"/>
        </svg>
        <svg @click="logout()" class="h-4 w-4 fill-white cursor-pointer hover:fill-red-200" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
          <path d="M12,7.505c.552,0,1-.448,1-1V2c0-.552-.448-1-1-1s-1,.448-1,1V6.505c0,.552,.448,1,1,1Z"/>
          <path d="M16.875,2.396c-.523-.168-1.09,.117-1.26,.643-.171,.525,.117,1.089,.643,1.26,2.061,.668,4.425,2.191,4.425,8.078,0,7.157-3.471,8.624-8.683,8.624S3.317,19.533,3.317,12.376c0-5.87,2.366-7.397,4.427-8.072,.525-.171,.811-.736,.639-1.261-.171-.525-.738-.814-1.261-.639C3.216,3.682,1.317,6.944,1.317,12.376c0,9.417,5.709,10.624,10.683,10.624s10.683-1.208,10.683-10.624c0-5.447-1.899-8.712-5.808-9.98Z"/>
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'


  export default {
    computed: {
      ...mapState([
        'employee',
      ])
    },
    components: {
    },
    setup () {
      return {
        dissmiss: false
      }
    },
    name: 'nav-bar',
    props: {
    },
    data: () => {
      return {
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
      if (this.employee !== null) {
        this.tokenCheck()
        // detects the device 
        // & automatically closes the sidebar
        // if its a small device
        this.deviceDetection()
      } else {
        this.$router.push('/')
      }
    },
    methods: {
      loadProfile () {
        this.$router.push('/employee-profile')
      },
      // detect if the device is a mobile device
      // if yes close the sidebar
      deviceDetection () {
        if(this.$isMobile()) {
          this.dissmiss = this.sidebarClosed
          const sidebar = document.querySelector('.sidebar')
          sidebar.classList.toggle('-translate-x-full')
          this.dissmiss = true
          this.$store.commit('mutateSidebarClosed', this.dissmiss)
          this.$emit('sidebarDismissed', this.dissmiss)
        } 
      },
      // dismisses the sidebar
      sidebarDismiss () {
        if (this.$isMobile()) {
          this.dissmiss = this.sidebarClosed
        }
        
        const sidebar = document.querySelector('.sidebar')
        sidebar.classList.toggle('-translate-x-full')
        this.dissmiss = !this.dissmiss
        this.$store.commit('mutateSidebarClosed', this.dissmiss)
        this.$emit('sidebarDismissed', this.dissmiss)
      },
      // logs out users
      logout () {
        // const observable = ajax({
        //   url: this.BASE_URL + '/login/signout',
        //   method: 'PUT',
        //   headers: {
        //     Authorization: `Bearer ${this.token}`
        //   },
        //   body: {
        //     id: this.loginId
        //   }
        // })

        // const observer = {
        //   next: () => {
        //   },
        //   error: () => {
        //     this.$router.push('/')
        //   },
        //   complete: () => {
        //     this.$router.push('/')
        //   }
        // }
  
        // observable.subscribe(observer)
        this.$router.push('/')
      },
      // check the validity of the user token
      tokenCheck () {
        const observable = ajax({
          url: this.BASE_URL + '/employee/findById',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          },
          body: {
            id: this.loginId
          }
        })

        const observer = {
          next: () => {
          },
          error: () => {
            this.$router.push('/')
          },
          complete: () => {
          }
        }
  
        observable.subscribe(observer)
      },
      // go to the home page
      home () {
        this.$router.push('/admin-home')
      }
    }
  }
  </script>
  
  <style>
  </style>
  