<template>
    <Login></Login>
</template>
<script>
    import Login from '@/components/Login.vue'
    export default {
        name: 'login-view',
        components: {
            Login
        }
    }
</script>