<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Create Request Step 1</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Create Request</p>
            </div>
            <!-- {{ advance }} -->
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer animate-pulse text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="mx-0.5 cursor-pointer text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm font-medium ring-emerald-600 ring-2 text-gray-500 py-0 px-1">4</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer mx-0.5 text-size-sm ring-emerald-600 font-medium ring-2 text-gray-500 py-0 px-1">5</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="cursor-pointer text-size-sm font-medium mr-4 ring-emerald-600 ring-2 text-gray-500 py-0 px-1">6</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
              <!-- {{ advance }} -->
                <div v-if="showAdvance" class="px-4 py-6 space-y-2.5">
                  <div class="mx-4 mb-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-2 gap-6">
                    <!-- source of funding -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Source of Funds <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'budgetSource'"
                            :url="'/budget-source/search'"
                            :persistedSelection="selectedBudgetSources"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              selectedBudgetSources = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- departure date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Departure Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="departureDate"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- return date -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Return Date <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="returnDate"
                          required
                          type="date"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- departure time -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Departure Time <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="departureTime"
                          required
                          type="time"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- return time -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Return Time <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="returnTime"
                          required
                          type="time"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3.5 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- mode of transport -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Mode of Transport <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'transportation'"
                            :url="'/transportation/search'"
                            :persistedSelection="selectedTransportation"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              selectedTransportation = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- return time -->
                    <div v-if="Array.isArray(selectedTransportation) && selectedTransportation.length > 0 && selectedTransportation.some((data) => data.name.includes('Air'))" class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">If By Air Indicate No of People Accompanying You <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="noOfPeople"
                          required
                          placeholder="Number of people accompanying you"
                          type="number"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-4 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- companions -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Names of Companions <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="companions"
                          required
                          placeholder="Number of people accompanying you"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-4 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                      <!-- <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'companions'"
                            :url="'/employee/search'"
                            :persistedSelection="selectedCompany"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              selectedCompany = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['firstname', 'surname', 'position']
                            }"
                          />
                        </div>
                      </div> -->
                    </div>
                    <!-- destinations -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Destinations <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'destinations'"
                            :url="'/location/search'"
                            :persistedSelection="selectedDestinations"
                            :multipleSelection="true"
                            @selectedItems="(items) => {
                              selectedDestinations = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name', 'type']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- return time -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Reason Of Travel <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <input
                          v-model="reasonOfTravel"
                          required
                          placeholder="Reason of Travel"
                          type="text"
                          class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-4 border-gray-300 focus:outline-none focus:border-emerald-400 focus:ring-1 focus:ring-emerald-600"/>
                      </div>
                    </div>
                    <!-- need lodging travel advance -->
                    <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Will Need Lodging Advance <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'choice'"
                            :url="'/choice/search'"
                            :persistedSelection="selectedLodgingAdvanceNeed"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedLodgingAdvanceNeed = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                     <!-- currency -->
                     <div class="col-span-full sm:col-span-1 ">
                      <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Request Currency <span class="font-bold text-red-500">*</span></label>
                      <div class="flex ">
                        <div class=" w-full">
                          <CustomDropDown
                            :elementId="'currency'"
                            :url="'/currency/search'"
                            :persistedSelection="selectedCurrency"
                            :multipleSelection="false"
                            @selectedItems="(items) => {
                              selectedCurrency = items
                            }"
                            :display="{
                              id: 'id',
                              name: ['name']
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1">
                      <button
                        @click="next"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
    <!-- the budget percentage modal -->
    <BudgetPercentageModal
      v-if="showPercentageModal"
      :isOk="isOk"
      :title="messageTitle"
      :message="message"
      :budgetSources="selectedBudgetSources"
      ref="budgetPercentageModal"
      @error="(error) => {
        percentageError(error)
      }"
      @success="(percentageData) => {
        savePercentageData(percentageData)
      }"
    />
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import CustomDropDown from './CustomDropDown.vue';
import { interval } from 'rxjs';
import BudgetPercentageModal from './modals/SourcesPercentage.vue';

export default {
  computed: {
    ...mapState([
      'employee',
      'advance'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message,
    CustomDropDown,
    BudgetPercentageModal,
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      isOk: true,
      message: '',
      messageTitle: '',
      showPercentageModal: false,
      title: 'Error Message',
      processMessage: '',
      selectedBudgetSources: {},
      departureDate: '',
      returnDate: '',
      departureTime: '',
      returnTime: '',
      selectedTransportation: {},
      noOfPeople: 0,
      selectedCompany: {},
      selectedDestinations: {},
      reasonOfTravel: '',
      companions: '',
      selectedLodgingAdvanceNeed: {},
      selectedCurrency: {},
      showAdvance: false
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 400)
      this.$store.commit('mutateSelectedSubMenuItem', 400)
      // load the vuex data
      this.loadAdvance()
      // start saving
      this.autosave()
    }
  },
  methods: {
    openMessageModal () {
      this.isOk = false
      this.messageTitle = 'Error Message'
      this.message = 'Fill all the fields in the memo to move to the next step...'
      this.$refs.messageModal.openMessageModal()
    },
    // check the start and end date
    isDateGreaterThan(endDate, startDate) {
        // Ensure both dates are Date objects
        const date1 = new Date(endDate);
        const date2 = new Date(startDate);
        if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
          throw new Error("Both arguments must be Date objects.");
        }

        // Compare the timestamps
        return date1.getTime() >= date2.getTime();
    },
    // load the vuex data
    loadAdvance () {
      // load the selected budget source
      if ( this.advance !== null && this.advance.budgetSource !== null && this.advance.budgetSource !== undefined) {
        this.selectedBudgetSources = this.advance.budgetSource
      }
      if ( this.advance !== null && this.advance.departureDate !== null && this.advance.departureDate !== undefined) {
        this.departureDate = this.advance.departureDate
      }
      if ( this.advance !== null && this.advance.returnDate !== null && this.advance.returnDate !== undefined) {
        this.returnDate = this.advance.returnDate
      }
      if ( this.advance !== null && this.advance.departureTime !== null && this.advance.departureTime !== undefined) {
        this.departureTime = this.advance.departureTime
      }
      if ( this.advance !== null && this.advance.returnTime !== null && this.advance.returnTime !== undefined) {
        this.returnTime = this.advance.returnTime
      }
      if ( this.advance !== null && this.advance.transportation !== null && this.advance.transportation !== undefined) {
        this.selectedTransportation = this.advance.transportation
      }
      if ( this.advance !== null && this.advance.companions !== null && this.advance.companions !== undefined) {
        this.companions = this.advance.companions
      }
      if ( this.advance !== null && this.advance.destinations !== null && this.advance.destinations !== undefined) {
        this.selectedDestinations = this.advance.destinations
      }
      if ( this.advance !== null && this.advance.reasonOfTravel !== null && this.advance.reasonOfTravel !== undefined) {
        this.reasonOfTravel = this.advance.reasonOfTravel
      }
      if ( this.advance !== null && this.advance.needAdvanceLodging !== null && this.advance.needAdvanceLodging !== undefined) {
        this.selectedLodgingAdvanceNeed = this.advance.needAdvanceLodging
      }
      if ( this.advance !== null && this.advance.currency !== null && this.advance.currency !== undefined) {
        this.selectedCurrency = this.advance.currency
      }

      if ( this.advance !== null && this.advance.noOfPeople !== null && this.advance.noOfPeople !== undefined) {
        this.noOfPeople = this.advance.noOfPeople
      }

      this.showAdvance = true
    },
    autosave () {
      // save details every 8 milliseconds
      const observable = interval(6000 * 1 * 1)
      const observer = {
        next: () => {
          const data = {
            budgetSource: this.selectedBudgetSources,
            departureDate: this.departureDate,
            returnDate: this.returnDate,
            departureTime: this.departureTime,
            returnTime: this.returnTime,
            transportation: this.selectedTransportation,
            companions: this.companions,
            destinations: this.selectedDestinations,
            reasonOfTravel: this.reasonOfTravel,
            noOfPeople: this.noOfPeople,
            needAdvanceLodging: this.selectedLodgingAdvanceNeed,
            currency: this.selectedCurrency
          }
          this.$store.commit('mutateAdvance', data)
        },
        error: () => {}
      }
      observable.subscribe(observer)
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    percentageError (error) {
      // fill all the fields
      // close the loader modal
      this.$refs.loaderRef.closeModal()
      // showing the dialog for the error
      this.processMessage = error
      // open the error modal
      this.$refs.messageRef.openModal()
      this.showPercentageModal = false
    },
    savePercentageData (data) {
      this.showPercentageModal = false
      this.selectedBudgetSources = data
    },
    budgetPercentageCheck () {
        try {
        if (Array.isArray(this.selectedBudgetSources)) {
          const size = this.selectedBudgetSources.length
          var total = 0
          for (let i = 0; i < size; i++) {
            const data = this.selectedBudgetSources[i]
            const percent = data.percentage

            if (percent === undefined || percent === null) {
              return false
            } else {
              total += parseFloat(percent)
            }
          }
        }
        // const siz\e
        total = parseInt(total)
        if (total !== 100) {
          return false
        } else {
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    viewPercentages () {
      this.showPercentageModal = true
      setTimeout(() => {
        this.$refs.budgetPercentageModal.openMessageModal()      
      }, 200)
    },
    // move to the next page
    next () {
      // save the data
      const data = {
            budgetSource: this.selectedBudgetSources,
            departureDate: this.departureDate,
            returnDate: this.returnDate,
            departureTime: this.departureTime,
            returnTime: this.returnTime,
            transportation: this.selectedTransportation,
            companions: this.companions,
            destinations: this.selectedDestinations,
            reasonOfTravel: this.reasonOfTravel,
            noOfPeople: this.noOfPeople,
            needAdvanceLodging: this.selectedLodgingAdvanceNeed,
            currency: this.selectedCurrency
          }
          
      this.$store.commit('mutateAdvance', data)

      // check the selected budget percentages
      if (!this.budgetPercentageCheck()) {
        this.viewPercentages()
      } else if (!this.isDateGreaterThan(this.returnDate, this.departureDate)) {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please make sure the return date is greater than or equal to the departure date.'
        // open the error modal
        this.$refs.messageRef.openModal()
      } else if (this.selectedBudgetSources.length > 0 && this.departureDate !== '' && this.returnDate !==''
          && this.departureTime !== '' && this.returnTime !== '' && this.selectedTransportation.length > 0
          && !isNaN(this.noOfPeople) && parseInt(this.noOfPeople) >= 0 && this.reasonOfTravel.trim() !== ''
          && this.selectedCurrency.id !== undefined && this.selectedLodgingAdvanceNeed.id !== undefined
      ) {
        // validate the data
        this.$router.push('/create-request-2')
      } else {
        // fill all the fields
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.processMessage = 'Please fill all the fields to continue.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // submits the signature
    submit (filename) {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + '/signature/insertFile',
        method: 'POST',
        body: {
          employee_id: this.employee.id,
          filename: filename
        },
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          // move to the profile page
          this.$router.push('/employee-profile')
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.processMessage = 'Failed to submit the signature, please try again.'
          // open the error modal
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
        }
      }

      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
