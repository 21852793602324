<template>
    <div>
        <!--The error dialog-->
        <TransitionRoot appear :show="isOpen" @close="closeModal" as="template">
            <Dialog as="div">
                <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    >
                    <DialogOverlay class="fixed inset-0 bg-black opacity-40" />
                    </TransitionChild>
        
                    <span class="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                    </span>
        
                    <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                    >
                    <div
                        class="inline-block border w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                    >
                        <DialogTitle
                        as="h4"
                        class="text-sm font-medium leading-6 text-gray-900"
                        >
                        {{ title }}
                        </DialogTitle>
                        <div class="my-3">
                        <p style="white-space: pre;" class="text-size text-gray-500">
                            {{message}}
                        </p>
                        </div>
        
                        <div class="mt-4 flex justify-center">
                        <button
                            type="button"
                            class=" items-center inline-flex justify-center px-6 py-1 text-size text-emerald-900 bg-gray-300 border
                                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                                    focus-visible:ring-offset-2 focus-visible:ring-white outline-none"
                            @click="closeModal"
                        >
                            Ok
                        </button>
                        </div>
                    </div>
                    </TransitionChild>
                </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

    export default {
        components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay
        // DialogTitle
    },
    setup () {
      const isOpen = ref(false)
      return {
        isOpen,
        closeModal () {
          isOpen.value = false
        },
        openModal () {
          isOpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
        title: String,
        message: String
    },
    data: () => {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  }
</script>