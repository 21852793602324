<template>
    <EditLiquidation2></EditLiquidation2>
</template>
<script>
    import EditLiquidation2 from '@/components/EditLiquidation2.vue'
    export default {
        name: 'employee-liquidation',
        components: {
            EditLiquidation2
        }
    }
</script>