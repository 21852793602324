<template>
    <Edit25Liquidation3></Edit25Liquidation3>
</template>
<script>
    import Edit25Liquidation3 from '@/components/Edit25Liquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit25Liquidation3
        }
    }
</script>