<template>
    <CreateRequest3></CreateRequest3>
</template>
<script>
    import CreateRequest3 from '@/components/CreateRequest3.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreateRequest3
        }
    }
</script>