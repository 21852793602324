<template>
    <EmployeeBanking></EmployeeBanking>
</template>
<script>
    import EmployeeBanking from '@/components/EmployeeBanking.vue'
    export default {
        name: 'employee-banking',
        components: {
            EmployeeBanking
        }
    }
</script>