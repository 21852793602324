<template>
    <Create75Liquidation1></Create75Liquidation1>
</template>
<script>
    import Create75Liquidation1 from '@/components/Create75Liquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create75Liquidation1
        }
    }
</script>