<template>
    <ApprovedPayments></ApprovedPayments>
</template>
<script>
    import ApprovedPayments from '@/components/ApprovedPayments.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            ApprovedPayments
        }
    }
</script>