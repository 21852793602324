<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-4 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">> Home</span> > Bank Management</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white mb-10">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><title>03-Diagram</title><path d="M3,21.976a1,1,0,0,1-1-1V0H0V20.976a3,3,0,0,0,3,3H24v-2Z"/><rect x="5" y="12" width="2" height="7"/><rect x="10" y="10" width="2" height="9"/><rect x="15" y="13" width="2" height="6"/><rect x="20" y="9" width="2" height="10"/><polygon points="11 4.414 16 9.414 23.707 1.707 22.293 0.293 16 6.586 11 1.586 5.293 7.293 6.707 8.707 11 4.414"/></svg> -->
              <!-- <svg  class="h-5 w-5 text-emerald-500 fill-current" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m9 24h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/><path d="m7 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/><path d="m5 16h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"/><path d="m13 23.955a1 1 0 0 1 -.089-2 10 10 0 1 0 -10.87-10.865 1 1 0 0 1 -1.992-.18 12 12 0 0 1 23.951 1.09 11.934 11.934 0 0 1 -10.91 11.951c-.03.003-.061.004-.09.004z"/><path d="m12 6a1 1 0 0 0 -1 1v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586a1 1 0 0 0 -1-1z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m11.349,24H0V3C0,1.346,1.346,0,3,0h12c1.654,0,3,1.346,3,3v5.059c-.329-.036-.662-.059-1-.059s-.671.022-1,.059V3c0-.552-.448-1-1-1H3c-.552,0-1,.448-1,1v19h7.518c.506.756,1.125,1.429,1.831,2Zm0-14h-7.349v2h5.518c.506-.756,1.125-1.429,1.831-2Zm-7.349,7h4c0-.688.084-1.356.231-2h-4.231v2Zm20,0c0,3.859-3.141,7-7,7s-7-3.141-7-7,3.141-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5ZM14,5H4v2h10v-2Zm5.589,9.692l-3.228,3.175-1.63-1.58-1.393,1.436,1.845,1.788c.314.315.733.489,1.179.489s.865-.174,1.173-.482l3.456-3.399-1.402-1.426Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14.648,5.493c.873-.701,1.772-1.643,2.228-2.789,.238-.598,.161-1.277-.205-1.816-.377-.556-1.002-.888-1.671-.888h-6c-.669,0-1.294,.332-1.671,.888-.366,.539-.442,1.218-.205,1.816,.456,1.145,1.355,2.088,2.228,2.789C4.696,7.221,1,13.159,1,18c0,3.309,2.691,6,6,6h10c3.309,0,6-2.691,6-6,0-4.841-3.696-10.779-8.352-12.507Zm.369-3.528c-.516,1.297-2.094,2.393-3.019,2.91-.923-.513-2.495-1.6-2.999-2.875l6.018-.035Zm1.982,20.035H7c-2.206,0-4-1.794-4-4,0-5.243,4.71-11,9-11s9,5.757,9,11c0,2.206-1.794,4-4,4Zm-5,0c-.552,0-1-.448-1-1v-1h-.268c-1.068,0-2.063-.574-2.598-1.499-.276-.478-.113-1.089,.365-1.366,.476-.277,1.089-.114,1.366,.365,.178,.308,.511,.5,.867,.5h2.268c.551,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.042-.507c-1.341-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1c0-.552,.448-1,1-1s1,.448,1,1v1h.268c1.067,0,2.063,.575,2.598,1.5,.276,.478,.113,1.089-.365,1.366-.477,.277-1.089,.114-1.366-.365-.179-.309-.511-.5-.867-.5h-2.268c-.551,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.042,.507c1.341,.223,2.315,1.373,2.315,2.733,0,1.654-1.346,3-3,3v1c0,.552-.448,1-1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m8.293,4.121c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.293,1.293V1c0-.552.448-1,1-1s1,.448,1,1v3l1.293-1.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293c-.39.39-.902.585-1.414.585s-1.024-.195-1.414-.585l-2.293-2.293Zm14.707,17.879h-2v-12h.292c1.023,0,1.95-.555,2.417-1.448.438-.837.381-1.797-.153-2.566-.448-.645-1.024-1.163-1.671-1.499l-4.069-2.105c.387,1.062.156,2.302-.695,3.153l-2.293,2.293c-.754.755-1.759,1.171-2.828,1.171s-2.075-.416-2.829-1.171l-2.292-2.292c-.851-.851-1.082-2.09-.695-3.153l-4.072,2.106c-.643.335-1.22.853-1.668,1.498-.534.769-.591,1.728-.153,2.565.467.894,1.394,1.448,2.418,1.448h.292v12H1c-.552,0-1,.448-1,1s.448,1,1,1h6c.552,0,1-.448,1-1s-.448-1-1-1h-2v-12h6v2c-1.654,0-3,1.346-3,3,0,1.36.974,2.51,2.315,2.733l3.042.507c.373.062.644.382.644.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.277-.478-.889-.642-1.366-.365-.479.277-.642.888-.365,1.366.535.925,1.53,1.499,2.598,1.499h.268v1c0,.552.448,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551.449-1,1-1h2.268c.356,0,.688.192.867.5.274.479.886.641,1.366.365.478-.277.642-.888.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-2h6v12h-2c-.553,0-1,.448-1,1s.447,1,1,1h6c.553,0,1-.448,1-1s-.447-1-1-1Z"/>
              </svg>
              <p class="text-left text-size-sm font-medium text-gray-600">{{ bankData.length }} Banks</p>
            </div>
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-2 mt-5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class="flex justify-between text-center">
                  <span></span>
                  <button @click="openAddModal" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add a Bank</button>
                </div>

                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size-input border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer ">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs pointer-events-none">
                        <td class="py-3 px-2 text-left text-white font-bold">#</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Bank</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Bank Code</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1 + startRow) }}</td>
                        <td class="py-3 px-2 border text-left">{{data.name}}</td>
                        <td class="py-3 px-2 border text-left">{{data.bank_code}}</td>
                        <td class="py-3 px-2 border mx-auto flex text-center">
                          <div class="flex mx-auto space-x-4 items-center">
                            <svg @click="deleteItem(data.id)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="editItem(data)" class="h-5 w-5 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m7.127,12.4l-4.519,1.004L.113,22.536l6.009-6.009,1.414,1.414-6.009,6.009,9.132-2.495,1.004-4.519-4.536-4.536ZM23.256.742c-.99-.99-2.6-.99-3.59,0l-10.685,10.684,3.59,3.59,10.684-10.684c.479-.48.744-1.117.744-1.795s-.264-1.316-.744-1.795Z"/></svg> -->
                            <svg @click="editItem(data)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if=" (startRow+rowsPerPage) < bankData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add funding modal -->
    <TransitionRoot appear :show="isAddOpen" @close="closeAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add a Bank
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-1 grid-rows-1 gap-4 py-4">
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Bank <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="name"
                        placeholder="Bank"
                        requiered
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit funding modal -->
    <TransitionRoot appear :show="isEditOpen" @close="closeEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Edit a Bank
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-1 grid-rows-1 gap-4 py-4">
                    <!-- Description -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Bank <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="name"
                        placeholder="Bank"
                        requiered
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Edit
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
  </TransitionRoot>
  <!-- the loader modal -->
  <Loader ref="loaderRef"/>
  <!-- the message loader -->
  <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry } from 'rxjs'
import { ref } from 'vue'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'

export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.bankData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    Loader,
    Message,
  },
  setup () {
    const isAddOpen = ref(false)
    const isEditOpen = ref(false)
    return {
      isAddOpen,
      isEditOpen,
      closeAddModal () {
        isAddOpen.value = false
      },
      openAddModal () {
        isAddOpen.value = true
      },
      closeEditModal () {
        isEditOpen.value = false
      },
      openEditModal () {
        isEditOpen.value = true
      }
    }
  },
  name: 'funding-management',
  props: {
  },
  data: () => {
    return {
      name: '',
      filter: '',
      showRequests: false,
      expandBody: false,
      bankData: [],
      message: '',
      processMessage: '',
      messageTitle: '',
      idOk: false,
      BASE_URL: process.env.VUE_APP_BASE_URL,
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      editId: null,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 600)
    this.$store.commit('mutateSelectedSubMenuItem', 602)
    // getting the pending activity approvals
    this.getBanks()
  },
  methods: {
    // add an item
    addData () {
      // close the modal
      this.closeAddModal()

      if (this.name.trim() !== '' &&  this.name.length >= 4) {
        this.$refs.loaderRef.openModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/bank/insert',
          method: 'POST',
          body: {
            name: this.name
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.processMessage =  'Successfully submitted the bank...'
            this.$refs.messageRef.openModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.processMessage =  'Failed to submit the bank, please try again later...'
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.getBanks()
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.processMessage =  'Cannot submit an empty or invalid bank.'
        this.$refs.messageRef.openModal()
      }
    },
    // edit an item
    editData () {
      // close the modal
      this.closeEditModal()

      if (this.name.trim() !== '' &&  this.name.length >= 4) {
        this.$refs.loaderRef.openModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/bank/update',
          method: 'PUT',
          body: {
            id: this.editId,
            name: this.name
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.processMessage =  'Successfully edited the bank...'
            this.$refs.messageRef.openModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.processMessage =  'Failed to edit the bank, please try again later...'
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
            this.getBanks()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.processMessage =  'Cannot edit an empty or invalid bank.'
        this.$refs.messageRef.openModal()
      }
    },
    // edit item
    editItem (data) {
      const id = data.id
      const source = data.name

      this.editId = id
      this.name = source

      this.openEditModal()
    },
    // deletes the item
    deleteItem (id) {
      const observable = ajax({
      url: process.env.VUE_APP_BASE_URL + '/bank/delete?id='+id,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.employee.token}`
      }
    })

    const observer = {
      next: () => {
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.isOk = true
        this.messageTitle = 'Message'
        this.processMessage =  'Successfully deleted the bank...'
        this.$refs.messageRef.openModal()
      },
      error: () => {
        // close the loader modal
        this.$refs.loaderRef.closeModal()
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.processMessage =  'Failed to delete the bank, please try again later...'
        this.$refs.messageRef.openModal()
      },
      complete: () => {
        this.$refs.loaderRef.closeModal()
        this.getBanks()
      }
    }

    observable.subscribe(observer)
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.bankData.length) {
        this.startRow = newStartRow
      }
      // creating the elements for the next page
      // var nextPage = this.startRow + this.rowsPerPage
      // // check if the value is within the data array size
      // const size = this.bankData.length
      // if (nextPage < size) {
      //   this.endPageItemIndex = nextPage
      // } else {
      //   this.endPageItemIndex = size
      // }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getBanks () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + '/bank/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.bankData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
