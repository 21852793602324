<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-4 margin' : 'main px-5 space-y-4']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-6 text-size-md font-medium text-gray-500 w-full">
          <span><span class=" text-emerald-500">>Home</span> > Employee Management</span>
          <span>{{ firstname }} {{ surname }}</span>
        </div>
          <div>
          <!-- Timeline -->
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-4 mb-4 space-x-1 items-center">
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">{{employeeData.length}} Employees</p>
            </div>
            <!-- {{ employeeData }} -->
              <!-- The pending activity approvals -->
              <div class="mx-8 mb-5 mt-2 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 sm:gap-y-4">
                <div class="flex justify-between text-center">
                  <span></span>
                  <div class="flex">
                    <button @click="downloadInactive()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Download Deactivated Employees</button>
                    <button @click="downloadActive()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Download Active Employees</button>
                    <button @click="openAddModal()" class="shadow inline-block box-shadow text-size-sm px-8 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">Add an Employee</button>
                  </div>
                </div>

                <div class=" flex">
                  <input
                    v-model="filter"
                    type="text"
                    placeholder="Search..."
                    required class="shadow-sm bg-gray-50 text-gray-600 w-full text-size border px-2 py-3 border-gray-300  focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 "/>
                </div>

                <div class=" overflow-x-auto">
                  <table id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                    <thead>
                      <tr class=" bg-emerald-600 text-size-xs pointer-events-none" style="">
                        <td class="py-3 px-2 text-left text-white font-bold">#</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Firstname</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Surname</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Email</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Phone</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Position</td>
                        <td class="py-3 px-2 border text-left text-white font-bold">Office</td>
                        <td class="py-3 px-2 border text-center text-white font-bold">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, i) in filteredRows" :key="i" class="text-gray-500 text-size-sm" style="">
                        <td class="py-3 px-2 border text-left">{{ (i + 1 + startRow) }}</td>
                        <td class="py-3 px-2 border text-left">{{data.firstname}}</td>
                        <td class="py-3 px-2 border text-left">{{data.surname}}</td>
                        <td class="py-3 px-2 border text-left">{{data.email}}</td>
                        <td class="py-3 px-2 border text-left">{{data.phone}}</td>
                        <td class="py-3 px-2 border text-left">{{data.position}}</td>
                        <td class="py-3 px-2 border text-left">{{data.office}}</td>
                        <td class="py-3 px-2 border mx-auto flex text-center">
                          <div class="flex mx-auto space-x-4">
                            <svg @click="deleteItem(data.id)" class="h-4 w-4 text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.707,15.293-1.414,1.414L12,13.414,8.707,16.707,7.293,15.293,10.586,12,7.293,8.707,8.707,7.293,12,10.586l3.293-3.293,1.414,1.414L13.414,12Z"/></svg>
                            <!-- <svg @click="loadEditBudgetModal(i)" class="h-5 w-5 text-emerald-400 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m21,0H3C1.346,0,0,1.346,0,3v21h24V3c0-1.654-1.346-3-3-3Zm-2.879,10.621l-7.878,7.879h-4.243v-4.242l7.879-7.879c1.133-1.133,3.109-1.133,4.243,0,1.169,1.17,1.169,3.072,0,4.242Zm-3.871,1.043l-4.836,4.836h-1.415v-1.414l4.836-4.836,1.414,1.414Zm2.457-3.871c.39.39.39,1.024,0,1.414l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0Z"/></svg> -->
                            <svg @click="editItem(data)" class="h-4 w-4 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,24H5c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1ZM2.48,11.89c-.31,.73-.48,1.53-.48,2.34v4.35l-1.71,1.71c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l1.71-1.71H7.76c.81,0,1.61-.17,2.34-.48L2.48,11.89ZM20.91,7.83l-8.67,10.15c-.13,.15-.27,.29-.42,.42L3.59,10.17c.13-.14,.27-.28,.42-.41L14.17,1.1c1.78-1.54,4.47-1.45,6.14,.21l.39,.39c1.66,1.66,1.76,4.36,.22,6.13Z"/></svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div class="flex mb-5">
                <div class=" flex mx-auto items-center">
                  <div v-if="startRow !== 0" @click="movePages(-1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div v-if="(startRow+rowsPerPage) < employeeData.length" @click="movePages(1)" class=" bg-emerald-600 px-1 py-1 cursor-pointer border-l hover:bg-emerald-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add funding modal -->
    <TransitionRoot appear :show="isAddOpen" @close="closeAddModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Add an Employee
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- Firstname -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Firstname <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="firstname"
                        placeholder="Firstname"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Surname -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Surname <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="surname"
                        placeholder="Surname"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                   <!-- Position -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Position <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'position'"
                          :url="'/position/search'"
                          :persistedSelection="{}"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedPosition = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Department -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Department <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'department'"
                          :url="'/department/search'"
                          :persistedSelection="{}"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedDepartment = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Office -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Office <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'office'"
                          :url="'/location/searchOffice'"
                          :persistedSelection="{}"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedOffice = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Province <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'province'"
                          :url="'/location/searchProvince'"
                          :persistedSelection="{}"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedProvince = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                    <!-- Email -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Email <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="email"
                        placeholder="Email"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Phone -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Phone <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="phone"
                        placeholder="Phone"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Associate Id -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Associate Id <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="associateId"
                        placeholder="Associate Id"
                        required
                        type="number"
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeAddModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="addData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Save
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- edit funding modal -->
    <TransitionRoot appear :show="isEditOpen" @close="closeEditModal()" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black opacity-80" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block border w-full max-w-5xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
              >
                <DialogTitle
                  as="h4"
                  class="text-size font-medium leading-6 text-gray-600"
                >
                Edit an Employee
                </DialogTitle>
                <div class="grid grid-flow-row grid-cols-2 gap-4 py-4">
                  <!-- Firstname -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Firstname <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="firstname"
                        placeholder="Firstname"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Surname -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Surname <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="surname"
                        placeholder="Surname"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                   <!-- Position -->
                   <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Position <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'position'"
                          :url="'/position/search'"
                          :persistedSelection="selectedPosition"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedPosition = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Department -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Department <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'department'"
                          :url="'/department/search'"
                          :persistedSelection="selectedDepartment"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedDepartment = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Office -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Office <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'office'"
                          :url="'/location/searchOffice'"
                          :persistedSelection="selectedOffice"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedOffice = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left">Province <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <div class=" w-full">
                        <CustomDropDown
                          :elementId="'province'"
                          :url="'/location/searchProvince'"
                          :persistedSelection="selectedProvince"
                          :multipleSelection="false"
                          @selectedItems="(items) => {
                            selectedProvince = items
                          }"
                          :display="{
                            id: 'id',
                            name: ['name']
                          }"
                        />
                      </div>
                    </div>
                  </div>
                    <!-- Email -->
                    <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Email <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="email"
                        placeholder="Email"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Phone -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Phone <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="phone"
                        placeholder="Phone"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                  <!-- Associate Id -->
                  <div class="col-span-full sm:col-span-1 ">
                    <label for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Associate Id <span class="font-bold text-red-500">*</span></label>
                    <div class="flex ">
                      <input
                        v-model="associateId"
                        placeholder="associateId"
                        required
                        class="bg-gray-50 text-size-md text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-4 flex justify-center space-x-0.5">
                  <button
                    @click="closeEditModal()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Cancel
                  </button>
                  <button
                    @click="editData()"
                    type="button"
                    class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                    border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                    focus-visible:ring-offset-2 focus-visible:ring-emerald-500">
                    Edit
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
  </TransitionRoot>
  <!-- the loader modal -->
  <Loader ref="loaderRef"/>
  <!-- the message loader -->
  <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import { retry } from 'rxjs'
import { ref } from 'vue'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
import CustomDropDown from './CustomDropDown.vue'

export default {
  computed: {
    ...mapState([
      'employee'
    ]),
    filteredRows () {
      return this.employeeData.filter((row) => {
        const searchText = this.filter.toLowerCase()

        return JSON.stringify(row).toLowerCase().includes(searchText)
      }).slice(this.startRow, this.startRow + this.rowsPerPage)
    }
  },
  components: {
    Navbar,
    Sidebar,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    Message,
    Loader,
    CustomDropDown
  },
  setup () {
    const isAddOpen = ref(false)
    const isEditOpen = ref(false)
    return {
      isAddOpen,
      isEditOpen,
      closeAddModal () {
        isAddOpen.value = false
      },
      openAddModal () {
        isAddOpen.value = true
      },
      closeEditModal () {
        isEditOpen.value = false
      },
      openEditModal () {
        isEditOpen.value = true
      }
    }
  },
  name: 'employee-management',
  props: {
  },
  data: () => {
    return {
      filter: '',
      startRow: 0,
      rowsPerPage: 10,
      endPageItemIndex: 10,
      showRequests: false,
      expandBody: false,
      employeeData: [],
      processMessage: '',
      editId: null,
      firstname: '',
      surname: '',
      email: '',
      phone: '',
      associateId: '',
      selectedOffice: {},
      selectedProvince: {},
      selectedDepartment: {},
      selectedPosition: {},
      positions: [],
      departments: [],
      offices: [],
      BASE_URL: process.env.VUE_APP_BASE_URL,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    this.$store.commit('mutateActiveListItemIndex', 200)
    this.$store.commit('mutateSelectedSubMenuItem', 200)
    // getting the pending activity approvals
    this.getEmployees()
    // this.getPositions()
    // this.getDepartments()
    // this.getOffices()
  },
  methods: {
    downloadActive () {
      this.$refs.loaderRef.openModal()
      const observable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + '/employee/downloadActive',
            headers: {
              Authorization: `Bearer ${this.employee.token}`,
              filename: 'active-employees.xlsx'
            },
            responseType: 'blob'
          })

      const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // showing the dialog for the error
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to download the files.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
    },
    downloadInactive () {
      const observable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + '/employee/downloadInactive',
            headers: {
              Authorization: `Bearer ${this.employee.token}`,
              filename: 'active-employees.xlsx'
            },
            responseType: 'blob'
          })

      const observer = {
          next: (response) => {
            const blob = response.response
            // const headers = response.responseHeaders
            const filename = response.request.headers.filename
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', filename || url.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: () => {
            // showing the dialog for the error
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to download the file.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

      observable.subscribe(observer)
    },
    // add an item
    addData () {
      // close the modal
      this.closeAddModal()
      // console.log(this.firstname, this.surname, this.email, this.phone, this.selectedPosition.id, this.selectedDepartment.id, this.selectedOffice.id) 

      if (this.firstname.toString().trim() !== '' && this.surname.toString().trim() !== '' && this.associateId.toString().trim() !== '' && this.email.toString().trim() !== '' && this.phone.toString().trim() !== ''
        && this.selectedPosition.id !== undefined && this.selectedDepartment.id !== undefined && this.selectedOffice.id !== undefined && this.selectedProvince.id !== undefined) {
        this.$refs.loaderRef.openModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/employee/insert',
          method: 'POST',
          body: {
            firstname: this.firstname,
            surname: this.surname,
            phone: this.phone,
            email: this.email,
            position_id: this.selectedPosition.id,
            department_id: this.selectedDepartment.id,
            office_id: this.selectedOffice.id,
            province_id: this.selectedProvince.id,
            associate_id: this.associateId
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.processMessage =  'Successfully submitted the employee...'
            this.$refs.messageRef.openModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.processMessage =  'Failed to submit the employee, please try again later...'
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.getEmployees()
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.processMessage =  'Cannot submit an empty or invalid employee.'
        this.$refs.messageRef.openModal()
      }
    },
    // edit an item
    editData () {
      // close the modal
      this.closeEditModal()

      if (this.firstname.toString().trim() !== '' && this.surname.toString().trim() !== '' && this.associateId.toString().trim() !== '' && this.email.toString().trim() !== '' && this.phone.toString().trim() !== ''
        && this.selectedPosition.id !== undefined && this.selectedDepartment.id !== undefined && this.selectedOffice.id !== undefined && this.selectedProvince.id !== undefined) {
        this.$refs.loaderRef.openModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/employee/update',
          method: 'PUT',
          body: {
            id: this.editId,
            firstname: this.firstname,
            surname: this.surname,
            phone: this.phone,
            email: this.email,
            position_id: this.selectedPosition.id,
            department_id: this.selectedDepartment.id,
            office_id: this.selectedOffice.id,
            province_id: this.selectedProvince.id,
            associate_id: this.associateId
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = true
            this.messageTitle = 'Message'
            this.processMessage =  'Successfully edited the employee...'
            this.$refs.messageRef.openModal()
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.isOk = false
            this.messageTitle = 'Error Message'
            this.processMessage =  'Failed to edit the employee, please try again later...'
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
            this.getEmployees()
          }
        }

        observable.subscribe(observer)

      } else {
        // showing the dialog for the error
        this.isOk = false
        this.messageTitle = 'Error Message'
        this.processMessage =  'Cannot edit an empty or invalid employee.'
        this.$refs.messageRef.openModal()
      }
    },
    // edit item
    editItem (data) {
      const id = data.id
      const firstname = data.firstname
      const surname = data.surname
      const email = data.email
      const phone = data.phone
      const associateId = data.associate_id
      console.log(data)
      
      this.editId = id
      this.associateId = associateId
      this.firstname = firstname
      this.surname = surname
      this.email = email
      this.phone = phone

      const positionId = data.position_id
      var positionName = data.position
      const positionData = { id: positionId, name: positionName }
      this.selectedPosition = positionData

      const officeId = data.office_id
      const officeName = data.office
      const officeData = { id: officeId, name: officeName }
      this.selectedOffice = officeData

      const departmentId = data.department_id
      const departmentName = data.department
      const departmentData = { id: departmentId, name: departmentName }
      this.selectedDepartment = departmentData

      const provinceId = data.province_id
      const provinceName = data.province
      const provinceData = { id: provinceId, name: provinceName }
      this.selectedProvince = provinceData

      // console.log(this.selectedPosition)
      this.openEditModal()
    },
    // deletes the item
    deleteItem (id) {
      const observable = ajax({
      url: process.env.VUE_APP_BASE_URL + '/employee/delete?id='+id,
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: () => {
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.isOk = true
          this.messageTitle = 'Message'
          this.processMessage =  'Successfully deleted the employee...'
          this.$refs.messageRef.openModal()
        },
        error: () => {
          // close the loader modal
          this.$refs.loaderRef.closeModal()
          // showing the dialog for the error
          this.isOk = false
          this.messageTitle = 'Error Message'
          this.processMessage =  'Failed to delete the employee, please try again later...'
          this.$refs.messageRef.openModal()
        },
        complete: () => {
          this.$refs.loaderRef.closeModal()
          this.getEmployees()
        }
      }

      observable.subscribe(observer)
    },
    // moves the pages of the pagenation
    movePages (amount) {
      var newStartRow = this.startRow + (amount * this.rowsPerPage)
      // only move if its greater than 0
      if (newStartRow >= 0 && newStartRow < this.employeeData.length) {
        this.startRow = newStartRow
      }
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // getting the pending activity approvals
    getEmployees () {
      this.showRequests = false
      const observable = ajax({
        url: this.BASE_URL + '/employee/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.employeeData = data
          this.showRequests = true
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // getting positions
    getPositions () {
      const observable = ajax({
        url: this.BASE_URL + '/position/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.positions = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // getting departments
    getDepartments () {
      const observable = ajax({
        url: this.BASE_URL + '/department/findAll',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.departments = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
    // getting offices
    getOffices () {
      const observable = ajax({
        url: this.BASE_URL + '/location/findAllOffices',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        retry(1)
      )
      const observer = {
        next: (response) => {
          const data = response.response
          this.offices = data
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {}
      }
      observable.subscribe(observer)
    },
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
