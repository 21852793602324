<template>
    <EmployeeViewLiquidation></EmployeeViewLiquidation>
</template>
<script>
    import EmployeeViewLiquidation from '@/components/EmployeeViewLiquidation.vue'
    export default {
        name: 'employee-view-request',
        components: {
            EmployeeViewLiquidation
        }
    }
</script>