<template>
    <FundingManagement></FundingManagement>
</template>
<script>
    import FundingManagement from '@/components/FundingManagement.vue'
    export default {
        name: 'funding-management',
        components: {
            FundingManagement
        }
    }
</script>