<template>
    <div class="row-span-full fixed h-screen transform bg-white shadow-2xl border-r opacity-100 z-10" style="width: 19rem;">
          <!-- the side nav -->
          <nav class="text-left mx-4 mt-4 flex flex-col space-y-4">
            <!-- Home list item -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==0 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(0)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <svg :class="[activeListItemIndex==0 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,15h-1c-.553,0-1-.447-1-1s.447-1,1-1h1c.553,0,1,.447,1,1s-.447,1-1,1Zm1,3c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm-4-12c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,0c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm0,4c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,9V5c0-2.757-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5,0,.553,.447,1,1,1s1-.447,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3v14c0,1.654-1.346,3-3,3h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c2.757,0,5-2.243,5-5Zm-8,.5v-4.152c0-1.548-.699-2.982-1.919-3.938l-3-2.349c-1.814-1.418-4.348-1.419-6.162,0l-3,2.348c-1.22,.955-1.919,2.39-1.919,3.938v4.152c0,2.481,2.019,4.5,4.5,4.5h7c2.481,0,4.5-2.019,4.5-4.5Zm-6.151-8.863l3,2.348c.731,.573,1.151,1.435,1.151,2.363v4.152c0,1.379-1.121,2.5-2.5,2.5H4.5c-1.379,0-2.5-1.121-2.5-2.5v-4.152c0-.929,.42-1.79,1.151-2.363l3-2.347c.544-.426,1.196-.639,1.849-.639s1.305,.213,1.849,.638Zm.151,7.363v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==0 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Home</span>
                </div>
              </a>
            </div>
            <!-- Funding Management -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==1 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(1)">
              <a href="">
                <div class="flex items-center">
                  <div class="flex space-x-2">
                    <!-- <svg :class="[activeListItemIndex==2 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                    <svg :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14.648,5.493c.873-.701,1.772-1.643,2.228-2.789,.238-.598,.161-1.277-.205-1.816-.377-.556-1.002-.888-1.671-.888h-6c-.669,0-1.294,.332-1.671,.888-.366,.539-.442,1.218-.205,1.816,.456,1.145,1.355,2.088,2.228,2.789C4.696,7.221,1,13.159,1,18c0,3.309,2.691,6,6,6h10c3.309,0,6-2.691,6-6,0-4.841-3.696-10.779-8.352-12.507Zm.369-3.528c-.516,1.297-2.094,2.393-3.019,2.91-.923-.513-2.495-1.6-2.999-2.875l6.018-.035Zm1.982,20.035H7c-2.206,0-4-1.794-4-4,0-5.243,4.71-11,9-11s9,5.757,9,11c0,2.206-1.794,4-4,4Zm-5,0c-.552,0-1-.448-1-1v-1h-.268c-1.068,0-2.063-.574-2.598-1.499-.276-.478-.113-1.089,.365-1.366,.476-.277,1.089-.114,1.366,.365,.178,.308,.511,.5,.867,.5h2.268c.551,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.042-.507c-1.341-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1c0-.552,.448-1,1-1s1,.448,1,1v1h.268c1.067,0,2.063,.575,2.598,1.5,.276,.478,.113,1.089-.365,1.366-.477,.277-1.089,.114-1.366-.365-.179-.309-.511-.5-.867-.5h-2.268c-.551,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.042,.507c1.341,.223,2.315,1.373,2.315,2.733,0,1.654-1.346,3-3,3v1c0,.552-.448,1-1,1Z"/></svg>

                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==1 ? 'text-size-sm font-medium text-emerald-500' : ' font-medium text-size-sm  text-gray-600']">Funding Management</span>
                  </div>
                </div>
              </a>
            </div>
            <!-- Employee Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==200 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color: #42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <svg :class="[activeListItemIndex==200 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==200 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Employee Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==200" class="w-3 h-3 text-emerald-500 fill-current mx-10" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-10" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 border absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-2 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Employee Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==200 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(200)">
                      <svg :class="[selectedSubMenuItem==200 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Employee Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==201 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(201)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==201 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==201 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m22,14.537v-1.537c0-2.206-1.794-4-4-4s-4,1.794-4,4v1.537c-1.195.693-2,1.986-2,3.463v2c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4v-2c0-1.477-.805-2.77-2-3.463Zm-6-1.537c0-1.103.897-2,2-2s2,.897,2,2v1h-4v-1Zm6,7c0,1.103-.897,2-2,2h-4c-1.103,0-2-.897-2-2v-2c0-1.103.897-2,2-2h4c1.103,0,2,.897,2,2v2Zm-2.5-1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-11.5-7c3.309,0,6-2.691,6-6S11.309,0,8,0,2,2.691,2,6s2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm1.99,13.211c-.078.547-.583.929-1.131.85-.284-.041-.574-.061-.859-.061-3.309,0-6,2.691-6,6v1c0,.552-.448,1-1,1s-1-.448-1-1v-1c0-4.411,3.589-8,8-8,.379,0,.763.027,1.141.081.547.078.927.584.85,1.131Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Deactivated Accounts</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==202 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(202)">
                      <svg :class="[selectedSubMenuItem==202 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m16,1.5v5.5h-3s0-5.5,0-5.5c0-.828.672-1.5,1.5-1.5.829,0,1.5.671,1.5,1.5Zm7.157,6.564c-.808-.582-1.943-.411-2.647.294l-.51.51V3c0-.829-.671-1.5-1.5-1.5s-1.5.671-1.5,1.5v13.684c.874-.331,1.679-.838,2.352-1.512l4.062-4.062c.859-.859.773-2.305-.257-3.047Zm-8.157,2.436c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v.5s0,0,0,0v7.5c0,1.379-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5v-9c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v6.247l-.51-.51c-.704-.704-1.839-.876-2.647-.294-1.03.742-1.116,2.188-.257,3.047l4.062,4.062c1.247,1.248,3.088,1.948,4.852,1.948,3.033,0,5.5-2.468,5.5-5.5v-7.5h0v-.5Zm-4-2c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v5.5h3s0-5.5,0-5.5Zm-1.5-3.5c.935,0,1.814.364,2.475,1.025.009.009.016.021.025.03v-2.435c0-.829-.671-1.5-1.5-1.5-.828,0-1.5.672-1.5,1.5v1.43c.165-.024.329-.051.5-.051Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Delegate Approvals</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==203 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(203)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==203 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m12,10c2.757,0,5-2.243,5-5S14.757,0,12,0s-5,2.243-5,5,2.243,5,5,5Zm0-8c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Zm11,17h-3c0-4.411-3.589-8-8-8s-8,3.589-8,8H1c-.552,0-1,.448-1,1s.448,1,1,1h1v2c0,.552.448,1,1,1s1-.448,1-1v-2h16v2c0,.552.448,1,1,1s1-.448,1-1v-2h1c.552,0,1-.448,1-1s-.448-1-1-1Zm-17,0c0-2.573,1.631-4.767,3.911-5.617l1.187,1.781-1.099,3.836h-4Zm6.901-3.836l1.187-1.781c2.28.851,3.911,3.044,3.911,5.617h-4l-1.099-3.836Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Position Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==204 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(204)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==204 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m0 22h24v2h-24zm24-14.091v2.091h-3v8h2v2h-22v-2h2v-8h-3v-2.091a3 3 0 0 1 1.563-2.634l9-4.909a2.993 2.993 0 0 1 2.874 0l9 4.909a3 3 0 0 1 1.563 2.634zm-19 10.091h3v-8h-3zm5-8v8h4v-8zm9 0h-3v8h3zm3-2.091a1 1 0 0 0 -.521-.878l-9-4.909a1 1 0 0 0 -.958 0l-9 4.909a1 1 0 0 0 -.521.878v.091h20z"/></svg>
                      <span class="text-size-sm text-gray-700">Employee Banking Details</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==205 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(205)">
                      <svg :class="[selectedSubMenuItem==205 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1.5,18c0-1.105.895-2,2-2s2,.895,2,2-.895,2-2,2-2-.895-2-2Zm8.5,0c0,1.105.895,2,2,2s2-.895,2-2-.895-2-2-2-2,.895-2,2Zm2-14c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm6.5,14c0,1.105.895,2,2,2s2-.895,2-2-.895-2-2-2-2,.895-2,2Zm-14-5.5c0-.275.224-.5.5-.5h6v2h2v-2h6c.276,0,.5.225.5.5v1.5h2v-1.5c0-1.379-1.122-2.5-2.5-2.5H5c-1.378,0-2.5,1.121-2.5,2.5v1.5h2v-1.5Zm.5,8.5h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm17,0h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm-8.5,0h-3c-1.103,0-2,.897-2,2v1h2v-1h3v1h2v-1c0-1.103-.897-2-2-2Zm-3-14h3v1h2v-1c0-1.103-.897-2-2-2h-3c-1.103,0-2,.897-2,2v1h2v-1Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Supervisor Management</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Liquidation Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==300 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m13,11h11v2h-11v11h-2v-11H0v-2h11V0h2v11Zm3,7h8v-2h-8v2Zm0,4h8v-2h-8v2ZM8,4H0v2h8v-2Zm13,0V1h-2v3h-3v2h3v3h2v-3h3v-2h-3ZM7.062,15.523l-2.562,2.562-2.562-2.562-1.414,1.414,2.562,2.562-2.562,2.562,1.414,1.414,2.562-2.562,2.562,2.562,1.414-1.414-2.562-2.562,2.562-2.562-1.414-1.414Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==300 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Liquidation Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==300" class="w-3 h-3 text-emerald-500 fill-current mx-2" style="margin-left: 2.2rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-2" style="margin-left: 2.2rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Liquidation Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==300 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(300)">
                      <svg :class="[selectedSubMenuItem==300 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,1.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm8.5,3.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm.5,7.5v8c0,1.995-2.58,3.5-6,3.5-2.151,0-3.968-.596-5.022-1.534-1.217,1.01-3.353,1.534-5.478,1.534-3.23,0-6.5-1.202-6.5-3.5V4.5C0,2.202,3.27,1,6.5,1s6.5,1.202,6.5,3.5v5.353c1.047-.534,2.433-.853,4-.853,3.42,0,6,1.505,6,3.5Zm-2,2.647c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5v-1.353ZM11,7.091c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409ZM2,11.091v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909Zm0,5.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm11-4c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5-1.519-1.5-4-1.5-4,.971-4,1.5ZM2,4.5c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5-1.577-1.5-4.5-1.5-4.5,1.064-4.5,1.5Zm9,16v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5Zm10,0v-1.353c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm-4.555-12.668c.17.114.363.168.554.168.323,0,.64-.156.833-.445l4-6c.306-.459.182-1.081-.277-1.387-.46-.308-1.081-.183-1.387.277l-4,6c-.306.459-.182,1.081.277,1.387Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Approved Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==301 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(301)">
                      <!-- <svg :class="[selectedSubMenuItem==301 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.95,5.54l-3.49-3.49c-1.32-1.32-3.08-2.05-4.95-2.05H7C4.24,0,2,2.24,2,5v14c0,2.76,2.24,5,5,5h10c2.76,0,5-2.24,5-5V10.49c0-1.87-.73-3.63-2.05-4.95Zm-1.41,1.41c.32,.32,.59,.67,.81,1.05h-4.34c-.55,0-1-.45-1-1V2.66c.38,.22,.73,.49,1.05,.81l3.49,3.49Zm1.46,12.05c0,1.65-1.35,3-3,3H7c-1.65,0-3-1.35-3-3V5c0-1.65,1.35-3,3-3h4.51c.16,0,.33,0,.49,.02V7c0,1.65,1.35,3,3,3h4.98c.02,.16,.02,.32,.02,.49v8.51ZM7.09,13h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.22h.84c1.18,0,2.14-.95,2.14-2.11s-.96-2.11-2.14-2.11Zm0,2.97h-.83s0-1.72,0-1.72h.84c.48,0,.89,.39,.89,.86s-.41,.86-.89,.86Zm11.93-2.34c0,.35-.28,.62-.62,.62h-1.69v1.14h1.24c.35,0,.62,.28,.62,.62s-.28,.62-.62,.62h-1.24v1.8c0,.35-.28,.62-.62,.62s-.62-.28-.62-.62v-4.81c0-.35,.28-.62,.62-.62h2.31c.35,0,.62,.28,.62,.62Zm-6.93-.62h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.56,.62,.56s1.46,0,1.46,0c1.18,0,2.14-.95,2.14-2.11v-1.78c0-1.16-.96-2.11-2.14-2.11Zm.89,3.89c0,.47-.41,.86-.89,.86h-.83s0-3.5,0-3.5h.84c.48,0,.89,.39,.89,.86v1.78Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==301 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21.122,18.229c.426.352.486.982.135,1.408-2.29,2.772-5.664,4.363-9.257,4.363C5.383,24,0,18.617,0,12S5.383,0,12,0c3.593,0,6.966,1.59,9.257,4.363.352.426.291,1.056-.135,1.408-.427.353-1.055.292-1.408-.134-1.908-2.311-4.72-3.637-7.714-3.637C6.486,2,2,6.486,2,12s4.486,10,10,10c2.994,0,5.806-1.325,7.714-3.637.353-.426.98-.485,1.408-.135Zm-7.622-10.229c1.381,0,2.5,1.119,2.5,2.5v3c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5v-3c0-1.381,1.119-2.5,2.5-2.5Zm0,1.6c-.496,0-.9.404-.9.9v3c0,.496.404.9.9.9s.9-.404.9-.9v-3c0-.496-.404-.9-.9-.9Zm9.25,3.881c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm-3.25-4.25c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Zm-1.156,6.75c.27,0,.522-.136.67-.363l4.178-6.4c.347-.532-.034-1.237-.67-1.237-.27,0-.522.136-.67.363l-4.178,6.4c-.347.532.034,1.237.67,1.237Zm-12.634-.081c.123.068.257.101.389.101.281,0,.553-.147.7-.411l3.046-5.474c.228-.457.204-.988-.065-1.423-.269-.434-.733-.692-1.243-.692h-2.737c-.442,0-.8.358-.8.8s.358.8.8.8h2.502l-2.901,5.212c-.215.386-.076.873.31,1.088Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Pending Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==302 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(302)">
                      <!-- <svg :class="[selectedSubMenuItem==9 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==9 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <circle cx="9" cy="10.77" r="1"/>
                        <circle cx="15" cy="15.231" r="1"/>
                        <g>
                          <path d="M12.477,1.121c-.305-.166-.676-.161-.975,.012-.386,.221-9.436,5.5-9.546,12.014,.152,4.996,5.027,9.784,10.058,9.853,5.003-.069,10.03-5.027,10.03-9.901C22.044,6.5,12.862,1.33,12.477,1.121Zm-.463,19.879c-3.946-.054-7.938-3.974-8.058-7.866,.078-4.58,6.257-8.828,8.057-9.969,1.798,1.088,7.924,5.144,8.031,9.945-.136,3.932-4.119,7.835-8.03,7.89Z"/>
                          <path d="M14.491,8.587c-.482-.272-1.09-.102-1.362,.379l-4,7.085c-.271,.481-.104,1.095,.38,1.362,.291,.161,.888,.301,1.362-.379l4-7.085c.271-.481,.102-1.091-.38-1.362Z"/>
                        </g>
                      </svg>
                      <span class="text-size-sm text-center">Rejected Liquidations</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Payment Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==400 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==6 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m1.886,8.437c-1.328.484-2.085,1.795-1.841,3.189.245,1.397,1.407,2.374,2.826,2.374h7.129v7.128c0,1.419.976,2.582,2.374,2.827.175.03.349.046.521.046,1.192,0,2.249-.727,2.666-1.878L24.028-.014,1.886,8.437Zm11.801,12.988c-.229.635-.797.59-.967.561-.169-.03-.72-.18-.72-.857v-9.128H2.871c-.678,0-.826-.551-.856-.72-.029-.168-.076-.736.561-.967L20.562,3.449l-6.875,17.976Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==400 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Payments Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==400" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Payment Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==400 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(400)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="M24,4c0-2.206-1.794-4-4-4H4C1.794,0,0,1.794,0,4c0,1.858,1.279,3.411,3,3.858V24H21V7.858c1.721-.447,3-2,3-3.858ZM2,4c0-.737,.405-1.375,1-1.722v3.443c-.595-.347-1-.985-1-1.722Zm3-2h3V22h-3V2Zm5,20V2h9V22H10ZM21,5.722V2.278c.595,.347,1,.985,1,1.722s-.405,1.375-1,1.722Zm-4,12.778c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Approved Payments</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==401 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(401)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==401 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==401 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
                      <span class="text-size-sm text-center">Pending Payments</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==402 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(402)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Rejected Payments</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Request Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==500 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <svg :class="[activeListItemIndex==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==500 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Request Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==500" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 3.1rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 3.1rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Request Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==500 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(500)">
                      <!-- <svg :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg>
                      <span class="text-size-sm text-center">Approved Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==501 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(501)">
                      <!-- <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1,13c-.553,0-1-.448-1-1C0,5.383,5.383,0,12,0c3.449,0,6.733,1.518,9,4.087V1c0-.552.447-1,1-1s1,.448,1,1v4c0,1.103-.897,2-2,2h-4c-.553,0-1-.448-1-1s.447-1,1-1h2.122c-1.867-1.893-4.434-3-7.122-3C6.486,2,2,6.486,2,12c0,.552-.447,1-1,1Zm22-2c-.553,0-1,.448-1,1,0,5.514-4.486,10-10,10-2.688,0-5.255-1.107-7.123-3h2.123c.553,0,1-.448,1-1s-.447-1-1-1H3c-1.103,0-2,.897-2,2v4c0,.552.447,1,1,1s1-.448,1-1v-3.087c2.267,2.569,5.551,4.087,9,4.087,6.617,0,12-5.383,12-12,0-.552-.447-1-1-1Zm-12.142,8.919c.374.054.759.081,1.142.081,4.411,0,8-3.589,8-8,0-.78-.111-1.55-.332-2.287-.159-.529-.715-.832-1.244-.671-.529.158-.83.715-.672,1.245.165.551.248,1.127.248,1.713,0,3.309-2.691,6-6,6-.288,0-.577-.021-.858-.061-.542-.078-1.052.301-1.132.848-.078.546.302,1.053.849,1.131Zm1.142-13.919c.286,0,.575.021.859.061.55.079,1.053-.303,1.131-.85.077-.546-.303-1.053-.85-1.131-.377-.053-.761-.081-1.141-.081-4.411,0-8,3.589-8,8,0,.777.111,1.546.331,2.286.13.434.527.715.959.715.094,0,.19-.014.285-.042.529-.157.831-.714.674-1.244-.165-.555-.249-1.132-.249-1.715,0-3.309,2.691-6,6-6Zm1.126,8.2h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Pending Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==502 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(502)">
                      <!-- <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m23.55,19.071l-4.293,4.207c-.48.48-1.116.722-1.753.722-.641,0-1.283-.244-1.771-.732l-4.25-4.25,2.121-2.121,2.396,2.396V0h3v19.33l2.45-2.401,2.1,2.143Zm-10.246-7.445c0-1.033-.734-1.929-1.747-2.131l-2.792-.559c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.127c.316,0,.585.211.671.5h1.62c-.102-1.175-1.09-2.1-2.291-2.1h-.2v-1.2h-1.6v1.2h-.327c-1.198,0-2.173.975-2.173,2.173,0,1.033.734,1.929,1.747,2.131l2.791.559c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.126c-.316,0-.585-.211-.671-.5h-1.62c.102,1.175,1.09,2.1,2.291,2.1h.2v1.2h1.6v-1.2h.326c1.199,0,2.174-.975,2.174-2.173Zm-10.304-1.626c0-3.86,3.14-7,7-7,1.487,0,2.865.47,4,1.264V.841c-1.226-.538-2.578-.841-4-.841C4.486,0,0,4.486,0,10s4.486,10,10,10v-3c-3.86,0-7-3.14-7-7Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Rejected Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==503 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(503)">
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><polygon points="18.707 6.707 17.293 5.293 12 10.586 6.707 5.293 5.293 6.707 10.586 12 5.293 17.293 6.707 18.707 12 13.414 17.293 18.707 18.707 17.293 13.414 12 18.707 6.707"/></g></svg> -->
                      <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,22.5c0,.828-.672,1.5-1.5,1.5h-3.819c-2.838,0-5.245-2.215-5.481-5.043l-1.08-12.957h-.619c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h4.757c.619-1.746,2.287-3,4.243-3h3c1.956,0,3.624,1.254,4.243,3h4.757c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5H5.131l1.059,12.707c.106,1.286,1.201,2.293,2.491,2.293h3.819c.828,0,1.5.672,1.5,1.5Zm8.5-14.5h-6c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h6c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-4c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h4c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-2c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h2c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Withdrawn Requests</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Financial Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==600 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg :class="[activeListItemIndex==8 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==8 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m17.163,13c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-1.163v-6c0-.275.225-.5.5-.5h.663c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-.663c-1.379,0-2.5,1.121-2.5,2.5v6h-2v2h2v6c0,1.379,1.121,2.5,2.5,2.5h.663c.434,1.441,1.757,2.5,3.337,2.5,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5c-1.58,0-2.903,1.059-3.337,2.5h-.663c-.275,0-.5-.225-.5-.5v-6h1.163Zm3.337-2.5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm0-8.5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm0,17c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm-9.8-10.861l-1.411.813c-.605-.652-1.393-1.126-2.29-1.331v-1.621h-2v1.621c-.896.205-1.685.679-2.29,1.331l-1.411-.813-.998,1.732,1.416.816c-.129.418-.218.853-.218,1.313s.089.895.218,1.313l-1.416.816.998,1.732,1.411-.813c.605.652,1.393,1.126,2.29,1.331v1.621h2v-1.621c.896-.205,1.685-.679,2.29-1.331l1.411.813.998-1.732-1.416-.816c.129-.418.218-.853.218-1.313s-.089-.895-.218-1.313l1.416-.816-.998-1.732Zm-4.7,6.361c-1.379,0-2.5-1.121-2.5-2.5s1.121-2.5,2.5-2.5,2.5,1.121,2.5,2.5-1.121,2.5-2.5,2.5Z"/>
                    </svg> -->
                    <svg :class="[activeListItemIndex==600 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m9,13h2.697c-.647.519-1.154,1.204-1.437,2h-1.26v-2Zm15,3.5v7.5h-12v-7.5c0-1.379,1.121-2.5,2.5-2.5h.5v-.5c0-.827.673-1.5,1.5-1.5h3c.827,0,1.5.673,1.5,1.5v.5h.5c1.379,0,2.5,1.121,2.5,2.5Zm-10,0v1.5h8v-1.5c0-.275-.225-.5-.5-.5h-7c-.275,0-.5.225-.5.5Zm8,5.5v-2h-3v1h-2v-1h-3v2h8ZM2,3c0-.552.448-1,1-1h10c.552,0,1,.448,1,1v8.065c.526-.54,1.219-.915,2-1.027V3c0-1.654-1.346-3-3-3H3C1.346,0,0,1.346,0,3v21h10v-2H2V3Zm5,10h-3v2h3v-2Zm-3,6h3v-2h-3v2Zm3-14h-3v2h3v-2Zm5,0h-3v2h3v-2Zm-5,4h-3v2h3v-2Zm2,2h3v-2h-3v2Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==600 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Financial Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==600" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 3.0rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 3.0rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-72
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Financial Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==600 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(600)">
                      <!-- <svg :class="[selectedSubMenuItem==15 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m15 3a3 3 0 0 0 -3-3h-12v24h2v-11h8v1a3 3 0 0 0 3 3h11v-13h-9zm-13-1h10a1 1 0 0 1 1 1v8h-11zm20 4v9h-9a1 1 0 0 1 -1-1v-1h3v-7z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==600 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m10,14.11l11,3.143v6.747h-3v-4.484l-8-2.286v6.769h-3l-4.266-3.391c-.866-.722-.983-2.008-.261-2.874.722-.866,2.008-.983,2.874-.261l1.64,1.222v-10.234c0-.911.799-1.632,1.739-1.479.737.12,1.273.812,1.273,1.56v5.57ZM24,3.5v7.5h-12v-2.421c0-.353-.064-.693-.162-1.019l.168-.13,1.63,1.261.378-.262-.663-2.015,1.648-.955v-.458h-2.163l-.588-2.251h-.486l-.588,2.251h-5.338l-.588-2.251h-.486l-.588,2.251h-2.175v.462l1.66.924-.652,2.039.36.272,1.638-1.266.133.103c-.09.312-.152.633-.152.966v2.501H0V3.5C0,1.57,1.57,0,3.5,0h17c1.93,0,3.5,1.57,3.5,3.5Zm-2,1.5h-2.163l-.588-2.251h-.486l-.588,2.251h-2.175v.462l1.66.924-.652,2.039.36.272,1.638-1.266,1.63,1.261.378-.262-.663-2.015,1.648-.955v-.458Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==600 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m24,3c0-1.654-1.346-3-3-3H6.5c-1.93,0-3.5,1.57-3.5,3.5v4.5h2V3.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v2.5h11v15c0,.551-.449,1-1,1h-5v2h5c1.654,0,3-1.346,3-3V6h3v-3Zm-2,1h-12v-.5c0-.537-.122-1.045-.338-1.5h11.338c.551,0,1,.449,1,1v1Zm-13.5,6H2.5c-1.378,0-2.5,1.122-2.5,2.5v11.5h11v-11.5c0-1.378-1.122-2.5-2.5-2.5Zm.5,12H2v-9.5c0-.276.224-.5.5-.5h6c.276,0,.5.224.5.5v9.5ZM3,13h5v2H3v-2Zm3,3h2v2h-2v-2Zm-3,0h2v2h-2v-2Zm3,3h2v2h-2v-2Zm-3,0h2v2h-2v-2Z"/>
                      </svg>
                      <span class="text-size-sm text-center">EGPAF Per Diem</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==601 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(601)">
                      <svg :class="[selectedSubMenuItem==16 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,1H3C1.346,1,0,2.346,0,4v19h24V4c0-1.654-1.346-3-3-3Zm1,20H2V4c0-.551.449-1,1-1h18c.551,0,1,.449,1,1v17ZM10,6h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">MOH Per Diems</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==602 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(602)">
                      <!-- <svg :class="[selectedSubMenuItem==602 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512" height="512">
                        <g>
                          <path d="M309.333,149.333H480c17.673,0,32-14.327,32-32s-14.327-32-32-32H309.333c-17.673,0-32,14.327-32,32   S291.66,149.333,309.333,149.333z"/>
                          <path d="M117.333,0C52.532,0,0,52.532,0,117.333s52.532,117.333,117.333,117.333s117.333-52.532,117.333-117.333   C234.596,52.561,182.106,0.071,117.333,0z M117.333,170.667C87.878,170.667,64,146.789,64,117.333S87.878,64,117.333,64   s53.333,23.878,53.333,53.333S146.789,170.667,117.333,170.667z"/>
                          <path d="M117.333,277.333C52.532,277.333,0,329.865,0,394.667S52.532,512,117.333,512s117.333-52.532,117.333-117.333   C234.596,329.895,182.106,277.404,117.333,277.333z M117.333,448C87.878,448,64,424.122,64,394.667s23.878-53.333,53.333-53.333   s53.333,23.878,53.333,53.333S146.789,448,117.333,448z"/>
                          <path d="M394.667,277.333c-64.801,0-117.333,52.532-117.333,117.333S329.865,512,394.667,512S512,459.468,512,394.667   C511.929,329.895,459.439,277.404,394.667,277.333z M394.667,448c-29.455,0-53.333-23.878-53.333-53.333   s23.878-53.333,53.333-53.333S448,365.211,448,394.667S424.122,448,394.667,448z"/>
                        </g>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==602 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8.293,4.121c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.293,1.293V1c0-.552.448-1,1-1s1,.448,1,1v3l1.293-1.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293c-.39.39-.902.585-1.414.585s-1.024-.195-1.414-.585l-2.293-2.293Zm14.707,17.879h-2v-12h.292c1.023,0,1.95-.555,2.417-1.448.438-.837.381-1.797-.153-2.566-.448-.645-1.024-1.163-1.671-1.499l-4.069-2.105c.387,1.062.156,2.302-.695,3.153l-2.293,2.293c-.754.755-1.759,1.171-2.828,1.171s-2.075-.416-2.829-1.171l-2.292-2.292c-.851-.851-1.082-2.09-.695-3.153l-4.072,2.106c-.643.335-1.22.853-1.668,1.498-.534.769-.591,1.728-.153,2.565.467.894,1.394,1.448,2.418,1.448h.292v12H1c-.552,0-1,.448-1,1s.448,1,1,1h6c.552,0,1-.448,1-1s-.448-1-1-1h-2v-12h6v2c-1.654,0-3,1.346-3,3,0,1.36.974,2.51,2.315,2.733l3.042.507c.373.062.644.382.644.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.277-.478-.889-.642-1.366-.365-.479.277-.642.888-.365,1.366.535.925,1.53,1.499,2.598,1.499h.268v1c0,.552.448,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551.449-1,1-1h2.268c.356,0,.688.192.867.5.274.479.886.641,1.366.365.478-.277.642-.888.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-2h6v12h-2c-.553,0-1,.448-1,1s.447,1,1,1h6c.553,0,1-.448,1-1s-.447-1-1-1Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Bank Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==603 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(603)">
                      <!-- <svg :class="[selectedSubMenuItem==16 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,1H3C1.346,1,0,2.346,0,4v19h24V4c0-1.654-1.346-3-3-3Zm1,20H2V4c0-.551.449-1,1-1h18c.551,0,1,.449,1,1v17ZM10,6h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==603 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="M19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm3,19c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V5c0-.352,.072-.686,.184-1H21.816c.112,.314,.184,.648,.184,1v14ZM4,8c0-.552,.447-1,1-1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-.553,0-1-.448-1-1Zm10,5c0,.553-.447,1-1,1H7c-.553,0-1-.447-1-1s.447-1,1-1h6c.553,0,1,.448,1,1Zm0,5c0,.553-.447,1-1,1H5c-.553,0-1-.447-1-1s.447-1,1-1H13c.553,0,1,.447,1,1Zm6,0c0,1.105-.895,2-2,2s-2-.895-2-2c0-.738,.405-1.376,1-1.723v-6.277c0-.551-.448-1-1-1h-1.277c-.346,.595-.984,1-1.723,1-1.105,0-2-.895-2-2s.895-2,2-2c.738,0,1.376,.405,1.723,1h1.277c1.654,0,3,1.346,3,3v6.277c.595,.346,1,.984,1,1.723Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Spend Plan Management</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==604 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(604)">
                      <!-- <svg :class="[selectedSubMenuItem==16 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,1H3C1.346,1,0,2.346,0,4v19h24V4c0-1.654-1.346-3-3-3Zm1,20H2V4c0-.551.449-1,1-1h18c.551,0,1,.449,1,1v17ZM10,6h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm2,4h9v2h-9v-2Zm-2,1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==604 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="M19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm3,19c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V5c0-.352,.072-.686,.184-1H21.816c.112,.314,.184,.648,.184,1v14ZM4,8c0-.552,.447-1,1-1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-.553,0-1-.448-1-1Zm10,5c0,.553-.447,1-1,1H7c-.553,0-1-.447-1-1s.447-1,1-1h6c.553,0,1,.448,1,1Zm0,5c0,.553-.447,1-1,1H5c-.553,0-1-.447-1-1s.447-1,1-1H13c.553,0,1,.447,1,1Zm6,0c0,1.105-.895,2-2,2s-2-.895-2-2c0-.738,.405-1.376,1-1.723v-6.277c0-.551-.448-1-1-1h-1.277c-.346,.595-.984,1-1.723,1-1.105,0-2-.895-2-2s.895-2,2-2c.738,0,1.376,.405,1.723,1h1.277c1.654,0,3,1.346,3,3v6.277c.595,.346,1,.984,1,1.723Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Other Per Diems</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>

            <!-- divider line -->
            <div class=" h-2 w-full border-t"></div>
            <div  class="px-4 color: #42b983"  @click="barClick(1000)">
              <a href="#">
                <div class="flex space-x-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500 fill-current" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M15,2.426v2.1a9,9,0,1,1-6,0v-2.1a11,11,0,1,0,6,0Z"/><rect x="11" width="2" height="8"/></g></svg>
                  <span class="font-medium text-size-sm  text-gray-600">Signout</span>
                </div>
              </a>
            </div>
          </nav>
        </div>
  </template>
  
  <script>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { mapState } from 'vuex'
  // import { ajax } from 'rxjs/ajax'
  
  export default {
    computed: {
      ...mapState([
        'activeListItemIndex',
        'selectedSubMenuItem',
        'email',
        'loginId',
        'token'
      ])
    },
    components: {
      Popover,
      PopoverButton,
      PopoverPanel
    },
    setup () {
      return {
      }
    },
    name: 'side-bar',
    props: {
    },
    data: () => {
      return {
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
    },
    methods: {
      // signout of the system
      // logs out users
      logout () {
        this.$router.push('/')
        // const observable = ajax({
        //   url: this.BASE_URL + '/login/signout',
        //   method: 'PUT',
        //   headers: {
        //     Authorization: `Bearer ${this.token}`
        //   },
        //   body: {
        //     id: this.loginId
        //   }
        // })

        // const observer = {
        //   next: () => {
        //   },
        //   error: () => {
        //     this.$router.push('/')
        //   },
        //   complete: () => {
        //     this.$router.push('/')
        //   }
        // }
  
        // observable.subscribe(observer)
      },
      // side nav bar list items clicks
      barClick (index) {
        switch (index) {
          case 0:
            this.$router.push('/admin-home')
            this.$store.commit('mutateActiveListItemIndex', 0)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            break
          case 1:
            this.$store.commit('mutateActiveListItemIndex', 1)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            this.$router.push('/funding-management')
            break
          case 200:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 200)
            this.$router.push('/employee-management')
            break
          case 201:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 201)
            this.$router.push('/deactivated-accounts')
            break
          case 202:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 202)
            this.$router.push('/delegate-approvals')
            break
          case 203:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 203)
            this.$router.push('/position-management')
            break
          case 204:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 204)
            this.$router.push('/employee-banking')
            break
          case 205:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 205)
            this.$router.push('/supervisor-management')
            break
          case 300:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 300)
            this.$router.push('/approved-liquidations')
            break
          case 301:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 301)
            this.$router.push('/pending-liquidations')
            break
          case 302:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 302)
            this.$router.push('/rejected-liquidations')
            break
          case 400:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 400)
            this.$router.push('/approved-payments')
            break
          case 401:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 401)
            this.$router.push('/pending-payments')
            break
          case 402:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 402)
            this.$router.push('/rejected-payments')
            break
          case 500:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 501)
            this.$router.push('/approved-requests')
            break
          case 501:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', 13)
            this.$router.push('/pending-requests')
            break
          case 502:
            this.$store.commit('mutateActiveListItemIndex', 7)
            this.$store.commit('mutateSelectedSubMenuItem', 14)
            this.$router.push('/rejected-requests')
            break
          case 503:
            this.$store.commit('mutateActiveListItemIndex', 8)
            this.$store.commit('mutateSelectedSubMenuItem', 15)
            this.$router.push('/withdrawn-requests')
            break



          case 600:
            this.$store.commit('mutateActiveListItemIndex', 600)
            this.$store.commit('mutateSelectedSubMenuItem', 600)
            this.$router.push('/egpaf-per-diem')
            break
          case 601:
            this.$store.commit('mutateActiveListItemIndex', 600)
            this.$store.commit('mutateSelectedSubMenuItem', 601)
            this.$router.push('/moh-per-diem')
            break
          case 602:
            this.$store.commit('mutateActiveListItemIndex', 600)
            this.$store.commit('mutateSelectedSubMenuItem', 602)
            this.$router.push('/bank-management')
            break
          case 603:
            this.$store.commit('mutateActiveListItemIndex', 600)
            this.$store.commit('mutateSelectedSubMenuItem', 603)
            this.$router.push('/spend-plan-management')
            break
          case 604:
            this.$store.commit('mutateActiveListItemIndex', 600)
            this.$store.commit('mutateSelectedSubMenuItem', 604)
            this.$router.push('/other-per-diem')
            break

          default:
            // signout
            this.logout()
        }
      }
    }
  }
  </script>
  
  <style>
  </style>
  