<template>
    <CreatePayment1></CreatePayment1>
</template>
<script>
    import CreatePayment1 from '@/components/CreatePayment1.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreatePayment1
        }
    }
</script>