import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    employee: null,
    activeListItemIndex: null,
    selectedSubMenuItem: null,
    advance: null,
    hotelNeed: null,
    hotel: null,
    mieAdvance: null,
    lodgingAdvance: null,
    otherAdvance: null,
    tor: null,
    requestAttachments: [],
    liquidationRequest: null,
    liquidation75Request: null,
    liquidation75RequestData: null,
    liquidationAdvances: null,
    expenseReport: null,
    tripReport: null,
    liquidationAttachments: [],
    focalPerson: null,
    paymentAttachments: null,
    paymentSpreadsheet: null,
    paymentMethod: null,
    paymentVoucherSheet: null,
    paymentVoucherDetails: null,
    liquidationPayment: null,
    selectedLiquidationPaymentData: null,
    paymentLiquidationAttachments: [],
    paymentLiquidationPaymentMethod: null,
    paymentLiquidationSpreadsheet: null,
    paymentLiquidationVoucherSheet: null,
    paymentLiquidationVoucherDetails: null,
    eventName: '',
    venue: '',
    departureDate: '',
    returnDate: '',
    liquidationCurrencyAndFunding: null,
    liquidation75VoucherDetails: null,
    liquidation75VoucherSheet: null,
    liquidation25Request: null,
    liquidation25RequestData: null,
    liquidation25VoucherDetails: null,
    liquidation25VoucherSheet: null,
  },
  mutations: {
    mutateEmployee (state, employee) {
      state.employee = employee
    },
    mutateActiveListItemIndex (state, activeListItemIndex) {
      state.activeListItemIndex = activeListItemIndex
    },
    mutateSelectedSubMenuItem (state, selectedSubMenuItem) {
      state.selectedSubMenuItem = selectedSubMenuItem
    },
    mutateAdvance (state, advance) {
      state.advance = advance
    },
    mutateHotel (state, hotel) {
      state.hotel = hotel
    },
    mutateMieAdvance (state, mieAdvance) {
      state.mieAdvance = mieAdvance
    },
    mutateLodgingAdvance (state, lodgingAdvance) {
      state.lodgingAdvance = lodgingAdvance
    },
    mutateOtherAdvance (state, otherAdvance) {
      state.otherAdvance = otherAdvance
    },
    mutateTor (state, tor) {
      state.tor = tor
    },
    mutateRequestAttachments (state, requestAttachments) {
      state.requestAttachments = requestAttachments
    },
    mutateHotelNeed (state, hotelNeed) {
      state.hotelNeed = hotelNeed
    },
    mutateLiquidationRequest (state, liquidationRequest) {
      state.liquidationRequest = liquidationRequest
    },
    mutateLiquidationAdvances (state, liquidationAdvances) {
      state.liquidationAdvances = liquidationAdvances
    },
    mutateExpenseReport (state, expenseReport) {
      state.expenseReport = expenseReport
    },
    mutateTripReport (state, tripReport) {
      state.tripReport = tripReport
    },
    mutateLiquidationAttachments (state, liquidationAttachments) {
      state.liquidationAttachments = liquidationAttachments
    },
    mutateFocalPerson (state, focalPerson) {
      state.focalPerson = focalPerson
    },
    mutatePaymentAttachments (state, paymentAttachments) {
      state.paymentAttachments = paymentAttachments
    },
    mutatePaymentSpreadsheet (state, paymentSpreadsheet) {
      state.paymentSpreadsheet = paymentSpreadsheet
    },
    mutatePaymentMethod (state, paymentMethod) {
      state.paymentMethod = paymentMethod
    },
    mutatePaymentVoucherSheet (state, paymentVoucherSheet) {
      state.paymentVoucherSheet = paymentVoucherSheet
    },
    mutatePaymentVoucherDetails (state, paymentVoucherDetails) {
      state.paymentVoucherDetails = paymentVoucherDetails
    },
    mutateLiquidationPayment (state, liquidationPayment) {
      state.liquidationPayment = liquidationPayment
    },
    mutateSelectedLiquidationPaymentData (state, selectedLiquidationPaymentData) {
      state.selectedLiquidationPaymentData = selectedLiquidationPaymentData
    },
    mutatePaymentLiquidationPaymentMethod (state, paymentLiquidationPaymentMethod) {
      state.paymentLiquidationPaymentMethod = paymentLiquidationPaymentMethod
    },
    mutatePaymentLiquidationAttachments (state, paymentLiquidationAttachments) {
      state.paymentLiquidationAttachments = paymentLiquidationAttachments
    },
    mutatePaymentLiquidationSpreadsheet (state, paymentLiquidationSpreadsheet) {
      state.paymentLiquidationSpreadsheet = paymentLiquidationSpreadsheet
    },
    mutatePaymentLiquidationVoucherSheet (state, paymentLiquidationVoucherSheet) {
      state.paymentLiquidationVoucherSheet = paymentLiquidationVoucherSheet
    },
    mutatePaymentLiquidationVoucherDetails (state, paymentLiquidationVoucherDetails) {
      state.paymentLiquidationVoucherDetails = paymentLiquidationVoucherDetails
    },
    mutateEventName (state, eventName) {
      state.eventName = eventName
    },
    mutateVenue (state, venue) {
      state.venue = venue
    },
    mutateDepartureDate (state, departureDate) {
      state.departureDate = departureDate
    },
    mutateReturnDate (state, returnDate) {
      state.returnDate = returnDate
    },
    mutateLiquidationCurrencyAndFunding (state, liquidationCurrencyAndFunding) {
      state.liquidationCurrencyAndFunding = liquidationCurrencyAndFunding
    },
    mutateLiquidation75Request (state, liquidation75Request) {
      state.liquidation75Request = liquidation75Request
    },
    mutateLiquidation75RequestData (state, liquidation75RequestData) {
      state.liquidation75RequestData = liquidation75RequestData
    },
    mutateLiquidation75VoucherDetails (state, liquidation75VoucherDetails) {
      state.liquidation75VoucherDetails = liquidation75VoucherDetails
    },
    mutateLiquidation75VoucherSheet (state, liquidation75VoucherSheet) {
      state.liquidation75VoucherSheet = liquidation75VoucherSheet
    },


    mutateLiquidation25Request (state, liquidation25Request) {
      state.liquidation25Request = liquidation25Request
    },
    mutateLiquidation25RequestData (state, liquidation25RequestData) {
      state.liquidation25RequestData = liquidation25RequestData
    },
    mutateLiquidation25VoucherDetails (state, liquidation25VoucherDetails) {
      state.liquidation25VoucherDetails = liquidation25VoucherDetails
    },
    mutateLiquidation25VoucherSheet (state, liquidation25VoucherSheet) {
      state.liquidation25VoucherSheet = liquidation25VoucherSheet
    }
  },
  actions: {
  },
  modules: {
  }
})