<template>
    <OtherPerDiem></OtherPerDiem>
</template>
<script>
    import OtherPerDiem from '@/components/OtherPerDiem.vue'
    export default {
        name: 'funding-management',
        components: {
            OtherPerDiem
        }
    }
</script>