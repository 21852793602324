<template>
    <EmployeeTrack75Liquidation></EmployeeTrack75Liquidation>
</template>
<script>
    import EmployeeTrack75Liquidation from '@/components/EmployeeTrack75Liquidation.vue'
    export default {
        name: 'employee-track-liquidation',
        components: {
            EmployeeTrack75Liquidation
        }
    }
</script>