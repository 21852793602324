<template>
    <EditLiquidation5></EditLiquidation5>
</template>
<script>
    import EditLiquidation5 from '@/components/EditLiquidation5.vue'
    export default {
        name: 'create-liquidation',
        components: {
            EditLiquidation5
        }
    }
</script>