<template>
    <div>
      <TransitionRoot appear :show="isMessageOpen" @close="closeMessageModal" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
              </TransitionChild>
  
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
  
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="inline-block border w-full max-w-4xl p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl"
                >
                  <DialogTitle
                    as="h4"
                    class="text-size font-medium leading-6 text-emerald-500 flex space-x-0.5 items-center mb-4"
                  >
                    <!-- <svg class="h-5 w-5 fill-current animate-pulse" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.91,17.05l-1.65-7.82c-1.03-3.67-4.42-6.24-8.26-6.24-4.05,0-7.49,2.74-8.37,6.69l-1.55,7.43c-.21,.95,.02,1.93,.63,2.69,.61,.76,1.52,1.19,2.49,1.19h3.35c.24,1.69,1.69,3,3.45,3s3.21-1.31,3.45-3h3.35c.93,0,1.79-.39,2.39-1.07l.16-.19c.61-.8,.81-1.82,.56-2.69Zm-2.93,.86h0c-.04,.06-.09,.09-.16,.09H7.2c-.07,0-.12-.02-.16-.07-.04-.05-.05-.1-.03-.17l1.55-7.43c.57-2.55,2.81-4.33,5.44-4.33,2.5,0,4.7,1.66,5.35,3.95l1.65,7.82c.01,.05,0,.1-.02,.15ZM5.26,5.61c-.73,1.08-1.29,2.32-1.59,3.67l-1.54,7.41c-.08,.35-.12,.71-.12,1.06-.5-.2-.95-.51-1.3-.94-.61-.76-.84-1.74-.63-2.69L1.62,6.69C2.51,2.74,5.95,0,10,0c1.43,0,2.81,.36,4.02,1,0,0-.01,0-.02,0-3.26,0-6.2,1.46-8.15,3.83-.02,.02-.03,.04-.05,.06-.16,.19-.31,.39-.45,.6-.03,.05-.06,.09-.09,.13Z"/></svg> -->
                    <svg class="h-5 w-5 fill-current animate-pulse" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m14,1.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm8.5,3.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm-4.667,3L22.5,0h-2.333l-4.667,8h2.333Zm5.167,4.5v8c0,1.995-2.58,3.5-6,3.5-2.151,0-3.968-.596-5.022-1.534-1.217,1.01-3.353,1.534-5.478,1.534-3.23,0-6.5-1.202-6.5-3.5V4.5C0,2.202,3.27,1,6.5,1s6.5,1.202,6.5,3.5v5.353c1.047-.534,2.433-.853,4-.853,3.42,0,6,1.505,6,3.5ZM2,4.5c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5-1.577-1.5-4.5-1.5-4.5,1.064-4.5,1.5Zm0,4c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm0,4c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm0,4c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm9,4v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5Zm10,0v-1.353c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4v-1.353c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm0-4c0-.529-1.519-1.5-4-1.5s-4,.971-4,1.5,1.519,1.5,4,1.5,4-.971,4-1.5Z"/>
                    </svg>
                    <span class=" text-gray-600">Funding Sources Percentage Allocation</span>
                  </DialogTitle>
                  <div class="my-1">
                    
                      <div v-for="(data, i) in budgetSources" :key="i" class="grid grid-cols-2 gap-x-4 mb-4">
                        <!-- the budget source -->
                        <div class=" col-span-1 ">
                          <label v-if="i==0" for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Funding Source <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              :value="data.name"
                              required
                              disabled
                              class="bg-gray-100 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                        <!-- the percentage -->
                        <div class="col-span-1 ">
                          <label v-if="i==0" for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Percentage(%) <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              :id="data.id"
                              v-model="data.percentage"
                              placeholder="Percentage"
                              required
                              @keyup="changed()"
                              type="number"
                              class="bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                      </div>

                      <!-- // the totals row -->
                      <div class="grid grid-cols-2 gap-x-4 mb-4">
                        <!-- the budget source -->
                        <div class=" col-span-1 ">
                          <div class="flex ">
                            <input
                              value="Total"
                              placeholder="Total"
                              required
                              disabled
                              class="bg-gray-100 text-size-md shadow-sm text-gray-600 font-medium w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"/>
                          </div>
                        </div>
                        <!-- the percentage -->
                        <div class="col-span-1 ">
                          <label v-if="i==0" for="email" class="block text-size-md font-medium text-gray-600 text-left mb-2">Percentage(%) <span class="font-bold text-red-500">*</span></label>
                          <div class="flex ">
                            <input
                              v-model="total"
                              placeholder="Percentage"
                              required
                              disabled
                              :class=" !isNaN(total.toString().replace('%', '')) ? 'bg-gray-50 text-size-md shadow-sm text-gray-600 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600' : 'bg-gray-50 text-size-md shadow-sm text-red-500 w-full border px-2 py-3 border-gray-300 focus:outline-none focus:border-red-600 focus:ring-1 focus:ring-red-600'"/>
                          </div>
                        </div>
                      </div>

                  </div>
  
                  <div class="mt-2 flex justify-center space-x-0.5">
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="closeMessageModal()"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class=" items-center inline-flex justify-center px-4 py-1 text-size text-emerald-900 bg-gray-300 border
                              border-transparent hover:bg-emerald-600 hover:opacity-90 hover:text-white focus:outline-none focus-visible:ring-2
                              focus-visible:ring-offset-2 focus-visible:ring-white"
                      @click="saveData()"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue'
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay
      // DialogTitle
    },
    setup () {
      const isMessageOpen = ref(false)
      return {
        isMessageOpen,
        closeMessageModal () {
          isMessageOpen.value = false
        },
        openMessageModal () {
          isMessageOpen.value = true
        }
      }
    },
    name: 'message-modal',
    props: {
      isOk: Boolean,
      title: String,
      message: String,
      budgetSources: Array
    },
    data: () => {
      return {
        total: 0
      }
    },
    mounted () {
      this.initialTotal()
    },
    methods: {
      // set the initial total
      initialTotal () {
        if (this.budgetSources !== undefined && this.budgetSources !== null && Array.isArray(this.budgetSources)) {
          const size = this.budgetSources.length
          for (let i = 0; i < size; i++) {
            const data = this.budgetSources[i]
            const percentage = parseFloat(data.percentage)

            // sum up the percentage
            this.total += percentage
          }

          this.total = parseInt(this.total) + '%'
        }

      },
      // validate the budget sources
      saveData () {
        const percentageData = []
        var isValid = true
        var isEmpty = false
        const size = this.budgetSources.length

        // check if the total is 100%
        if (parseInt(this.total) !== 100) {
          isValid = false
        }

        if (isValid) {
          // check if there is no empty percentage
          for (let i = 0; i < size; i++) {
            const data = this.budgetSources[i]
            const id = data.id
            const element = document.getElementById(id)
            const value = element.value

            if (value === null || value === undefined || value.trim() === '' || isNaN(value)) {
              isEmpty = true
              break

            } else {
              data.percentage = value
              percentageData.push(data)
            }
          }
        }

        if (!isValid) {
          // close the request and emit an event that the funding sources cannot be saved
          this.$emit('error', 'Error, the total budget percentage allocation is not equal to 100%.')
        
        } else if (isEmpty) {
          this.$emit('error', 'Please fill all the percentage fields, to save the percentages.')
        
        } else {
          // emit an event for saving the funding sources percentage
          this.$emit('success', percentageData )
        }
        this.closeMessageModal()
      },
      // called when the input values are being changed
      changed () {
        // get the data from the input fields
        const size = this.budgetSources.length
        this.total = 0
        for (let i = 0; i < size; i++) {
          const data = this.budgetSources[i]
          const id = data.id
          const element = document.getElementById(id)
          const value = element.value

          if (value !== null && value !== null && value.trim() !== '' && !isNaN(value)) {
            this.total += parseFloat(value)
          }
          
        }

        if (this.total > 100) {
          this.total = 'The budget percentage total cannot exceed 100%'
        } else {
          this.total += '%'
        }
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
  </style>
  