<template>
    <EmployeePayments></EmployeePayments>
</template>
<script>
    import EmployeePayments from '@/components/EmployeePayments.vue'
    export default {
        name: 'employee-payments',
        components: {
            EmployeePayments
        }
    }
</script>