<template>
    <EditRequest3></EditRequest3>
</template>
<script>
    import EditRequest3 from '@/components/EditRequest3.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditRequest3
        }
    }
</script>