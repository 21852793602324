<template>
    <CreateRequest6></CreateRequest6>
</template>
<script>
    import CreateRequest6 from '@/components/CreateRequest6.vue'
    export default {
        name: 'create-request-6',
        components: {
            CreateRequest6
        }
    }
</script>