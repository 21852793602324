<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin' : 'main px-5 space-y-2']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Administrator Profile</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <div
                v-if="employeeData !== null"
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-2 space-y-2.5">
                  <div class="justify-between flex">
                    <div class="space-x-1 text-size-md">
                      <span class=" font-medium">Firstname:</span>
                      <span class="py-1 px-4">{{ employeeData.firstname }} {{ employeeData.surname }}</span>
                    </div>
                    <svg class="h-4 w-4 fill-emerald-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="M17.5,24c-3.584,0-6.5-2.916-6.5-6.5s2.916-6.5,6.5-6.5,6.5,2.916,6.5,6.5-2.916,6.5-6.5,6.5Zm0-11c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5,4.5-2.019,4.5-4.5-2.019-4.5-4.5-4.5Zm.999,6.354l1.886-1.833c.396-.385,.405-1.018,.021-1.414-.385-.395-1.018-.406-1.414-.02l-1.892,1.838c-.099,.1-.262,.1-.362,0l-.876-.858c-.395-.386-1.027-.379-1.414,.016s-.38,1.027,.015,1.414l.876,.858c.437,.428,1.01,.641,1.582,.641s1.146-.215,1.579-.643Zm-9.499-7.354c-3.309,0-6-2.691-6-6S5.691,0,9,0s6,2.691,6,6-2.691,6-6,6Zm0-10c-2.206,0-4,1.794-4,4s1.794,4,4,4,4-1.794,4-4-1.794-4-4-4ZM2,23c0-3.524,2.633-6.511,6.124-6.946,.548-.068,.937-.568,.869-1.116s-.574-.931-1.116-.868C3.386,14.629,0,18.469,0,23c0,.553,.448,1,1,1s1-.447,1-1Z"/>
                    </svg>
                  </div>
                  <div class="space-x-1 text-size-md">
                    <span class=" font-medium">Surname:</span>
                    <span class="py-1 px-4">{{ employeeData.surname }}</span>
                  </div>
                  <div class="space-x-1 text-size-md">
                    <span class=" font-medium">Role:</span>
                    <span class="py-1 px-4">System Administrator</span>
                  </div>
                  <div class="space-x-1 text-size-md">
                    <span class=" font-medium">Priviledges:</span>
                    <span class="py-1 px-4">All Granted</span>
                  </div>
                  <div class="space-x-1 text-size-md">
                    <span class=" font-medium">Email:</span>
                    <span class="py-1 px-4">{{ employeeData.email }}</span>
                  </div>
                  <div class="space-x-1 text-size-md">
                    <span class=" font-medium">Notice:</span>
                    <span class="py-1 px-2 bg-red-100">Use This Account With Care</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the pop up modal -->
    <PopUp 
      ref="popupRef"
      :message=" 'Hi, ' + firstname + ' ' + surname" />
  </div>
</template>

<script>
import Navbar from '@/components/AdminNavbar.vue'
import Sidebar from '@/components/AdminSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import PopUp from './PopUp.vue'

export default {
  computed: {
    ...mapState([
      'employee'
    ])
  },
  components: {
    PopUp,
    Navbar,
    Sidebar
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      employeeData: null,
      signatureLink: null
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 100)
      this.$store.commit('mutateSelectedSubMenuItem', 100)
      // getting the charts data
      this.getEmployee()
    }
  },
  methods: {
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // get the requests chart data
    getEmployee () {
      const observable = ajax({
        url: process.env.VUE_APP_BASE_URL + `/admin/findById?id=${this.employee.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      })

      const observer = {
        next: (response) => {
          const size = response.response.length
          if (size > 0) {
            this.employeeData = response.response[0]
          }
        },
        error: (err) => {
          const status = err.status
          if (status === 401) {
            this.$router.push('/')
          }
        },
        complete: () => {
        }
      }
      observable.subscribe(observer)
    },
    getSignature (filename) {
        // download the signature
          const observable = ajax({
            method: 'GET',
            url: process.env.VUE_APP_BASE_URL + '/signature/download?filename=' + filename,
            headers: {
              Authorization: `Bearer ${this.employee.token}`
            },
            responseType: 'blob'
          })

          const observer = {
            next: (response) => {
              const blob = response.response
              this.signatureLink = window.URL.createObjectURL(blob);
              // const link = document.createElement('a');
              // link.href = url;
              // link.setAttribute('download', filename || url.split('/').pop());
              // document.body.appendChild(link);
              // link.click();
              // link.remove();
            },
            error: () => {
            },
            complete: () => {
            }
          }

          observable.subscribe(observer)
        
      }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
