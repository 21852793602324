<template>
    <Edit75Liquidation4></Edit75Liquidation4>
</template>
<script>
    import Edit75Liquidation4 from '@/components/Edit75Liquidation4.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit75Liquidation4
        }
    }
</script>