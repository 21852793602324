<template>
    <AdminTrackLiquidation></AdminTrackLiquidation>
</template>
<script>
    import AdminTrackLiquidation from '@/components/AdminTrackLiquidation.vue'
    export default {
        name: 'employee-track-liquidation',
        components: {
            AdminTrackLiquidation
        }
    }
</script>