<template>
    <EmployeeTrackRequest></EmployeeTrackRequest>
</template>
<script>
    import EmployeeTrackRequest from '@/components/EmployeeTrackRequest.vue'
    export default {
        name: 'employee-track-request',
        components: {
            EmployeeTrackRequest
        }
    }
</script>