<template>
    <EmployeeLiquidations></EmployeeLiquidations>
</template>
<script>
    import EmployeeLiquidations from '@/components/EmployeeLiquidations.vue'
    export default {
        name: 'employee-requests',
        components: {
            EmployeeLiquidations
        }
    }
</script>