<template>
    <EditPaymentLiquidation2></EditPaymentLiquidation2>
</template>
<script>
    import EditPaymentLiquidation2 from '@/components/EditPaymentLiquidation2.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditPaymentLiquidation2
        }
    }
</script>