<template>
    <EmployeeManagement></EmployeeManagement>
</template>
<script>
    import EmployeeManagement from '@/components/EmployeeManagement.vue'
    export default {
        name: 'funding-management',
        components: {
            EmployeeManagement
        }
    }
</script>