<template>
    <EmployeeUploadSignature></EmployeeUploadSignature>
</template>
<script>
    import EmployeeUploadSignature from '@/components/EmployeeUploadSignature.vue'
    export default {
        name: 'employee-upload-signature',
        components: {
            EmployeeUploadSignature
        }
    }
</script>