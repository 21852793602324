<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin' : 'main px-5 space-y-2']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m16,1.5v5.5h-3s0-5.5,0-5.5c0-.828.672-1.5,1.5-1.5.829,0,1.5.671,1.5,1.5Zm7.157,6.564c-.808-.582-1.943-.411-2.647.294l-.51.51V3c0-.829-.671-1.5-1.5-1.5s-1.5.671-1.5,1.5v13.684c.874-.331,1.679-.838,2.352-1.512l4.062-4.062c.859-.859.773-2.305-.257-3.047Zm-8.157,2.436c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v.5s0,0,0,0v7.5c0,1.379-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5v-9c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v6.247l-.51-.51c-.704-.704-1.839-.876-2.647-.294-1.03.742-1.116,2.188-.257,3.047l4.062,4.062c1.247,1.248,3.088,1.948,4.852,1.948,3.033,0,5.5-2.468,5.5-5.5v-7.5h0v-.5Zm-4-2c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v5.5h3s0-5.5,0-5.5Zm-1.5-3.5c.935,0,1.814.364,2.475,1.025.009.009.016.021.025.03v-2.435c0-.829-.671-1.5-1.5-1.5-.828,0-1.5.672-1.5,1.5v1.43c.165-.024.329-.051.5-.051Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Sign Your Signature</p>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-10 pl-1 chart-height"
              >
                <div class="px-4 py-2 space-y-2.5">
                  <div>
                    <span class="mx-4 text-size-md font-medium text-gray-500">Sign Your Signature Below</span>
                   </div>
                  <div class="mx-4 mb-5 mt-0.5 border shadow px-4 py-6 grid grid-flow-row grid-cols-1 ">
                    <!-- the signature -->
                    <div class=" border-gray-600 overflow-hidden bg-red">
                        <!-- show the signature -->
                        <div class="flex items-center" v-if=" currentView== 0">
                          <img class="h-64 w-full" :src="signatureLink"/>
                        </div>
                        <!-- show the signature pad -->
                        <div class="flex items-center" v-else>
                          <vue3Signature
                            ref="signature"
                            :sigOption="option"
                            :w="'100%'"
                            :h="'256px'"
                            :disabled="disabled"
                            class="touch-none mx-auto"
                          />
                        </div>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                    <div class="flex-1 mb-2">
                      <button
                        @click="clearSignature()"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Clear Signature
                      </button>
                      <button
                        @click="submit()"
                        class="shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Submit Signature
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import vue3Signature from "vue3-signature";
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';

export default {
  computed: {
    ...mapState([
      'employee'
    ])
  },
  components: {
    Navbar,
    Sidebar,
    vue3Signature,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      title: 'Process Error',
      processMessage: '',
      employeeData: null,
      disabled: false,
      signatureLink: null,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      signature: null
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 100)
      this.$store.commit('mutateSelectedSubMenuItem', 101)
    }
  },
  methods: {
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    // removes the signature
    clearSignature () {
      this.$refs.signature.clear()
    },
    // submits the signature
    submit () {
      const isEmpty = this.$refs.signature.isEmpty()
      if (!isEmpty) {
          // open the loading modal
          this.$refs.loaderRef.openModal()
          
          var signature = this.$refs.signature.save()

          const observable = ajax({
            url: process.env.VUE_APP_BASE_URL + '/signature/insert',
            method: 'POST',
            body: {
              employee_id: this.employee.id,
              filename: signature
            },
            headers: {
              Authorization: `Bearer ${this.employee.token}`
            }
          })

          const observer = {
            next: () => {
              // move to the profile page
              this.$router.push('/employee-profile')
            },
            error: () => {
              // close the loader modal
              this.$refs.loaderRef.closeModal()
              // showing the dialog for the error
              this.processMessage = 'Failed to submit the signature, please try again.'
              // open the error modal
              this.$refs.messageRef.openModal()
            },
            complete: () => {
              this.$refs.loaderRef.closeModal()
            }
          }

          observable.subscribe(observer)
      } else {
        this.processMessage = 'Failed to submit the signature, you cannot submit an empty signature.'
        // open the error modal
        this.$refs.messageRef.openModal()
      }
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
