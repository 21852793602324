<template>
    <EditLiquidation4></EditLiquidation4>
</template>
<script>
    import EditLiquidation4 from '@/components/EditLiquidation4.vue'
    export default {
        name: 'create-liquidation',
        components: {
            EditLiquidation4
        }
    }
</script>