<template>
    <ApprovePaymentLiquidations></ApprovePaymentLiquidations>
</template>
<script>
    import ApprovePaymentLiquidations from '@/components/ApprovePaymentLiquidations.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            ApprovePaymentLiquidations
        }
    }
</script>