<template>
    <EmployeeApprovePayment></EmployeeApprovePayment>
</template>
<script>
    import EmployeeApprovePayment from '@/components/EmployeeApprovePayment.vue'
    export default {
        name: 'employee-view-request',
        components: {
            EmployeeApprovePayment
        }
    }
</script>