<template>
    <div class="row-span-full fixed h-screen transform bg-white shadow-2xl border-r opacity-100 z-10" style="width: 19rem;">
          <!-- the side nav -->
          <nav class="text-left mx-4 mt-4 flex flex-col space-y-4">
            <!-- Home list item -->
            <div v-bind="activeListItemIndex" :class="[activeListItemIndex==0 ? 'bg-emerald-100 px-4 py-3 rounded-sm transform ' : 'px-4 py-3  color: #42b983' ]"  @click="barClick(0)">
              <a href="">
                <div class="flex space-x-2 items-center">
                  <svg :class="[activeListItemIndex==0 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,15h-1c-.553,0-1-.447-1-1s.447-1,1-1h1c.553,0,1,.447,1,1s-.447,1-1,1Zm1,3c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm-4-12c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,0c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm0,4c0-.553-.447-1-1-1h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1Zm4,9V5c0-2.757-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5,0,.553,.447,1,1,1s1-.447,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3v14c0,1.654-1.346,3-3,3h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c2.757,0,5-2.243,5-5Zm-8,.5v-4.152c0-1.548-.699-2.982-1.919-3.938l-3-2.349c-1.814-1.418-4.348-1.419-6.162,0l-3,2.348c-1.22,.955-1.919,2.39-1.919,3.938v4.152c0,2.481,2.019,4.5,4.5,4.5h7c2.481,0,4.5-2.019,4.5-4.5Zm-6.151-8.863l3,2.348c.731,.573,1.151,1.435,1.151,2.363v4.152c0,1.379-1.121,2.5-2.5,2.5H4.5c-1.379,0-2.5-1.121-2.5-2.5v-4.152c0-.929,.42-1.79,1.151-2.363l3-2.347c.544-.426,1.196-.639,1.849-.639s1.305,.213,1.849,.638Zm.151,7.363v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z"/></svg>
                  <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==0 ? 'text-size-sm font-medium text-emerald-500' : 'font-medium text-size-sm  text-gray-600']">Home</span>
                </div>
              </a>
            </div>
            <!-- Employee Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==100 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color: #42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <svg :class="[activeListItemIndex==200 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==100 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Profile Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==100" class="w-3 h-3 text-emerald-500 fill-current mx-10" style="margin-left: 3.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-10" style="margin-left: 3.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 border absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-0.5 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Employee Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==100 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(100)">
                      <!-- <svg :class="[selectedSubMenuItem==2 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M4.825,17.433,2.437,19.248a11.947,11.947,0,0,1-1.543-2.7l2.775-1.139A9,9,0,0,0,4.825,17.433Zm0-10.872L2.439,4.747A12.025,12.025,0,0,0,.9,7.44L3.67,8.582A9,9,0,0,1,4.828,6.561ZM3,12a9.168,9.168,0,0,1,.075-1.17L.1,10.445A12.084,12.084,0,0,0,0,12a12.256,12.256,0,0,0,.1,1.545l2.976-.38A9.055,9.055,0,0,1,3,12Zm1.614,9.458a12.064,12.064,0,0,0,2.673,1.581l1.18-2.759a8.96,8.96,0,0,1-2.005-1.186ZM4.62,2.537,6.466,4.9A8.993,8.993,0,0,1,8.472,3.717L7.294.958A12.049,12.049,0,0,0,4.62,2.537ZM12,0a12.19,12.19,0,0,0-1.709.121l.424,2.97A9.178,9.178,0,0,1,12,3a9,9,0,0,1,0,18,9.178,9.178,0,0,1-1.285-.091l-.424,2.97A12.19,12.19,0,0,0,12,24,12,12,0,0,0,12,0ZM10.5,6.273V10.69a1.966,1.966,0,0,0,0,2.62v3.463h3V13.31a1.966,1.966,0,0,0,0-2.62V6.273Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==201 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==100 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m22,14.537v-1.537c0-2.206-1.794-4-4-4s-4,1.794-4,4v1.537c-1.195.693-2,1.986-2,3.463v2c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4v-2c0-1.477-.805-2.77-2-3.463Zm-6-1.537c0-1.103.897-2,2-2s2,.897,2,2v1h-4v-1Zm6,7c0,1.103-.897,2-2,2h-4c-1.103,0-2-.897-2-2v-2c0-1.103.897-2,2-2h4c1.103,0,2,.897,2,2v2Zm-2.5-1c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-11.5-7c3.309,0,6-2.691,6-6S11.309,0,8,0,2,2.691,2,6s2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm1.99,13.211c-.078.547-.583.929-1.131.85-.284-.041-.574-.061-.859-.061-3.309,0-6,2.691-6,6v1c0,.552-.448,1-1,1s-1-.448-1-1v-1c0-4.411,3.589-8,8-8,.379,0,.763.027,1.141.081.547.078.927.584.85,1.131Z"/>
                      </svg> -->
                      <svg  :class="[selectedSubMenuItem==100 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Employee Profile</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==101 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(101)">
                      <svg :class="[selectedSubMenuItem==101 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current text-gray-600']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m16,1.5v5.5h-3s0-5.5,0-5.5c0-.828.672-1.5,1.5-1.5.829,0,1.5.671,1.5,1.5Zm7.157,6.564c-.808-.582-1.943-.411-2.647.294l-.51.51V3c0-.829-.671-1.5-1.5-1.5s-1.5.671-1.5,1.5v13.684c.874-.331,1.679-.838,2.352-1.512l4.062-4.062c.859-.859.773-2.305-.257-3.047Zm-8.157,2.436c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v.5s0,0,0,0v7.5c0,1.379-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5v-9c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v6.247l-.51-.51c-.704-.704-1.839-.876-2.647-.294-1.03.742-1.116,2.188-.257,3.047l4.062,4.062c1.247,1.248,3.088,1.948,4.852,1.948,3.033,0,5.5-2.468,5.5-5.5v-7.5h0v-.5Zm-4-2c0-.828-.672-1.5-1.5-1.5-.829,0-1.5.671-1.5,1.5v5.5h3s0-5.5,0-5.5Zm-1.5-3.5c.935,0,1.814.364,2.475,1.025.009.009.016.021.025.03v-2.435c0-.829-.671-1.5-1.5-1.5-.828,0-1.5.672-1.5,1.5v1.43c.165-.024.329-.051.5-.051Z"/>
                      </svg>
                      <span class="text-size-sm text-gray-700">Sign Signature</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==102 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(102)">
                      <svg :class="[selectedSubMenuItem==102 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg>
                      <span class="text-size-sm text-gray-700">Upload Signature</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==103 ? 'flex items-center px-5 bg-emerald-100 py-2 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 py-2 mx-2 space-x-2 cursor-pointer']" @click="barClick(103)">
                      <!-- <svg :class="[selectedSubMenuItem==102 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==103 ? 'h-4 w-4 fill-current text-emerald-500' : 'h-4 w-4 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,9H22.738a8.406,8.406,0,0,0-1.82.2,10.826,10.826,0,0,0-7.1-5.03,2.5,2.5,0,1,0-3.7.011,10.9,10.9,0,0,0-7.1,5.02A8.389,8.389,0,0,0,1.2,9H-.062L2.99,22H1.969v2h20V22H20.949ZM11.969,6A8.489,8.489,0,0,1,19,9.923a5.972,5.972,0,0,0-2.018,1.783,6,6,0,0,0-10.025,0A5.963,5.963,0,0,0,4.94,9.925,8.5,8.5,0,0,1,11.969,6ZM2.49,11.119A3.912,3.912,0,0,1,5.969,15h2a4,4,0,0,1,8,0h2a3.912,3.912,0,0,1,3.478-3.881L18.9,22H5.044Z"/></svg>
                      <span class="text-size-sm text-gray-700">Delegate Approval</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Liquidation Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==200 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m13,11h11v2h-11v11h-2v-11H0v-2h11V0h2v11Zm3,7h8v-2h-8v2Zm0,4h8v-2h-8v2ZM8,4H0v2h8v-2Zm13,0V1h-2v3h-3v2h3v3h2v-3h3v-2h-3ZM7.062,15.523l-2.562,2.562-2.562-2.562-1.414,1.414,2.562,2.562-2.562,2.562,1.414,1.414,2.562-2.562,2.562,2.562,1.414-1.414-2.562-2.562,2.562-2.562-1.414-1.414Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==300 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Liquidation Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==300" class="w-3 h-3 text-emerald-500 fill-current mx-2" style="margin-left: 2.2rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-2" style="margin-left: 2.2rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <span v-if="liquidationToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-2.5">{{ liquidationToApprove }}</span>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Liquidation Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==200 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(200)">
                      <svg :class="[selectedSubMenuItem==200 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,1.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm8.5,3.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm.5,7.5v8c0,1.995-2.58,3.5-6,3.5-2.151,0-3.968-.596-5.022-1.534-1.217,1.01-3.353,1.534-5.478,1.534-3.23,0-6.5-1.202-6.5-3.5V4.5C0,2.202,3.27,1,6.5,1s6.5,1.202,6.5,3.5v5.353c1.047-.534,2.433-.853,4-.853,3.42,0,6,1.505,6,3.5Zm-2,2.647c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5v-1.353ZM11,7.091c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409ZM2,11.091v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909Zm0,5.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409Zm11-4c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5-1.519-1.5-4-1.5-4,.971-4,1.5ZM2,4.5c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5-1.577-1.5-4.5-1.5-4.5,1.064-4.5,1.5Zm9,16v-1.409c-1.226.601-2.867.909-4.5.909s-3.274-.308-4.5-.909v1.409c0,.436,1.577,1.5,4.5,1.5s4.5-1.064,4.5-1.5Zm10,0v-1.353c-1.047.534-2.433.853-4,.853s-2.953-.319-4-.853v1.353c0,.529,1.519,1.5,4,1.5s4-.971,4-1.5Zm-4.555-12.668c.17.114.363.168.554.168.323,0,.64-.156.833-.445l4-6c.306-.459.182-1.081-.277-1.387-.46-.308-1.081-.183-1.387.277l-4,6c-.306.459-.182,1.081.277,1.387Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Create a Liquidation</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==201 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(201)">
                      <!-- <svg :class="[selectedSubMenuItem==301 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.95,5.54l-3.49-3.49c-1.32-1.32-3.08-2.05-4.95-2.05H7C4.24,0,2,2.24,2,5v14c0,2.76,2.24,5,5,5h10c2.76,0,5-2.24,5-5V10.49c0-1.87-.73-3.63-2.05-4.95Zm-1.41,1.41c.32,.32,.59,.67,.81,1.05h-4.34c-.55,0-1-.45-1-1V2.66c.38,.22,.73,.49,1.05,.81l3.49,3.49Zm1.46,12.05c0,1.65-1.35,3-3,3H7c-1.65,0-3-1.35-3-3V5c0-1.65,1.35-3,3-3h4.51c.16,0,.33,0,.49,.02V7c0,1.65,1.35,3,3,3h4.98c.02,.16,.02,.32,.02,.49v8.51ZM7.09,13h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.22h.84c1.18,0,2.14-.95,2.14-2.11s-.96-2.11-2.14-2.11Zm0,2.97h-.83s0-1.72,0-1.72h.84c.48,0,.89,.39,.89,.86s-.41,.86-.89,.86Zm11.93-2.34c0,.35-.28,.62-.62,.62h-1.69v1.14h1.24c.35,0,.62,.28,.62,.62s-.28,.62-.62,.62h-1.24v1.8c0,.35-.28,.62-.62,.62s-.62-.28-.62-.62v-4.81c0-.35,.28-.62,.62-.62h2.31c.35,0,.62,.28,.62,.62Zm-6.93-.62h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.56,.62,.56s1.46,0,1.46,0c1.18,0,2.14-.95,2.14-2.11v-1.78c0-1.16-.96-2.11-2.14-2.11Zm.89,3.89c0,.47-.41,.86-.89,.86h-.83s0-3.5,0-3.5h.84c.48,0,.89,.39,.89,.86v1.78Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==201 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21.122,18.229c.426.352.486.982.135,1.408-2.29,2.772-5.664,4.363-9.257,4.363C5.383,24,0,18.617,0,12S5.383,0,12,0c3.593,0,6.966,1.59,9.257,4.363.352.426.291,1.056-.135,1.408-.427.353-1.055.292-1.408-.134-1.908-2.311-4.72-3.637-7.714-3.637C6.486,2,2,6.486,2,12s4.486,10,10,10c2.994,0,5.806-1.325,7.714-3.637.353-.426.98-.485,1.408-.135Zm-7.622-10.229c1.381,0,2.5,1.119,2.5,2.5v3c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5v-3c0-1.381,1.119-2.5,2.5-2.5Zm0,1.6c-.496,0-.9.404-.9.9v3c0,.496.404.9.9.9s.9-.404.9-.9v-3c0-.496-.404-.9-.9-.9Zm9.25,3.881c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm-3.25-4.25c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Zm-1.156,6.75c.27,0,.522-.136.67-.363l4.178-6.4c.347-.532-.034-1.237-.67-1.237-.27,0-.522.136-.67.363l-4.178,6.4c-.347.532.034,1.237.67,1.237Zm-12.634-.081c.123.068.257.101.389.101.281,0,.553-.147.7-.411l3.046-5.474c.228-.457.204-.988-.065-1.423-.269-.434-.733-.692-1.243-.692h-2.737c-.442,0-.8.358-.8.8s.358.8.8.8h2.502l-2.901,5.212c-.215.386-.076.873.31,1.088Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==202 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(202)">
                      <!-- <svg :class="[selectedSubMenuItem==301 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.95,5.54l-3.49-3.49c-1.32-1.32-3.08-2.05-4.95-2.05H7C4.24,0,2,2.24,2,5v14c0,2.76,2.24,5,5,5h10c2.76,0,5-2.24,5-5V10.49c0-1.87-.73-3.63-2.05-4.95Zm-1.41,1.41c.32,.32,.59,.67,.81,1.05h-4.34c-.55,0-1-.45-1-1V2.66c.38,.22,.73,.49,1.05,.81l3.49,3.49Zm1.46,12.05c0,1.65-1.35,3-3,3H7c-1.65,0-3-1.35-3-3V5c0-1.65,1.35-3,3-3h4.51c.16,0,.33,0,.49,.02V7c0,1.65,1.35,3,3,3h4.98c.02,.16,.02,.32,.02,.49v8.51ZM7.09,13h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.62,.62,.62s.62-.28,.62-.62v-1.22h.84c1.18,0,2.14-.95,2.14-2.11s-.96-2.11-2.14-2.11Zm0,2.97h-.83s0-1.72,0-1.72h.84c.48,0,.89,.39,.89,.86s-.41,.86-.89,.86Zm11.93-2.34c0,.35-.28,.62-.62,.62h-1.69v1.14h1.24c.35,0,.62,.28,.62,.62s-.28,.62-.62,.62h-1.24v1.8c0,.35-.28,.62-.62,.62s-.62-.28-.62-.62v-4.81c0-.35,.28-.62,.62-.62h2.31c.35,0,.62,.28,.62,.62Zm-6.93-.62h-1.09c-.55,0-1,.45-1,1v4.44c0,.35,.28,.56,.62,.56s1.46,0,1.46,0c1.18,0,2.14-.95,2.14-2.11v-1.78c0-1.16-.96-2.11-2.14-2.11Zm.89,3.89c0,.47-.41,.86-.89,.86h-.83s0-3.5,0-3.5h.84c.48,0,.89,.39,.89,.86v1.78Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==202 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21.122,18.229c.426.352.486.982.135,1.408-2.29,2.772-5.664,4.363-9.257,4.363C5.383,24,0,18.617,0,12S5.383,0,12,0c3.593,0,6.966,1.59,9.257,4.363.352.426.291,1.056-.135,1.408-.427.353-1.055.292-1.408-.134-1.908-2.311-4.72-3.637-7.714-3.637C6.486,2,2,6.486,2,12s4.486,10,10,10c2.994,0,5.806-1.325,7.714-3.637.353-.426.98-.485,1.408-.135Zm-7.622-10.229c1.381,0,2.5,1.119,2.5,2.5v3c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5v-3c0-1.381,1.119-2.5,2.5-2.5Zm0,1.6c-.496,0-.9.404-.9.9v3c0,.496.404.9.9.9s.9-.404.9-.9v-3c0-.496-.404-.9-.9-.9Zm9.25,3.881c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm-3.25-4.25c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Zm-1.156,6.75c.27,0,.522-.136.67-.363l4.178-6.4c.347-.532-.034-1.237-.67-1.237-.27,0-.522.136-.67.363l-4.178,6.4c-.347.532.034,1.237.67,1.237Zm-12.634-.081c.123.068.257.101.389.101.281,0,.553-.147.7-.411l3.046-5.474c.228-.457.204-.988-.065-1.423-.269-.434-.733-.692-1.243-.692h-2.737c-.442,0-.8.358-.8.8s.358.8.8.8h2.502l-2.901,5.212c-.215.386-.076.873.31,1.088Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==202 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21.122,18.229c.426.352.486.982.134,1.408-2.29,2.772-5.664,4.363-9.256,4.363C5.383,24,0,18.617,0,12S5.383,0,12,0c3.593,0,6.967,1.591,9.256,4.363.352.426.292,1.057-.134,1.408s-1.056.291-1.408-.135c-1.909-2.312-4.72-3.637-7.714-3.637C6.486,2,2,6.486,2,12s4.486,10,10,10c2.994,0,5.806-1.325,7.714-3.637.352-.425.982-.484,1.408-.135Zm-7.622-10.229c1.381,0,2.5,1.119,2.5,2.5v3c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5v-3c0-1.381,1.119-2.5,2.5-2.5Zm0,1.6c-.496,0-.9.404-.9.9v3c0,.496.404.9.9.9s.9-.404.9-.9v-3c0-.496-.404-.9-.9-.9Zm9.25,3.881c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm-3.25-4.25c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Zm-1.156,6.75c.27,0,.522-.136.67-.363l4.178-6.4c.347-.532-.034-1.237-.67-1.237-.27,0-.522.136-.67.363l-4.178,6.4c-.347.532.034,1.237.67,1.237Zm-9.944-2.781v-.375c-.28.109-.582.174-.9.174-1.378,0-2.5-1.121-2.5-2.5s1.122-2.5,2.5-2.5,2.5,1.121,2.5,2.5v2.7c0,1.544-1.256,2.8-2.8,2.8h-.9c-.442,0-.8-.358-.8-.8s.358-.8.8-.8h.9c.662,0,1.2-.538,1.2-1.2Zm0-2.7c0-.496-.404-.9-.9-.9s-.9.404-.9.9.404.9.9.9.9-.404.9-.9Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Liquidation Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==203 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(203)">
                      <!-- <svg :class="[selectedSubMenuItem==9 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==203 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <circle cx="9" cy="10.77" r="1"/>
                        <circle cx="15" cy="15.231" r="1"/>
                        <g>
                          <path d="M12.477,1.121c-.305-.166-.676-.161-.975,.012-.386,.221-9.436,5.5-9.546,12.014,.152,4.996,5.027,9.784,10.058,9.853,5.003-.069,10.03-5.027,10.03-9.901C22.044,6.5,12.862,1.33,12.477,1.121Zm-.463,19.879c-3.946-.054-7.938-3.974-8.058-7.866,.078-4.58,6.257-8.828,8.057-9.969,1.798,1.088,7.924,5.144,8.031,9.945-.136,3.932-4.119,7.835-8.03,7.89Z"/>
                          <path d="M14.491,8.587c-.482-.272-1.09-.102-1.362,.379l-4,7.085c-.271,.481-.104,1.095,.38,1.362,.291,.161,.888,.301,1.362-.379l4-7.085c.271-.481,.102-1.091-.38-1.362Z"/>
                        </g>
                      </svg>
                      <span class="text-size-sm text-center pr-4">Approve Liquidations</span>
                      <span v-if="liquidationToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-4">{{ liquidationToApprove }}</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Payment Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==300 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" :class="[activeListItemIndex==1 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m19,0H5c-1.654,0-3,1.346-3,3v21h20V3c0-1.654-1.346-3-3-3ZM5,2h14c.551,0,1,.448,1,1v2H4v-2c0-.552.449-1,1-1Zm-1,20V7h16v15H4Zm3-12h10v2H7v-2Zm0,4h6v2h-6v-2Z"/></svg> -->
                    <!-- <svg :class="[activeListItemIndex==5 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,0H3A3,3,0,0,0,0,3V24H24V3A3,3,0,0,0,21,0ZM7,22V17a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v5Zm15,0H19V17a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v5H2V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></g></svg> -->
                    <svg :class="[activeListItemIndex==6 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                      <path d="m1.886,8.437c-1.328.484-2.085,1.795-1.841,3.189.245,1.397,1.407,2.374,2.826,2.374h7.129v7.128c0,1.419.976,2.582,2.374,2.827.175.03.349.046.521.046,1.192,0,2.249-.727,2.666-1.878L24.028-.014,1.886,8.437Zm11.801,12.988c-.229.635-.797.59-.967.561-.169-.03-.72-.18-.72-.857v-9.128H2.871c-.678,0-.826-.551-.856-.72-.029-.168-.076-.736.561-.967L20.562,3.449l-6.875,17.976Z"/>
                    </svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==300 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Payments Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==300" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 2.6rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <span v-if="paymentToApprove !== 0 || paymentLiquidationToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5">{{ paymentToApprove + paymentLiquidationToApprove }}</span>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-64 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Payment Management</span>
                      </a>
                    </div>
                    <div v-if=" position.toLocaleLowerCase().includes('finance') || position.toLocaleLowerCase().includes('director, operations') || financeDelegate" v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==300 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(300)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="M24,4c0-2.206-1.794-4-4-4H4C1.794,0,0,1.794,0,4c0,1.858,1.279,3.411,3,3.858V24H21V7.858c1.721-.447,3-2,3-3.858ZM2,4c0-.737,.405-1.375,1-1.722v3.443c-.595-.347-1-.985-1-1.722Zm3-2h3V22h-3V2Zm5,20V2h9V22H10ZM21,5.722V2.278c.595,.347,1,.985,1,1.722s-.405,1.375-1,1.722Zm-4,12.778c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Create a Payment</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==301 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(301)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==401 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==302 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
                      <span class="text-size-sm text-center">My Payments</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==302 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(302)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==401 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==302 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==302 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m18.5,0h-3c-3.033,0-5.5,2.467-5.5,5.5v13c0,3.033,2.467,5.5,5.5,5.5h3c3.033,0,5.5-2.467,5.5-5.5V5.5c0-3.033-2.467-5.5-5.5-5.5Zm2.5,18.5c0,1.378-1.122,2.5-2.5,2.5h-3c-1.378,0-2.5-1.122-2.5-2.5V5.5c0-1.378,1.122-2.5,2.5-2.5h3c1.378,0,2.5,1.122,2.5,2.5v13ZM8,4.5v15c0,.829-.671,1.5-1.5,1.5s-1.5-.671-1.5-1.5V4.5c0-.829.671-1.5,1.5-1.5s1.5.671,1.5,1.5Zm-5,3v9c0,.829-.671,1.5-1.5,1.5s-1.5-.671-1.5-1.5V7.5c0-.829.671-1.5,1.5-1.5s1.5.671,1.5,1.5Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Payments Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==303 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(303)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==303 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg>
                      <span class="text-size-sm text-center pr-6">Approve Payments</span>
                      <span v-if="paymentToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-4">{{ paymentToApprove }}</span>
                    </div>
                    <div v-if=" position.toLocaleLowerCase().includes('finance') || financeDelegate" v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==304 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(304)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==304 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==304 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m6,8h-2V0h16v2H6v6Zm12,13c0,.551-.448,1-1,1H7c-.552,0-1-.449-1-1v-3h-2v3c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3v-9h-2v9Zm5.451-15.326l-2.659-2.659-1.414,1.414,1.57,1.571h-3.948v2h3.948l-1.57,1.571,1.414,1.414,2.66-2.66c.729-.73.729-1.919,0-2.65ZM3.052,14h3.948v-2h-3.948l1.57-1.571-1.414-1.414-2.66,2.66c-.729.73-.729,1.919,0,2.65l2.659,2.659,1.414-1.414-1.57-1.571Zm6.948,6h4v-2h-4v2Zm5.3-7.374c0-1.033-.734-1.929-1.747-2.131l-2.792-.559c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.127c.316,0,.585.211.671.5h1.62c-.102-1.175-1.09-2.1-2.291-2.1h-.2v-1.2h-1.6v1.2h-.327c-1.198,0-2.173.975-2.173,2.173,0,1.033.734,1.929,1.747,2.131l2.791.559c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.126c-.316,0-.585-.211-.671-.5h-1.62c.102,1.175,1.09,2.1,2.291,2.1h.2v1.2h1.6v-1.2h.326c1.199,0,2.174-.975,2.174-2.173Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Create a Payment Liquidation</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==305 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(305)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==305 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==305 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m15.988,13.233l3.889-.845c.438.377,1,.612,1.622.612,1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5c-1.007,0-1.87.599-2.266,1.458l-3.883.844c-.209-.414-.462-.801-.757-1.153l2.968-4.155c1.351-.034,2.438-1.134,2.438-2.494,0-1.381-1.119-2.5-2.5-2.5s-2.5,1.119-2.5,2.5c0,.261.051.508.125.744l-2.973,4.162c-.669-.258-1.393-.406-2.153-.406s-1.483.148-2.153.406l-2.973-4.162c.074-.236.125-.483.125-.744,0-1.381-1.119-2.5-2.5-2.5S0,1.119,0,2.5c0,1.359,1.087,2.46,2.438,2.494l2.968,4.155c-.876,1.043-1.405,2.386-1.405,3.852,0,1.245.381,2.402,1.033,3.362l-2.345,2.657c-.063-.005-.124-.019-.188-.019-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5,2.5-1.119,2.5-2.5c0-.174-.018-.343-.052-.507l2.338-2.649c.816.416,1.737.656,2.714.656,1.216,0,2.347-.366,3.293-.99l3.719,3.372c-.002.04-.012.078-.012.118,0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5-1.119-2.5-2.5-2.5c-.197,0-.386.028-.569.071l-3.622-3.284c.405-.769.644-1.635.68-2.554Zm-8.988-.233c0-1.654,1.346-3,3-3s3,1.346,3,3-1.346,3-3,3-3-1.346-3-3Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My Payment Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==306 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(306)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==306 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg> -->
                      <svg  :class="[selectedSubMenuItem==306 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m7,10c-3.859,0-7,3.14-7,7s3.141,7,7,7,7-3.14,7-7-3.141-7-7-7Zm0,12c-2.757,0-5-2.243-5-5s2.243-5,5-5,5,2.243,5,5-2.243,5-5,5Zm2.742-6.499c.168.168.257.393.257.621,0,.113-.021.227-.067.336-.136.328-.456.541-.812.541h-1.121v2c0,.552-.447,1-1,1s-1-.448-1-1v-2h-1.121c-.355,0-.676-.213-.812-.541-.138-.329-.062-.706.19-.958l1.5-1.5c.685-.685,1.8-.685,2.485,0l1.5,1.5ZM16.5,0c-4.275,0-7.5,1.72-7.5,4v3.5c0,.552.447,1,1,1s1-.448,1-1v-.726c1.349.763,3.283,1.226,5.5,1.226s4.151-.463,5.5-1.226v1.226c0,.796-2.193,2-5.5,2-.634,0-1.257-.045-1.853-.134-.545-.077-1.055.296-1.137.842-.081.546.296,1.055.842,1.137.693.103,1.416.155,2.147.155,2.217,0,4.151-.463,5.5-1.226v1.226c0,.796-2.193,2-5.5,2-.553,0-1,.448-1,1s.447,1,1,1c2.217,0,4.151-.463,5.5-1.226v1.226c0,.796-2.193,2-5.5,2-.553,0-1,.448-1,1s.447,1,1,1c2.217,0,4.151-.463,5.5-1.226v1.226c0,.796-2.193,2-5.5,2-.47,0-.938-.025-1.393-.074-.541-.062-1.042.337-1.102.886s.337,1.042.887,1.102c.524.057,1.065.086,1.607.086,4.275,0,7.5-1.72,7.5-4V4c0-2.28-3.225-4-7.5-4Zm0,6c-3.307,0-5.5-1.204-5.5-2s2.193-2,5.5-2,5.5,1.204,5.5,2-2.193,2-5.5,2Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Liquidation Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==307 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(307)">
                      <!-- -->
                      <!-- <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==307 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m17,5v2c0,.553-.447,1-1,1H7c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h9c.553,0,1,.447,1,1Zm-9,5h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-8,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4-4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm-4,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm8,0h-1c-.553,0-1,.447-1,1s.447,1,1,1h1c.553,0,1-.447,1-1s-.447-1-1-1Zm-4,0h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm10-13v14c0,.553-.447,1-1,1h-.101c-.465,2.279-2.484,4-4.899,4H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h9c2.414,0,4.434,1.721,4.899,4h.101c.553,0,1,.447,1,1Zm-3,14V5c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h9c1.654,0,3-1.346,3-3Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==307 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M20,3H8c-2.21,0-4,1.79-4,4v6c0,2.21,1.79,4,4,4h12c2.21,0,4-1.79,4-4V7c0-2.21-1.79-4-4-4Zm2,10c0,1.1-.9,2-2,2H8c-1.1,0-2-.9-2-2V7c0-1.1,.9-2,2-2h12c1.1,0,2,.9,2,2v6Zm-3,7c0,.55-.45,1-1,1H5c-2.76,0-5-2.24-5-5v-7c0-.55,.45-1,1-1s1,.45,1,1v7c0,1.65,1.35,3,3,3h13c.55,0,1,.45,1,1ZM14,7c-1.65,0-3,1.35-3,3s1.35,3,3,3,3-1.35,3-3-1.35-3-3-3Zm0,4c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Z"/></svg>
                      <span class="text-size-sm text-center">Approve Payment Liquidation</span>
                      <span v-if="paymentLiquidationToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5">{{ paymentLiquidationToApprove }}</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Request Management -->
            <Popover as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==400 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <svg :class="[activeListItemIndex==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg>
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==400 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Request Management</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==400" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 3.1rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 3.1rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <span v-if="requestToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5">{{ requestToApprove }}</span>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-60 top-32 z-10 -left-4 absolute rounded-sm transform translate-x-4 -translate-y-24
                                  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Request Management</span>
                      </a>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==400 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(400)">
                      <!-- <svg :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Create a Request</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==401 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(401)">
                      <!-- <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==401 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1,13c-.553,0-1-.448-1-1C0,5.383,5.383,0,12,0c3.449,0,6.733,1.518,9,4.087V1c0-.552.447-1,1-1s1,.448,1,1v4c0,1.103-.897,2-2,2h-4c-.553,0-1-.448-1-1s.447-1,1-1h2.122c-1.867-1.893-4.434-3-7.122-3C6.486,2,2,6.486,2,12c0,.552-.447,1-1,1Zm22-2c-.553,0-1,.448-1,1,0,5.514-4.486,10-10,10-2.688,0-5.255-1.107-7.123-3h2.123c.553,0,1-.448,1-1s-.447-1-1-1H3c-1.103,0-2,.897-2,2v4c0,.552.447,1,1,1s1-.448,1-1v-3.087c2.267,2.569,5.551,4.087,9,4.087,6.617,0,12-5.383,12-12,0-.552-.447-1-1-1Zm-12.142,8.919c.374.054.759.081,1.142.081,4.411,0,8-3.589,8-8,0-.78-.111-1.55-.332-2.287-.159-.529-.715-.832-1.244-.671-.529.158-.83.715-.672,1.245.165.551.248,1.127.248,1.713,0,3.309-2.691,6-6,6-.288,0-.577-.021-.858-.061-.542-.078-1.052.301-1.132.848-.078.546.302,1.053.849,1.131Zm1.142-13.919c.286,0,.575.021.859.061.55.079,1.053-.303,1.131-.85.077-.546-.303-1.053-.85-1.131-.377-.053-.761-.081-1.141-.081-4.411,0-8,3.589-8,8,0,.777.111,1.546.331,2.286.13.434.527.715.959.715.094,0,.19-.014.285-.042.529-.157.831-.714.674-1.244-.165-.555-.249-1.132-.249-1.715,0-3.309,2.691-6,6-6Zm1.126,8.2h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My Requests</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==402 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(402)">
                      <!-- <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==402 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m23.55,19.071l-4.293,4.207c-.48.48-1.116.722-1.753.722-.641,0-1.283-.244-1.771-.732l-4.25-4.25,2.121-2.121,2.396,2.396V0h3v19.33l2.45-2.401,2.1,2.143Zm-10.246-7.445c0-1.033-.734-1.929-1.747-2.131l-2.792-.559c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.127c.316,0,.585.211.671.5h1.62c-.102-1.175-1.09-2.1-2.291-2.1h-.2v-1.2h-1.6v1.2h-.327c-1.198,0-2.173.975-2.173,2.173,0,1.033.734,1.929,1.747,2.131l2.791.559c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.126c-.316,0-.585-.211-.671-.5h-1.62c.102,1.175,1.09,2.1,2.291,2.1h.2v1.2h1.6v-1.2h.326c1.199,0,2.174-.975,2.174-2.173Zm-10.304-1.626c0-3.86,3.14-7,7-7,1.487,0,2.865.47,4,1.264V.841c-1.226-.538-2.578-.841-4-.841C4.486,0,0,4.486,0,10s4.486,10,10,10v-3c-3.86,0-7-3.14-7-7Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Request Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==403 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(403)">
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><polygon points="18.707 6.707 17.293 5.293 12 10.586 6.707 5.293 5.293 6.707 10.586 12 5.293 17.293 6.707 18.707 12 13.414 17.293 18.707 18.707 17.293 13.414 12 18.707 6.707"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==403 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,22.5c0,.828-.672,1.5-1.5,1.5h-3.819c-2.838,0-5.245-2.215-5.481-5.043l-1.08-12.957h-.619c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h4.757c.619-1.746,2.287-3,4.243-3h3c1.956,0,3.624,1.254,4.243,3h4.757c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5H5.131l1.059,12.707c.106,1.286,1.201,2.293,2.491,2.293h3.819c.828,0,1.5.672,1.5,1.5Zm8.5-14.5h-6c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h6c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-4c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h4c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-2c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h2c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==403 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24c-1.506,0-2.928-.583-4.004-1.64l-3.734-3.466C-.065,14.548-.065,7.543,4.219,3.238,6.297,1.15,9.061,0,12,0s5.702,1.15,7.781,3.238h0c4.284,4.305,4.284,11.31,0,15.615l-3.809,3.537c-1.045,1.028-2.467,1.61-3.973,1.61Zm0-21c-2.136,0-4.144,.836-5.654,2.354-3.124,3.138-3.124,8.245,0,11.383l3.723,3.455c1.084,1.063,2.811,1.034,3.832,.029l3.797-3.525c3.081-3.097,3.081-8.204-.043-11.342-1.511-1.518-3.52-2.354-5.654-2.354Zm-1.496,12c-.641,0-1.283-.244-1.771-.732l-2.272-2.187c-.597-.574-.615-1.524-.041-2.121,.575-.597,1.524-.616,2.121-.041l1.957,1.883,4.452-4.373c.592-.58,1.541-.571,2.121,.02,.58,.591,.571,1.541-.02,2.121l-4.793,4.707c-.481,.481-1.117,.723-1.754,.723Z"/></svg>
                      <span class="text-size-sm text-center pr-10">Approve Requests</span>
                      <span v-if="requestToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-4">{{ requestToApprove }}</span>
                    </div>
                  </div>
              </PopoverPanel>
            </Popover>
            <!-- Finance Liquidation Management -->
            <Popover v-if=" position.toLocaleLowerCase().includes('finance') || position.toLocaleLowerCase().includes('director, operations') || financeDelegate" as="div" v-bind="activeListItemIndex" :class="[activeListItemIndex==500 ? 'bg-emerald-100 px-4 py-3 rounded-sm relative flex items-center' : 'px-4 py-3 flex items-center color:#42b983 relative' ]" >
              <PopoverButton class=" focus:outline-none items-center flex">
                <a href="#">
                  <div class="flex space-x-2 items-center">
                    <!-- <svg :class="[activeListItemIndex==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.89,9.65c-.36-.42-.32-1.05,.1-1.41,.42-.36,1.05-.32,1.41,.1,1.07,1.24,1.85,2.71,2.32,4.38,.15,.53-.16,1.08-.69,1.23-.09,.03-.18,.04-.27,.04-.44,0-.84-.29-.96-.73-.39-1.39-1.04-2.6-1.91-3.62Zm4.06,7.66c-1.36,4.08-4.31,4.68-5.95,4.68-1.41,0-2.64-.62-3.66-1.55-1.18,.93-2.63,1.55-4.34,1.55s-3.22-.95-4.63-2.4c-1.25,1.2-3.33,2.4-4.37,2.4-.46,0-.88-.33-.98-.8-.11-.54,.24-1.07,.78-1.18,1.15-.24,2.26-.96,3.27-1.96C1.64,14.78,0,10.24,0,7.5,0,4.57,2.54,2,5.43,2s5.57,2.62,5.57,5.5c0,2.69-1.65,7.29-4.29,10.61,1.08,1.15,2.22,1.89,3.29,1.89,1.17,0,2.19-.42,3.02-1.06-1.31-1.86-2.02-4.12-2.02-5.48,0-1.84,1.64-3.45,3.51-3.45s3.49,1.61,3.49,3.45c0,1.51-.77,3.77-2.23,5.58,.67,.59,1.42,.97,2.23,.97,1.95,0,3.32-1.12,4.05-3.32,.17-.52,.74-.81,1.27-.63,.52,.17,.81,.74,.63,1.27ZM9,7.5c0-1.8-1.74-3.5-3.57-3.5s-3.43,1.67-3.43,3.5c0,2.39,1.47,6.2,3.41,8.99,2.14-2.88,3.59-6.79,3.59-8.99Zm5.45,9.96c1.02-1.4,1.55-3.05,1.55-4,0-.73-.74-1.45-1.49-1.45s-1.51,.73-1.51,1.45c0,.95,.55,2.6,1.45,4Z"/></svg> -->
                    <svg :class="[activeListItemIndex==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m8.76 22h-2.46l-3.761-10.342a1 1 0 0 0 -.939-.658h-1.6v-2h1.6a3.007 3.007 0 0 1 2.819 1.975l3.021 8.307 4.19-15.082a3.009 3.009 0 0 1 2.891-2.2h9.479v2h-9.479a1 1 0 0 0 -.964.732zm11.933-5 3.307-5h-2.385l-2.115 3.2-2.115-3.2h-2.385l3.307 5-3.307 5h2.385l2.115-3.2 2.115 3.2h2.385z"/></svg>
                    
                    <span v-bind="activeListItemIndex" :class="[ activeListItemIndex==500 ? 'font-medium text-size-sm text-gray-500' : 'font-medium text-size-sm  text-gray-600']">Financial Liquidation</span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,19c-1.183,0-2.295-.461-3.131-1.297l-3.922-3.873c-.59-.582-.596-1.532-.014-2.121,.583-.59,1.532-.595,2.121-.014l3.929,3.88c.554,.554,1.488,.546,2.026,.007l3.938-3.887c.588-.582,1.54-.577,2.121,.014,.582,.589,.576,1.539-.014,2.121l-3.93,3.88c-.829,.83-1.94,1.291-3.124,1.291Zm1.062-6.439l5.999-6c.586-.586,.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-4.938,4.939L7.061,4.439c-.586-.586-1.536-.586-2.121,0-.586,.586-.586,1.536,0,2.121l6.001,6c.281,.281,.663,.439,1.061,.439s.779-.158,1.061-.439Z"/></svg> -->
                  </div>
                </a>
              </PopoverButton>
              <svg v-if="activeListItemIndex==500" class="w-3 h-3 text-emerald-500 fill-current mx-5" style="margin-left: 3.5rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <svg v-else  class="w-3 h-3 text-gray-500 fill-current mx-5" style="margin-left: 3.5rem;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,21c-4.962,0-9-4.037-9-9S7.038,3,12,3s9,4.038,9,9-4.038,9-9,9Zm4-9c0,2.209-1.791,4-4,4s-4-1.791-4-4,1.791-4,4-4,4,1.791,4,4Z"/></svg>
              <span v-if="liquidation75ToApprove !== 0 || liquidation25ToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5">{{ (liquidation75ToApprove + liquidation25ToApprove) }}</span>
              <!--The items Menu panel-->
              <PopoverPanel class=" mt-0 w-72 top-32 z-10 -left-6 absolute rounded-sm transform translate-x-4 -translate-y-64
                                 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide divide-gray-100">
                  <div class="py-2 space-y-4 my-2">
                    <div class="flex items-center px-6">
                      <a href="#">
                        <span class="text-size-sm font-medium font text-gray-500">Financial Liquidation</span>
                      </a>
                    </div>

                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==500 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(500)">
                      <!-- <svg :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
                      </svg> -->
                      <svg  :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,12c0,2.206,1.794,4,4,4s4-1.794,4-4-1.794-4-4-4-4,1.794-4,4Zm6,0c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm-8.5-4c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5.672-1.5,1.5-1.5Zm13,5c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5.672-1.5,1.5-1.5Zm-16.5.395l-2,2V7c0-1.654,1.346-3,3-3h17.948l-1.571-1.571,1.414-1.414,2.659,2.659c.731.731.731,1.92,0,2.651l-2.659,2.659-1.414-1.414,1.571-1.571H3c-.551,0-1,.449-1,1v6.395Zm22-4.791v11.395H3.052l1.571,1.571-1.414,1.414-2.659-2.659c-.731-.731-.731-1.92,0-2.651l2.659-2.659,1.414,1.414-1.571,1.571h18.948v-7.395l2-2Z"/>
                      </svg>
                      <span class="text-size-sm text-center">Create a 75% Liquidation</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==501 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(501)">
                      <!-- <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1,13c-.553,0-1-.448-1-1C0,5.383,5.383,0,12,0c3.449,0,6.733,1.518,9,4.087V1c0-.552.447-1,1-1s1,.448,1,1v4c0,1.103-.897,2-2,2h-4c-.553,0-1-.448-1-1s.447-1,1-1h2.122c-1.867-1.893-4.434-3-7.122-3C6.486,2,2,6.486,2,12c0,.552-.447,1-1,1Zm22-2c-.553,0-1,.448-1,1,0,5.514-4.486,10-10,10-2.688,0-5.255-1.107-7.123-3h2.123c.553,0,1-.448,1-1s-.447-1-1-1H3c-1.103,0-2,.897-2,2v4c0,.552.447,1,1,1s1-.448,1-1v-3.087c2.267,2.569,5.551,4.087,9,4.087,6.617,0,12-5.383,12-12,0-.552-.447-1-1-1Zm-12.142,8.919c.374.054.759.081,1.142.081,4.411,0,8-3.589,8-8,0-.78-.111-1.55-.332-2.287-.159-.529-.715-.832-1.244-.671-.529.158-.83.715-.672,1.245.165.551.248,1.127.248,1.713,0,3.309-2.691,6-6,6-.288,0-.577-.021-.858-.061-.542-.078-1.052.301-1.132.848-.078.546.302,1.053.849,1.131Zm1.142-13.919c.286,0,.575.021.859.061.55.079,1.053-.303,1.131-.85.077-.546-.303-1.053-.85-1.131-.377-.053-.761-.081-1.141-.081-4.411,0-8,3.589-8,8,0,.777.111,1.546.331,2.286.13.434.527.715.959.715.094,0,.19-.014.285-.042.529-.157.831-.714.674-1.244-.165-.555-.249-1.132-.249-1.715,0-3.309,2.691-6,6-6Zm1.126,8.2h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m4,7v-2C4,2.243,6.243,0,9,0h6c1.22,0,2.395.444,3.308,1.25.414.365.453.998.088,1.412-.367.415-.997.454-1.412.087-.547-.483-1.252-.75-1.983-.75h-6c-1.654,0-3,1.346-3,3v2c0,.552-.447,1-1,1s-1-.448-1-1Zm15,5c-.553,0-1,.448-1,1v6c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3,0-.552-.447-1-1-1s-1,.448-1,1c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-6c0-.552-.447-1-1-1Zm-3.738.21c-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803Zm8.21-6.514l-2.244-2.382c-.378-.402-1.011-.42-1.413-.042-.402.379-.421,1.012-.042,1.414l1.238,1.314h-3.011c-.553,0-1,.448-1,1s.447,1,1,1h3.011l-1.238,1.314c-.379.402-.36,1.035.042,1.414.193.182.439.272.686.272.266,0,.531-.105.728-.314l2.224-2.36c.354-.354.55-.825.55-1.326s-.195-.972-.529-1.304ZM4.228,15.314l-1.238-1.314h3.011c.553,0,1-.448,1-1s-.447-1-1-1h-3.01l1.238-1.314c.379-.402.36-1.035-.042-1.414-.401-.377-1.035-.359-1.413.042l-2.223,2.36c-.354.354-.55.825-.55,1.326s.195.972.529,1.304l2.243,2.381c.196.209.462.314.728.314.246,0,.492-.09.686-.272.402-.379.421-1.012.042-1.414Zm6.772,2.686c-.553,0-1,.448-1,1s.447,1,1,1h2c.553,0,1-.448,1-1s-.447-1-1-1h-2Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My 75% Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==502 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(502)">
                      <!-- <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m23.55,19.071l-4.293,4.207c-.48.48-1.116.722-1.753.722-.641,0-1.283-.244-1.771-.732l-4.25-4.25,2.121-2.121,2.396,2.396V0h3v19.33l2.45-2.401,2.1,2.143Zm-10.246-7.445c0-1.033-.734-1.929-1.747-2.131l-2.792-.559c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.127c.316,0,.585.211.671.5h1.62c-.102-1.175-1.09-2.1-2.291-2.1h-.2v-1.2h-1.6v1.2h-.327c-1.198,0-2.173.975-2.173,2.173,0,1.033.734,1.929,1.747,2.131l2.791.559c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.126c-.316,0-.585-.211-.671-.5h-1.62c.102,1.175,1.09,2.1,2.291,2.1h.2v1.2h1.6v-1.2h.326c1.199,0,2.174-.975,2.174-2.173Zm-10.304-1.626c0-3.86,3.14-7,7-7,1.487,0,2.865.47,4,1.264V.841c-1.226-.538-2.578-.841-4-.841C4.486,0,0,4.486,0,10s4.486,10,10,10v-3c-3.86,0-7-3.14-7-7Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M21,2H18V0H16V2H8V0H6V2H3A3,3,0,0,0,0,5V24H24V5A3,3,0,0,0,21,2ZM2,5A1,1,0,0,1,3,4H21a1,1,0,0,1,1,1V8H2ZM2,22V10H22V22Z"/><rect x="15" y="13" width="2" height="2"/><rect x="11" y="13" width="2" height="2"/><rect x="7" y="13" width="2" height="2"/><rect x="15" y="17" width="2" height="2"/><rect x="11" y="17" width="2" height="2"/><rect x="7" y="17" width="2" height="2"/></g></svg>
                      <span class="text-size-sm text-center">75% Liquidation Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==503 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(503)">
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><polygon points="18.707 6.707 17.293 5.293 12 10.586 6.707 5.293 5.293 6.707 10.586 12 5.293 17.293 6.707 18.707 12 13.414 17.293 18.707 18.707 17.293 13.414 12 18.707 6.707"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==403 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,22.5c0,.828-.672,1.5-1.5,1.5h-3.819c-2.838,0-5.245-2.215-5.481-5.043l-1.08-12.957h-.619c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h4.757c.619-1.746,2.287-3,4.243-3h3c1.956,0,3.624,1.254,4.243,3h4.757c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5H5.131l1.059,12.707c.106,1.286,1.201,2.293,2.491,2.293h3.819c.828,0,1.5.672,1.5,1.5Zm8.5-14.5h-6c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h6c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-4c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h4c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-2c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h2c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24c-1.506,0-2.928-.583-4.004-1.64l-3.734-3.466C-.065,14.548-.065,7.543,4.219,3.238,6.297,1.15,9.061,0,12,0s5.702,1.15,7.781,3.238h0c4.284,4.305,4.284,11.31,0,15.615l-3.809,3.537c-1.045,1.028-2.467,1.61-3.973,1.61Zm0-21c-2.136,0-4.144,.836-5.654,2.354-3.124,3.138-3.124,8.245,0,11.383l3.723,3.455c1.084,1.063,2.811,1.034,3.832,.029l3.797-3.525c3.081-3.097,3.081-8.204-.043-11.342-1.511-1.518-3.52-2.354-5.654-2.354Zm-1.496,12c-.641,0-1.283-.244-1.771-.732l-2.272-2.187c-.597-.574-.615-1.524-.041-2.121,.575-.597,1.524-.616,2.121-.041l1.957,1.883,4.452-4.373c.592-.58,1.541-.571,2.121,.02,.58,.591,.571,1.541-.02,2.121l-4.793,4.707c-.481,.481-1.117,.723-1.754,.723Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M15.021,7l.336-2.041a3.044,3.044,0,0,0-4.208-3.287A3.139,3.139,0,0,0,9.582,3.225L7.717,7H3a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H22.018L24,10.963,24.016,7ZM2,19V10A1,1,0,0,1,3,9H7V20H3A1,1,0,0,1,2,19Zm20-8.3L20.33,20H9V8.909l2.419-4.9A1.07,1.07,0,0,1,13.141,3.8a1.024,1.024,0,0,1,.233.84L12.655,9H22Z"/></g></svg>
                      <span class="text-size-sm text-center pr-10">Approve 75% Liquidations</span>
                      <span v-if="liquidation75ToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-4">{{ liquidation75ToApprove }}</span>
                    </div>


                     
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==504 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(504)">
                      <!-- <svg :class="[selectedSubMenuItem==500 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m8,0C4.691,0,2,2.691,2,6s2.691,6,6,6,6-2.691,6-6S11.309,0,8,0Zm0,9c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm-3,5h6v3h-6c-1.103,0-2,.897-2,2v5H0v-5c0-2.757,2.243-5,5-5Zm18,1v-1.5c0-2.481-2.019-4.5-4.5-4.5s-4.5,2.019-4.5,4.5v1.5h-1v6c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-6h-1Zm-6-1.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5h-3v-1.5Zm3,7.5h-3v-3h3v3Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==400 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==504 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19,0H5C2.24,0,0,2.24,0,5v14c0,2.76,2.24,5,5,5h14c2.76,0,5-2.24,5-5V5c0-2.76-2.24-5-5-5Zm3,19c0,1.65-1.35,3-3,3H5c-1.65,0-3-1.35-3-3V5c0-1.65,1.35-3,3-3h14c1.65,0,3,1.35,3,3v14ZM5,10c0-.55,.45-1,1-1H15c.55,0,1,.45,1,1s-.45,1-1,1H6c-.55,0-1-.45-1-1Zm0-4c0-.55,.45-1,1-1h6c.55,0,1,.45,1,1s-.45,1-1,1H6c-.55,0-1-.45-1-1Zm14,8c0,.55-.45,1-1,1H6c-.55,0-1-.45-1-1s.45-1,1-1h12c.55,0,1,.45,1,1Zm-9,4c0,.55-.45,1-1,1h-3c-.55,0-1-.45-1-1s.45-1,1-1h3c.55,0,1,.45,1,1Z"/></svg>
                      <span class="text-size-sm text-center">Create a 25% Liquidation</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==505 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(505)">
                      <!-- <svg :class="[selectedSubMenuItem==501 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==505 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m1,13c-.553,0-1-.448-1-1C0,5.383,5.383,0,12,0c3.449,0,6.733,1.518,9,4.087V1c0-.552.447-1,1-1s1,.448,1,1v4c0,1.103-.897,2-2,2h-4c-.553,0-1-.448-1-1s.447-1,1-1h2.122c-1.867-1.893-4.434-3-7.122-3C6.486,2,2,6.486,2,12c0,.552-.447,1-1,1Zm22-2c-.553,0-1,.448-1,1,0,5.514-4.486,10-10,10-2.688,0-5.255-1.107-7.123-3h2.123c.553,0,1-.448,1-1s-.447-1-1-1H3c-1.103,0-2,.897-2,2v4c0,.552.447,1,1,1s1-.448,1-1v-3.087c2.267,2.569,5.551,4.087,9,4.087,6.617,0,12-5.383,12-12,0-.552-.447-1-1-1Zm-12.142,8.919c.374.054.759.081,1.142.081,4.411,0,8-3.589,8-8,0-.78-.111-1.55-.332-2.287-.159-.529-.715-.832-1.244-.671-.529.158-.83.715-.672,1.245.165.551.248,1.127.248,1.713,0,3.309-2.691,6-6,6-.288,0-.577-.021-.858-.061-.542-.078-1.052.301-1.132.848-.078.546.302,1.053.849,1.131Zm1.142-13.919c.286,0,.575.021.859.061.55.079,1.053-.303,1.131-.85.077-.546-.303-1.053-.85-1.131-.377-.053-.761-.081-1.141-.081-4.411,0-8,3.589-8,8,0,.777.111,1.546.331,2.286.13.434.527.715.959.715.094,0,.19-.014.285-.042.529-.157.831-.714.674-1.244-.165-.555-.249-1.132-.249-1.715,0-3.309,2.691-6,6-6Zm1.126,8.2h-2.204c-.223,0-.434-.107-.564-.287-.142-.193-.433-.213-.552-.213-.381,0-.628.246-.732.477-.131.291-.086.604.122.84.438.498,1.066.783,1.727.783h.278v.7c0,.441.358.8.8.8s.8-.359.8-.8v-.7h.326c.647,0,1.257-.287,1.673-.787.414-.499.583-1.156.463-1.803-.16-.853-.889-1.551-1.812-1.735l-2.688-.539c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.204c.223,0,.434.107.564.287.142.193.433.213.552.213.381,0,.628-.246.732-.476.132-.291.086-.605-.121-.841-.438-.497-1.067-.782-1.728-.782h-.277v-.7c0-.441-.358-.8-.8-.8s-.8.359-.8.8v.7h-.327c-.646,0-1.256.287-1.672.786-.414.499-.583,1.156-.463,1.804.159.853.887,1.55,1.812,1.735l2.688.538c.268.054.462.291.462.563,0,.316-.258.574-.574.574Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==505 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21.016,14.99c0,.547-.443.99-.99.99s-.99-.443-.99-.99.443-.99.99-.99.99.443.99.99Zm-5-4.99c.552,0,1-.452,1-1.01s-.448-1.01-1-1.01-1,.452-1,1.01.448,1.01,1,1.01Zm8,2c0,6.617-5.383,12-12,12h-.016C5.383,24,0,18.617,0,12S5.383,0,12,0s12.016,5.383,12.016,12Zm-1,0c0-6.065-4.935-11-11-11S1,5.935,1,12s4.935,11,11,11h.016c6.065,0,11-4.935,11-11Zm-17.433,1.498c1.141-.779,2.433-1.662,2.433-2.998,0-1.378-1.122-2.5-2.5-2.5s-2.5,1.122-2.5,2.5c0,.276.224.5.5.5s.5-.224.5-.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5c0,.808-1.015,1.501-1.997,2.172-1.03.704-2.003,1.368-2.002,2.312-.002.072,0,.449.276.734.125.128.348.282.707.282h3.5c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-3.484c0-.432.833-1.001,1.567-1.502Zm5.917-2.498h-1.5v-1.5c0-.276.224-.5.5-.5h2.5c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-2.5c-.827,0-1.5.673-1.5,1.5v1.5c0,.551.449,1,1,1h1.5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5h-2c-.276,0-.5.224-.5.5s.224.5.5.5h2c1.378,0,2.5-1.122,2.5-2.5s-1.122-2.5-2.5-2.5Zm9.291-2.907c-.226-.16-.538-.108-.698.116l-5,7c-.16.225-.108.537.116.697.089.063.19.093.291.093.155,0,.31-.073.407-.209l5-7c.16-.225.108-.537-.116-.697Z"/>
                      </svg>
                      <span class="text-size-sm text-center">My 25% Liquidations</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==506 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer']" @click="barClick(506)">
                      <!-- <svg :class="[selectedSubMenuItem==502 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m21,22.5v1.5h-3v-1.5c0-1.379-1.121-2.5-2.5-2.5h-7c-1.378,0-2.5,1.121-2.5,2.5v1.5h-3v-1.5c0-3.032,2.467-5.5,5.5-5.5h7c3.032,0,5.5,2.468,5.5,5.5ZM12,7.016c1.651,0,2.996,1.342,3,2.992,0,.35-.073.68-.185.992h2.185c1,0,.999-.648,1-.984v-.016c-.009-3.301-2.697-5.984-6-5.984s-5.992,2.684-6,5.984v.016c.005,1.789.801,3.392,2.051,4.49-.021-.167-.051-.332-.051-.505,0-1.129.472-2.145,1.225-2.872-.141-.347-.224-.723-.225-1.12.004-1.65,1.349-2.992,3-2.992Zm-5.128-2.988c1.529-1.433,3.531-2.141,5.63-2.011,3.924.254,6.999,3.669,6.999,7.774,0,1.77-1.439,3.209-3.209,3.209h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.872,0,5.209-2.337,5.209-5.209C21.5,4.634,17.604.342,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.787,1.675-2.861,3.996-2.99,6.432h2.003c.123-1.903.948-3.654,2.355-4.973Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==506 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m23.55,19.071l-4.293,4.207c-.48.48-1.116.722-1.753.722-.641,0-1.283-.244-1.771-.732l-4.25-4.25,2.121-2.121,2.396,2.396V0h3v19.33l2.45-2.401,2.1,2.143Zm-10.246-7.445c0-1.033-.734-1.929-1.747-2.131l-2.792-.559c-.267-.054-.461-.291-.461-.562,0-.316.257-.574.573-.574h2.127c.316,0,.585.211.671.5h1.62c-.102-1.175-1.09-2.1-2.291-2.1h-.2v-1.2h-1.6v1.2h-.327c-1.198,0-2.173.975-2.173,2.173,0,1.033.734,1.929,1.747,2.131l2.791.559c.268.054.462.291.462.563,0,.316-.258.574-.574.574h-2.126c-.316,0-.585-.211-.671-.5h-1.62c.102,1.175,1.09,2.1,2.291,2.1h.2v1.2h1.6v-1.2h.326c1.199,0,2.174-.975,2.174-2.173Zm-10.304-1.626c0-3.86,3.14-7,7-7,1.487,0,2.865.47,4,1.264V.841c-1.226-.538-2.578-.841-4-.841C4.486,0,0,4.486,0,10s4.486,10,10,10v-3c-3.86,0-7-3.14-7-7Z"/>
                      </svg> -->
                      <svg :class="[selectedSubMenuItem==506 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m9.436,14h1.564c0,3-.494,4.892-.805,6h-2.086c.139-.45.511-1.721.729-3.127l-1.01-1.114c.685-.62,1.301-1.38,1.307-1.388l.3-.371ZM24,3v19h-6.015c.193-.577.402-1.245.612-2h3.403V7c-.614,0-1.179-.23-1.63-.616-.299-.256-.668-.384-1.037-.384s-.738.128-1.037.384c-.451.386-1.016.616-1.63.616s-1.179-.23-1.63-.616c-.299-.256-.668-.384-1.037-.384s-.738.128-1.037.384c-.451.386-1.016.616-1.63.616s-1.179-.23-1.63-.616c-.299-.256-.668-.384-1.037-.384s-.738.128-1.037.384c-.451.386-1.016.616-1.63.616V3c0-1.654,1.346-3,3-3h12c1.654,0,3,1.346,3,3Zm-2,0c0-.551-.449-1-1-1h-12c-.551,0-1,.449-1,1v1.065c.219-.041.441-.065.667-.065.856,0,1.687.307,2.338.865.104.089.215.135.328.135s.224-.045.328-.135c.652-.558,1.482-.865,2.338-.865s1.687.307,2.338.865c.104.089.215.135.328.135s.224-.045.328-.135c.652-.558,1.482-.865,2.338-.865s1.687.307,2.338.865c.104.089.215.135.328.135v-2Zm-4.001,7.447c.001,6.683-2.552,12.696-2.661,12.949l-.26.604H0v-1.577c.646-1.189,2.502-6.817,2.577-11.938l.007-.454.491-.416c.991-.822,2.429-.822,3.418,0,.608.504,1.48.504,2.087,0,.99-.821,2.428-.821,3.418,0,.608.504,1.48.504,2.087,0,.99-.821,2.428-.821,3.418,0l.474.351.02.481Zm-2.004.583c-.213-.063-.454-.021-.631.125-1.313,1.092-3.326,1.092-4.641,0-.247-.205-.618-.203-.864,0-1.313,1.092-3.326,1.092-4.641,0-.183-.152-.435-.191-.653-.117-.15,4.361-1.355,8.759-2.185,10.963h11.361c.604-1.595,2.147-6.123,2.254-10.971Z"/>
                      </svg>
                      <span class="text-size-sm text-center">25% Liquidation Approval History</span>
                    </div>
                    <div v-bind="selectedSubMenuItem" :class="[ selectedSubMenuItem==507 ? 'flex items-center px-5 py-2 bg-emerald-100 mx-2 space-x-2 cursor-pointer pb-4' : 'flex items-center px-5 mx-2 space-x-2 cursor-pointer pb-4']" @click="barClick(507)">
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M15,17c0,.55,.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3H0v-2H19c.55,0,1-.45,1-1s-.45-1-1-1h-3c-1.65,0-3-1.35-3-3s1.35-3,3-3h8v2h-8c-.55,0-1,.45-1,1Zm-4.45,.5L.04,19.96,2.5,9.45l5.55-1.74L14.88,.88c1.13-1.13,3.11-1.13,4.24,0h0s0,0,0,0c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12l-6.83,6.83-1.74,5.55Zm-.55-8.91l1.41,1.41,6.29-6.29c.19-.19,.29-.44,.29-.71s-.1-.52-.29-.71h0c-.38-.38-1.04-.38-1.41,0l-6.29,6.29Zm-1.02,7.22l1.29-4.12-1.97-1.97-4.12,1.29-1.03,4.41,2.86-2.86,1.41,1.41-2.86,2.86,4.41-1.03Z"/></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==503 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><polygon points="18.707 6.707 17.293 5.293 12 10.586 6.707 5.293 5.293 6.707 10.586 12 5.293 17.293 6.707 18.707 12 13.414 17.293 18.707 18.707 17.293 13.414 12 18.707 6.707"/></g></svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==403 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m14,22.5c0,.828-.672,1.5-1.5,1.5h-3.819c-2.838,0-5.245-2.215-5.481-5.043l-1.08-12.957h-.619c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h4.757c.619-1.746,2.287-3,4.243-3h3c1.956,0,3.624,1.254,4.243,3h4.757c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5H5.131l1.059,12.707c.106,1.286,1.201,2.293,2.491,2.293h3.819c.828,0,1.5.672,1.5,1.5Zm8.5-14.5h-6c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h6c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-4c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h4c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Zm-2,5h-2c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h2c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5Z"/>
                      </svg> -->
                      <!-- <svg :class="[selectedSubMenuItem==507 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24c-1.506,0-2.928-.583-4.004-1.64l-3.734-3.466C-.065,14.548-.065,7.543,4.219,3.238,6.297,1.15,9.061,0,12,0s5.702,1.15,7.781,3.238h0c4.284,4.305,4.284,11.31,0,15.615l-3.809,3.537c-1.045,1.028-2.467,1.61-3.973,1.61Zm0-21c-2.136,0-4.144,.836-5.654,2.354-3.124,3.138-3.124,8.245,0,11.383l3.723,3.455c1.084,1.063,2.811,1.034,3.832,.029l3.797-3.525c3.081-3.097,3.081-8.204-.043-11.342-1.511-1.518-3.52-2.354-5.654-2.354Zm-1.496,12c-.641,0-1.283-.244-1.771-.732l-2.272-2.187c-.597-.574-.615-1.524-.041-2.121,.575-.597,1.524-.616,2.121-.041l1.957,1.883,4.452-4.373c.592-.58,1.541-.571,2.121,.02,.58,.591,.571,1.541-.02,2.121l-4.793,4.707c-.481,.481-1.117,.723-1.754,.723Z"/></svg> -->
                      <svg :class="[selectedSubMenuItem==507 ? 'h-4 w-4 text-emerald-500 fill-current' : 'h-4 w-4 text-gray-500 fill-current']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m11.131,12.207c-.555.543-1.282.814-2.009.814-.732,0-1.464-.275-2.02-.825l-1.78-1.76c-.393-.388-.396-1.021-.008-1.414.388-.393,1.02-.396,1.414-.008l1.78,1.76c.336.332.884.335,1.224.003l3.568-3.492c.395-.387,1.027-.378,1.414.016.386.395.379,1.028-.016,1.414l-3.568,3.492Zm12.869,4.793v4c0,1.654-1.346,3-3,3h-6.379c-1.9,0-3.551-1.353-3.923-3.216l-.639-3.196c-.177-.884.05-1.792.622-2.49.571-.697,1.417-1.098,2.319-1.098h3.183l-.58-3.265c-.057-.334-.029-.519-.007-.599.188-.69.75-1.136,1.432-1.136.213,0,.428.044.64.132.254.105.503.367.682.719l2.193,4.149h.456c1.654,0,3,1.346,3,3Zm-9.379,5h5.379v-6h-6.999c-.301,0-.582.133-.772.365-.191.233-.267.536-.208.83l.639,3.196c.187.932,1.012,1.608,1.962,1.608Zm-6.844-4.312c-3.401-.982-5.777-4.144-5.777-7.688C2,5.589,5.589,2,10,2c3.06,0,5.807,1.703,7.169,4.445.245.495.844.698,1.341.451.494-.246.696-.846.45-1.34C17.257,2.128,13.824,0,10,0,4.486,0,0,4.486,0,10c0,4.43,2.97,8.381,7.223,9.609.093.026.186.039.277.039.435,0,.834-.284.961-.723.153-.53-.153-1.085-.684-1.238Z"/>
                      </svg>
                      <span class="text-size-sm text-center pr-10">Approve 25% Liquidations</span>
                      <span v-if="liquidation25ToApprove !== 0" class="bg-red-400 text-white text-size-sm px-1.5 ml-4">{{ liquidation25ToApprove }}</span>
                    </div>
                    
                  </div>
              </PopoverPanel>
            </Popover>

            <!-- divider line -->
            <div class=" h-2 w-full border-t"></div>
            <div  class="px-4 color: #42b983"  @click="barClick(1000)">
              <a href="#">
                <div class="flex space-x-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500 fill-current" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M15,2.426v2.1a9,9,0,1,1-6,0v-2.1a11,11,0,1,0,6,0Z"/><rect x="11" width="2" height="8"/></g></svg>
                  <span class="font-medium text-size-sm  text-gray-600">Signout</span>
                </div>
              </a>
            </div>
          </nav>
        </div>
  </template>
  
  <script>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { mapState } from 'vuex'
  import { ajax } from 'rxjs/ajax'
  import { retry, zip } from 'rxjs';
  
  export default {
    computed: {
      ...mapState([
        'activeListItemIndex',
        'selectedSubMenuItem',
        'email',
        'loginId',
        'token',
        'employee'
      ])
    },
    components: {
      Popover,
      PopoverButton,
      PopoverPanel
    },
    setup () {
      return {
      }
    },
    name: 'side-bar',
    props: {
    },
    data: () => {
      return {
        position: '',
        requestToApprove: 0,
        liquidationToApprove: 0,
        liquidation75ToApprove: 0,
        liquidation25ToApprove: 0,
        paymentToApprove: 0,
        paymentLiquidationToApprove: 0,
        financeDelegate: false,
        delegationData: null,
        BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    mounted () {
      // the employees data
      this.position = this.employee.position
      // console.log(this.position)
      this.getPendingRequests()
      this.getPendingLiquidations()
      this.getPendingPayments()
      this.getPendingPaymentLiquidations()
      this.getPending75Liquidations()
      this.getPending25Liquidations()
      this.getDelegation()
    },
    methods: {
      getPendingDelegationRequests () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/request-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.requestToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      getPendingDelegationLiquidations () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/liquidation-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.liquidationToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      getPendingDelegationPayments () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/payment-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.paymentToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      getPendingDelegationPaymentLiquidations () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/payment-liquidation-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.paymentLiquidationToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      getPendingDelegation75Liquidations () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/seventy-five-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.liquidation75ToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      getPendingDelegation25Liquidations () {
        const delegationSize = this.delegationData.length
        // console.log(delegationSize)
        const observables = []

        for (let i = 0; i < delegationSize; i++) {
          const delegate = this.delegationData[i]
          // console.log(delegate)
          const delegateId = delegate.owner_id
          const observable = ajax({
                                  url: this.BASE_URL + `/twenty-five-approval/findToApprove?id=${delegateId}`,
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${this.employee.token}`
                                  }
                                })
          
          observables.push(observable)
        }

        const observable = zip(...observables)
        const observer = {
          next: (data) => {
            const size = data.length
            for (let i = 0; i < size; i++) {
              const result = data[i].response
              const size = result.length
              this.liquidation25ToApprove += size
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)

      },
      // getting the pending activity approvals
      getDelegation () {
        this.showRequests = false
        const observable = ajax({
          url: this.BASE_URL + `/delegation/findByDelegateId?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            // console.log(response)
            const data = response.response
            this.delegationData = data
            const size = data.length
            if (size > 0) {
              // loop through the delegates & check if there is a finance delegate
              for (let i = 0; i < size; i++) {
                const delegate = data[i]
                const ownerPosition = delegate.owner_position
                // finance check
                if (ownerPosition.toString().toLowerCase().includes('finance')) {
                  this.financeDelegate = true
                  break
                }
              }

              // the pending delegation data
              this.getPendingDelegationRequests()
              this.getPendingDelegationLiquidations()
              this.getPendingDelegationPayments()
              this.getPendingDelegationPaymentLiquidations()
              this.getPendingDelegation75Liquidations()
              this.getPendingDelegation25Liquidations()
            }
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPending25Liquidations () {
        this.showRequests = false
        const observable = ajax({
          url: this.BASE_URL + `/twenty-five-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            // console.log(response)
            const data = response.response
            const size = data.length
            this.liquidation25ToApprove = size
            
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPending75Liquidations () {
        this.showRequests = false
        const observable = ajax({
          url: this.BASE_URL + `/seventy-five-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            // console.log(response)
            const data = response.response
            const size = data.length
            this.liquidation75ToApprove = size
            
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPendingPaymentLiquidations () {
        this.showRequests = false
        const observable = ajax({
          url: this.BASE_URL + `/payment-liquidation-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            const data = response.response
            this.paymentLiquidationToApprove = data.length
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPendingPayments () {
        this.showRequests = false
        const observable = ajax({
          url: this.BASE_URL + `/payment-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            // console.log(response)
            const data = response.response
            this.paymentToApprove = data.length
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPendingLiquidations () {
        const observable = ajax({
          url: this.BASE_URL + `/liquidation-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        }).pipe(
          retry(1)
        )
        const observer = {
          next: (response) => {
            const data = response.response
            this.liquidationToApprove = data.length
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // getting the pending activity approvals
      getPendingRequests () {
        const observable = ajax({
          url: this.BASE_URL + `/request-approval/findToApprove?id=${this.employee.id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: (response) => {
            const data = response.response
            this.requestToApprove = data.length
          },
          error: (err) => {
            const status = err.status
            if (status === 401) {
              this.$router.push('/')
            }
          },
          complete: () => {}
        }
        observable.subscribe(observer)
      },
      // signout of the system
      // logs out users
      logout () {
        this.$router.push('/')
        // const observable = ajax({
        //   url: this.BASE_URL + '/login/signout',
        //   method: 'PUT',
        //   headers: {
        //     Authorization: `Bearer ${this.token}`
        //   },
        //   body: {
        //     id: this.loginId
        //   }
        // })

        // const observer = {
        //   next: () => {
        //   },
        //   error: () => {
        //     this.$router.push('/')
        //   },
        //   complete: () => {
        //     this.$router.push('/')
        //   }
        // }
  
        // observable.subscribe(observer)
      },
      // side nav bar list items clicks
      barClick (index) {
        switch (index) {
          case 0:
            this.$router.push('/employee-home')
            this.$store.commit('mutateActiveListItemIndex', 0)
            this.$store.commit('mutateSelectedSubMenuItem', null)
            break
          case 100:
            this.$store.commit('mutateActiveListItemIndex', 100)
            this.$store.commit('mutateSelectedSubMenuItem', 100)
            this.$router.push('/employee-profile')
            break
          case 101:
            this.$store.commit('mutateActiveListItemIndex', 100)
            this.$store.commit('mutateSelectedSubMenuItem', 101)
            this.$router.push('/employee-sign')
            break
          case 102:
            this.$store.commit('mutateActiveListItemIndex', 100)
            this.$store.commit('mutateSelectedSubMenuItem', 102)
            this.$router.push('/employee-upload-signature')
            break
          case 103:
            this.$store.commit('mutateActiveListItemIndex', 100)
            this.$store.commit('mutateSelectedSubMenuItem', 103)
            this.$router.push('/employee-delegate-approvals')
            break
          case 200:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 200)
            this.$router.push('/create-liquidation-1')
            break
          case 201:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 201)
            this.$router.push('/employee-liquidations')
            break
          case 202:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 202)
            this.$router.push('/liquidation-approval-history')
            break
          case 203:
            this.$store.commit('mutateActiveListItemIndex', 200)
            this.$store.commit('mutateSelectedSubMenuItem', 203)
            this.$router.push('/employee-approve-liquidation')
            break
          case 300:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 300)
            this.$router.push('/create-payment-1')
            break
          case 301:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 301)
            this.$router.push('/employee-payments')
            break
          case 302:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 302)
            this.$router.push('/payments-approval-history')
            break
          case 303:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 303)
            this.$router.push('/approve-payments')
            break
          case 304:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 304)
            this.$router.push('/create-payment-liquidation-1')
            break
          case 305:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 305)
            this.$router.push('/payment-liquidations')
            break
          case 306:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 306)
            this.$router.push('/payment-liquidation-approvalHistory')
            break
          case 307:
            this.$store.commit('mutateActiveListItemIndex', 300)
            this.$store.commit('mutateSelectedSubMenuItem', 307)
            this.$router.push('/approve-payment-liquidations')
            break
          case 400:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 400)
            this.$router.push('/create-request-1')
            break
          case 401:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 401)
            this.$router.push('/employee-requests')
            break
          case 402:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 402)
            this.$router.push('/request-approval-history')
            break
          case 403:
            this.$store.commit('mutateActiveListItemIndex', 400)
            this.$store.commit('mutateSelectedSubMenuItem', 403)
            this.$router.push('/approve-requests')
            break
          case 500:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 500)
            this.$router.push('/create-75liquidation-1')
            break
          case 501:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 501)
            this.$router.push('/employee-75liquidations')
            break
          case 502:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 502)
            this.$router.push('/liquidations75-approval-history')
            break
          case 503:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 503)
            this.$router.push('/approve-75liquidations')
            break
          case 504:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 504)
            this.$router.push('/create-25liquidation-1')
            break
          case 505:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 505)
            this.$router.push('/employee-25liquidations')
            break
          case 506:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 506)
            this.$router.push('/liquidations25-approval-history')
            break
          case 507:
            this.$store.commit('mutateActiveListItemIndex', 500)
            this.$store.commit('mutateSelectedSubMenuItem', 507)
            this.$router.push('/approve-25liquidations')
            break

          default:
            // signout
            this.logout()
        }
      }
    }
  }
  </script>
  
  <style>
  </style>
  