<template>
    <EmployeeApprovePaymentLiquidations></EmployeeApprovePaymentLiquidations>
</template>
<script>
    import EmployeeApprovePaymentLiquidations from '@/components/EmployeeApprovePaymentLiquidation.vue'
    export default {
        name: 'employee-view-request',
        components: {
            EmployeeApprovePaymentLiquidations
        }
    }
</script>