<template>
    <EditRequest6></EditRequest6>
</template>
<script>
    import EditRequest6 from '@/components/EditRequest6.vue'
    export default {
        name: 'create-request-6',
        components: {
            EditRequest6
        }
    }
</script>