<template>
    <CreateLiquidation5></CreateLiquidation5>
</template>
<script>
    import CreateLiquidation5 from '@/components/CreateLiquidation5.vue'
    export default {
        name: 'create-liquidation',
        components: {
            CreateLiquidation5
        }
    }
</script>