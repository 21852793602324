<template>
    <EditRequest5></EditRequest5>
</template>
<script>
    import EditRequest5 from '@/components/EditRequest5.vue'
    export default {
        name: 'create-request-5',
        components: {
            EditRequest5
        }
    }
</script>