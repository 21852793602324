<template>
    <SpendPlanManagement></SpendPlanManagement>
</template>
<script>
    import SpendPlanManagement from '@/components/SpendPlanManagement.vue'
    export default {
        name: 'spend-plan-management',
        components: {
            SpendPlanManagement
        }
    }
</script>