<template>
    <!-- the page content -->
    <div :id="elementId" class="mt-2 cursor-pointer">
        <div
            @click="initialSearch()"
            style=" padding-top: 1em; padding-bottom: 0.82em;"
            class="w-full text-size cursor-pointer text-left shadow-sm overflow-hidden
            border px-2 bg-gray-50 border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
            focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm space-x-1 space-y-1 flex justify-between">
              <div v-if="multipleSelection && selectedItems.length != 0"
                class="space-x-1 overflow-hidden">
                <span
                  class=" bg-emerald-600 box-shadow px-2 py-0.5 text-center overflow-hidden text-white"
                  v-for="(data, i) in selectedItems.slice(0, 2)" :key="i">
                  <span v-if="i == 0">{{ formatData(data.name) }}</span>
                  <span v-else>...</span>
                </span>
              </div>
              <span
                v-else-if="selectedItems.id !== undefined && selectedItems.id !== 0 && selectedItems.length != 0"
                class=" bg-emerald-600 box-shadow px-2 py-0.5 text-center text-white items-center">
                {{ formatData(selectedItems.name) }}
              </span>
              <span
                v-else
                class=" bg-gray-400 box-shadow px-2 py-0.5 text-center text-white">
                Search and Select
              </span>
              <div class="flex space-x-2">
                <!-- <svg class="w-2 h-2" xmlns="http://www.w3.org/2000/svg" id="arrow-circle-down" viewBox="0 0 24 24" width="512" height="512"><g><path d="M.305,12.293a1,1,0,0,1,1.414,0l8.172,8.171a3,3,0,0,0,4.242,0l8.172-8.171a1,1,0,0,1,1.414,1.414l-8.172,8.171a5,5,0,0,1-7.07,0L.305,13.707a1,1,0,0,1,0-1.414Z"/><path d="M.305,2.293a1,1,0,0,1,1.414,0l9.586,9.586a1,1,0,0,0,1.414,0l9.586-9.586a1,1,0,0,1,1.414,1.414l-9.586,9.586a3,3,0,0,1-4.242,0L.305,3.707a1,1,0,0,1,0-1.414Z"/></g></svg> -->
                <svg @click.stop="showSelectedOnly()" :class="[ showSelected ? 'w-4 h-4 fill-orange-500' : 'w-4 h-4 fill-gray-500']" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m2.876,7.118l13.189,13.189c-.715.254-1.489.441-2.314.559L1.602,8.718c.361-.517.781-1.06,1.274-1.6ZM.179,11.181c-.237.521-.237,1.118,0,1.64.041.091.094.199.147.308l6.911,6.911c1.107.476,2.361.8,3.766.908L.529,10.473c-.143.272-.261.514-.35.708Zm19.51,7.065l4.292,4.292-1.414,1.414L.019,1.403,1.433-.011l4.58,4.58c1.809-1.042,3.819-1.57,5.988-1.57,7.498,0,10.944,6.261,11.821,8.18.238.521.238,1.119,0,1.639-.478,1.046-1.77,3.485-4.134,5.427ZM7.489,6.046l1.771,1.771c1.939-1.27,4.573-1.054,6.275.648,1.702,1.702,1.918,4.336.648,6.275l2.084,2.084c2.139-1.693,3.31-3.904,3.737-4.836-.753-1.621-3.697-6.987-10.003-6.987-1.621,0-3.133.351-4.512,1.046Zm6.632,3.833c-.917-.916-2.283-1.114-3.395-.595l3.99,3.99c.519-1.112.321-2.478-.595-3.395Z"/></svg>
                <svg v-if="!show" class="w-4 h-4 fill-gray-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,13.033c-.445,0-.864-.173-1.178-.488L4.951,6.674l2.121-2.121,4.928,4.928,4.928-4.928,2.121,2.121-5.87,5.871c-.315,.315-.733,.488-1.179,.488Zm1.725,6.278l5.345-5.344-2.121-2.121-4.991,4.991-4.896-4.896-2.121,2.121,5.25,5.25c.472,.472,1.1,.732,1.768,.732s1.296-.26,1.768-.732Z"/></svg>
                <svg v-else class="w-4 h-4 fill-emerald-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M16.928,12.111l-4.928-4.928-4.928,4.928-2.121-2.121,5.281-5.281c.943-.944,2.592-.944,3.535,0l5.281,5.281-2.121,2.121Zm2.121,5.248l-5.867-5.867c-.629-.635-1.738-.631-2.359-.004l-5.871,5.871,2.121,2.121,4.928-4.927,4.928,4.927,2.121-2.121Zm-7.985-3.743l-.007-.007,.007,.007Zm1.877-.004l.007-.007-.007,.007Z"/></svg>
              </div>
        </div>
        <!-- the list to select the items from -->
        <div v-if="show" class=" shadow-2xl text-size relative">
          <div class="absolute shadow w-full bg-white border z-50">
            <div class=" flex items-center">
                <input
                    v-model="searchText"
                    id="searchBox"
                    autocomplete="off"
                    @keyup.enter="searchCall()"
                    class=" my-1 py-1.5 px-2 w-8/12 bg-gray-50 ml-2 text-left text-gray-500 border shadow-sm focus:outline-none focus:border-emerald-600 focus:ring-1 focus:ring-emerald-600"
                    placeholder="Search"
                >
                <button
                    id="searchbtn"
                    @click="searchCall()"
                    class=" mx-5 space-x-1 bg-emerald-600 px-5 py-0.5 shadow-sm box-shadow hover:scale-105 transform">
                    <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M24,22.586l-6.262-6.262a10.016,10.016,0,1,0-1.414,1.414L22.586,24ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/></g></svg> -->
                    <div class="flex items-center space-x-1">
                        <svg v-if="showLoader == true" class="h-4 w-4 text-white fill-current animate-spin" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m12 5a1.5 1.5 0 0 1 -1.5-1.5v-2a1.5 1.5 0 0 1 3 0v2a1.5 1.5 0 0 1 -1.5 1.5zm1.5 17.5v-2a1.5 1.5 0 0 0 -3 0v2a1.5 1.5 0 0 0 3 0zm-8.5-10.5a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm19 0a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm-6.524-6.671.981-1.743a1.5 1.5 0 0 0 -2.613-1.473l-.982 1.743a1.5 1.5 0 0 0 .571 2.044 1.484 1.484 0 0 0 .735.194 1.5 1.5 0 0 0 1.308-.765zm-9.32 16.558.982-1.743a1.5 1.5 0 0 0 -2.614-1.473l-.981 1.743a1.5 1.5 0 0 0 2.613 1.473zm-2.256-13.32a1.5 1.5 0 0 0 -.57-2.043l-1.744-.981a1.5 1.5 0 0 0 -1.473 2.613l1.743.982a1.5 1.5 0 0 0 2.044-.571zm16.558 9.32a1.5 1.5 0 0 0 -.57-2.043l-1.743-.982a1.5 1.5 0 0 0 -1.473 2.614l1.743.981a1.5 1.5 0 0 0 2.043-.57zm-13.891-11.987a1.5 1.5 0 0 0 .571-2.043l-.982-1.744a1.5 1.5 0 0 0 -2.613 1.473l.981 1.743a1.5 1.5 0 0 0 1.308.764 1.484 1.484 0 0 0 .735-.193zm9.32 16.558a1.5 1.5 0 0 0 .57-2.043l-.981-1.743a1.5 1.5 0 0 0 -2.614 1.473l.982 1.743a1.5 1.5 0 0 0 2.043.57zm2.257-13.32 1.743-.982a1.5 1.5 0 0 0 -1.473-2.613l-1.743.981a1.5 1.5 0 0 0 1.473 2.614zm-16.558 9.319 1.743-.981a1.5 1.5 0 0 0 -1.473-2.614l-1.743.982a1.5 1.5 0 0 0 .738 2.806 1.483 1.483 0 0 0 .735-.193z"/></svg>
                        <span v-if="showLoader" class="text-white">Searching</span>
                        <span v-else class="text-white">Search</span>
                    </div>
                </button>
            </div>
            <ul :class="searchData.length <= 5 ? 'text-left px-4 text-gray-500 space-y-2 pt-1 pb-2' : 'text-left px-4 text-gray-500 space-y-2 pt-1 pb-2 h-32 overflow-y-scroll'">
                <li
                  @click="addOrRemoveItem(data)"
                  v-for="(data, i) in searchData" :key="i"
                  class=" cursor-pointer">
                  <div class="flex items-center justify-between">
                    <span :class="[ isAvailable(data.id) ? 'text-emerald-500 font-medium text-xs' : 'text-gray-600 text-size-li']">{{ formatData(data.name) }}</span>
                    <svg v-if="isAvailable(data.id)" class="w-3 h-3 text-emerald-600 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M7.77,20.589a3.012,3.012,0,0,1-2.137-.883L0,14.073l1.424-1.425,5.633,5.633a1.008,1.008,0,0,0,1.425,0L22.576,4.187,24,5.612,9.906,19.706A3.01,3.01,0,0,1,7.77,20.589Z"/></svg>
                  </div>
                </li>
            </ul>
            <ul v-if="searchData.length == 0" class="text-left px-4 text-gray-500 pb-2">
              <li
                class=" cursor-pointer">
                <div class="flex items-center justify-between">
                  <span v-if="showLoader" class=" text-gray-600">Searching....</span>
                  <span v-else-if="!triggersAnotherDialog" class=" text-gray-600">No results found</span>
                  <span @click="openAnotherModal()" v-else class=" mx-5 space-x-1 bg-emerald-600 text-white px-5 py-0.5 shadow-sm box-shadow transform">{{ triggerButtonLabel }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import { onMounted, ref } from 'vue'
import { fromEvent, throttleTime, delay } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { mapState } from 'vuex'

export default {
  name: 'custom-drop-down',
  computed: {
    ...mapState([
      'employee'
    ])
  },
  watch: {
    searchText: function (newValue, oldValue) {
      if (newValue) {
        console.log(oldValue)
        this.rxSearch()
      }
    }
  },
  mounted () {
    // get the passed in data
    if (this.multipleSelection) {
      const size = this.persistedSelection.length
      for (var i = 0; i < size; i++) {
        const item = this.persistedSelection[i]
        this.selectedItems.push(item)
      }
    } else {
      this.selectedItems = this.persistedSelection
    }
  },
  components: {
  },
  setup (props) {
    var show = ref(false)
    var showSelected = ref(false)
    onMounted(() => {
      // const newId = props.elementId
      try {
        // set the id
        // if (document.getElementById('box') !== null) {
        // document.getElementById('box').id = newId
        // // set the outside click listener
        // document.addEventListener('click', (event) => {
        //   const box = document.getElementById(newId)
        //   if (box !== null) {
        //     if (!box.contains(event.target)) {
        //       show.value = false
        //     }
        //   }
        // })
        const id = props.elementId
        document.addEventListener('click', (event) => {
          const box = document.getElementById(id)
          if (box !== null) {
            if (!box.contains(event.target)) {
              show.value = false
              showSelected.value = false
            }
          }
        })

      } catch (err) {
        console.log(err)
      }
      // }
    })
    return {
      show,
      showSelected,
      // open's the search modal
      openSearch () {
        show.value = true
      },
      closeSearch () {
        show.value = false
      },
      // show the selected only
      displaySelected () {
        showSelected.value = true
      },
      closeSelected () {
        showSelected.value = false
      }
    }
  },
  props: {
    elementId: String,
    msg: String,
    url: String,
    persistedSelection: Array,
    parameters: JSON,
    display: JSON,
    localCall: Boolean,
    params: String,
    closeAfterSelection: Boolean,
    multipleSelection: Boolean,
    triggersAnotherDialog: Boolean,
    triggerButtonLabel: String
  },
  data: () => {
    return {
      searchText: '',
      showLoader: false,
      searchData: [],
      selectedItems: [],
      activityAPI: process.env.VUE_APP_ACTIVITY_API
    }
  },
  methods: {
    setSelection (data) {
      this.selectedItems = data
    },
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else {
        formattedData = data
      }
      return formattedData
    },
    openAnotherModal () {
      this.$emit('openAnotherModal', '')
    },
    showSelectedOnly () {
      if (!this.showSelected) {
        this.displaySelected()
        this.openSearch()
        this.selectedSearchCall()
      } else {
        this.closeSelected()
        this.searchCall()
      }
    },
    // the initial search
    initialSearch () {
      this.showSelected = false
      this.openSearch()
      this.searchCall()
    },
    rxSearch () {
      try {
        const searchbox = document.getElementById('searchBox')
        if (searchbox !== null) {
          const observable = fromEvent(searchbox, 'keyup')
            .pipe(
              delay(3000)
            )
          const observer = {
            next: () => {
              this.searchCall()
            },
            error: () => {
            },
            complete: () => {
            }
          }
          observable.subscribe(observer)
        }
      } catch (err) {
        console.log(err)
      }
    },
    clearSelection () {
      this.selectedItems = []
      this.searchData = []
      this.emitEvent()
    },
    emitEvent () {
      this.$emit('selectedItems', this.selectedItems)
    },
    // makes a search call to the back-end
    searchCall () {
      this.searchData = []
      this.showLoader = true
      // open the search box if it was closed
      this.openSearch()

      // open the search box if it was closed
      this.openSearch()
      var url = ''

      if (this.params !== undefined) {
        url = process.env.VUE_APP_BASE_URL + this.url + `?searchText=${this.searchText}` + this.params
      } else {
        url = process.env.VUE_APP_BASE_URL + this.url + `?searchText=${this.searchText}`
      }

      const observable = ajax({
        url: url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        throttleTime(4000)
      )
      const observer = {
        next: (response) => {
          this.searchData = []
          const data = response.response
          const size = data.length

          const idValue = this.display.id
          const namevalue = this.display.name
          // loop through the data and load it
          for (var i = 0; i < size; i++) {
            const id = data[i][idValue]
            var names = ''

            // loop through the names fields and get all the data
            const nameFieldsSize = namevalue.length
            for (var k = 0; k < nameFieldsSize; k++) {
              if (k !== (nameFieldsSize - 1)) {
                names += data[i][namevalue[k]] + ', '
              } else {
                names += data[i][namevalue[k]] + ''
              }
            }

            // trim the name if its too long
            if (names.length > 80) {
              names = names.substring(0, 72) + '...'
            }

            const listData = {
              id: id,
              name: names,
              allData: data[i]
            }
            this.searchData.push(listData)
          }
        },
        error: () => {
          this.showLoader = false
        },
        complete: () => {
          // close the loader
          this.showLoader = false
        }
      }
      observable.subscribe(observer)
    },
    // makes a search call to the back-end
    selectedSearchCall () {
      this.searchData = []
      this.showLoader = true
      // open the search box if it was closed
      this.openSearch()

      // open the search box if it was closed
      this.openSearch()
      var url = ''

      const searchText = ''
      if (this.params !== undefined) {
        url = process.env.VUE_APP_BASE_URL + this.url + `?searchText=${searchText}` + this.params
      } else {
        url = process.env.VUE_APP_BASE_URL + this.url + `?searchText=${searchText}`
      }

      const observable = ajax({
        url: url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.employee.token}`
        }
      }).pipe(
        throttleTime(4000)
      )
      const observer = {
        next: (response) => {
          this.searchData = []
          const data = response.response
          const size = data.length

          const idValue = this.display.id
          const namevalue = this.display.name
          // loop through the data and load it
          for (var i = 0; i < size; i++) {
            const id = data[i][idValue]
            var names = ''

            // loop through the names fields and get all the data
            const nameFieldsSize = namevalue.length
            for (var k = 0; k < nameFieldsSize; k++) {
              if (k !== (nameFieldsSize - 1)) {
                names += data[i][namevalue[k]] + ', '
              } else {
                names += data[i][namevalue[k]] + ''
              }
            }

            // trim the name if its too long
            if (names.length > 80) {
              names = names.substring(0, 72) + '...'
            }

            const listData = {
              id: id,
              name: names,
              allData: data[i]
            }

            // check if the item is in the selected array
            if (Array.isArray(this.selectedItems)) {
              const selectedSize = this.selectedItems.length
              for (let j = 0; j < selectedSize; j++) {
                const itemId = this.selectedItems[j].id
                if (itemId === id) {
                  this.searchData.push(listData)
                }
              }
            } else {
              // else if its an object
              const itemId = this.selectedItems.id
              if (itemId == id) {
                this.searchData.push(listData)
              }
            }
          }
        },
        error: () => {
          this.showLoader = false
        },
        complete: () => {
          // close the loader
          this.showLoader = false
        }
      }
      observable.subscribe(observer)
    },
    // adds or removes a clciked item to the selection list
    addOrRemoveItem (item) {
      if (this.multipleSelection) {
        const id = item.id

        // remove if its available add it if it is not available
        const availability = this.isAvailable(id)

        // its available remove it
        if (availability) {
          this.removeItem(id)
        } else {
          const itemsList = this.selectedItems
          itemsList.push(item)
          itemsList.reverse()
          this.selectedItems = itemsList
        }
      } else {
        // check if its the current item
        if (this.selectedItems.id === item.id) {
          this.selectedItems = []
        } else {
          this.selectedItems = item
        }
      }
      // emit the event
      this.emitEvent()

      // close the dialog box
      if (this.closeAfterSelection) {
        this.closeSearch()
      }
    },
    // checks if an item is available in the list
    isAvailable (id) {
      var availableSelection = this.selectedItems
      // check if its available in the list
      if (!this.multipleSelection) {
        availableSelection = [this.selectedItems]
      }
      return availableSelection.some((data) => {
        return data.id === id
      })
    },
    removeItem (id) {
      // search for it
      const size = this.selectedItems.length
      for (var i = 0; i < size; i++) {
        const thisId = this.selectedItems[i].id
        if (id === thisId) {
          this.selectedItems.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    color: #42b983;
  }

  .text-size {
    font-size: 0.8em;
  }

  .text-size-btn {
    font-size: 0.76em;
  }

  .text-size-input {
    font-size: 0.9em;
  }

  .text-size-li {
    font-size: 0.9em;
  }

    /* box shadow effect */
    .box-shadow{
    box-shadow: 0 4px 8px 0 rgba(5, 160, 105, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
</style>
