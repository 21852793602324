<template>
    <AdminTrackRequest></AdminTrackRequest>
</template>
<script>
    import AdminTrackRequest from '@/components/AdminTrackRequest.vue'
    export default {
        name: 'employee-track-request',
        components: {
            AdminTrackRequest
        }
    }
</script>