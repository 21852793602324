<template>
    <ApprovedLiquidations></ApprovedLiquidations>
</template>
<script>
    import ApprovedLiquidations from '@/components/ApprovedLiquidations.vue'
    export default {
        name: 'approved-liquidations',
        components: {
            ApprovedLiquidations
        }
    }
</script>