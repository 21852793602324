<template>
    <Create75Liquidation3></Create75Liquidation3>
</template>
<script>
    import Create75Liquidation3 from '@/components/Create75Liquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            Create75Liquidation3
        }
    }
</script>