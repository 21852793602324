<template>
    <EmployeeTrack25Liquidation></EmployeeTrack25Liquidation>
</template>
<script>
    import EmployeeTrack25Liquidation from '@/components/EmployeeTrack25Liquidation.vue'
    export default {
        name: 'employee-track-liquidation',
        components: {
            EmployeeTrack25Liquidation
        }
    }
</script>