<template>
    <EmployeeSign></EmployeeSign>
</template>
<script>
    import EmployeeSign from '@/components/EmployeeSign.vue'
    export default {
        name: 'employee-sign',
        components: {
            EmployeeSign
        }
    }
</script>