<template>
    <EmployeeView25Liquidation></EmployeeView25Liquidation>
</template>
<script>
    import EmployeeView25Liquidation from '@/components/EmployeeView25Liquidation.vue'
    export default {
        name: 'employee-sign',
        components: {
            EmployeeView25Liquidation
        }
    }
</script>