<template>
    <EmployeeApprove25Liquidation></EmployeeApprove25Liquidation>
</template>
<script>
    import EmployeeApprove25Liquidation from '@/components/EmployeeApprove25Liquidation.vue'
    export default {
        name: 'employee-sign',
        components: {
            EmployeeApprove25Liquidation
        }
    }
</script>