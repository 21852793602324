<template>
    <CreatePaymentLiquidation3></CreatePaymentLiquidation3>
</template>
<script>
    import CreatePaymentLiquidation3 from '@/components/CreatePaymentLiquidation3.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreatePaymentLiquidation3
        }
    }
</script>