<template>
    <EmployeeRequests></EmployeeRequests>
</template>
<script>
    import EmployeeRequests from '@/components/EmployeeRequests.vue'
    export default {
        name: 'employee-requests',
        components: {
            EmployeeRequests
        }
    }
</script>