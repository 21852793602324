<template>
    <EditPayment1></EditPayment1>
</template>
<script>
    import EditPayment1 from '@/components/EditPayment1.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditPayment1
        }
    }
</script>