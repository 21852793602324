<template>
    <EditRequest2></EditRequest2>
</template>
<script>
    import EditRequest2 from '@/components/EditRequest2.vue'
    export default {
        name: 'employee-sign',
        components: {
            EditRequest2
        }
    }
</script>