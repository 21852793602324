<template>
    <CreatePayment3></CreatePayment3>
</template>
<script>
    import CreatePayment3 from '@/components/CreatePayment3.vue'
    export default {
        name: 'create-payment-3',
        components: {
            CreatePayment3
        }
    }
</script>