<template>
    <CreatePayment2></CreatePayment2>
</template>
<script>
    import CreatePayment2 from '@/components/CreatePayment2.vue'
    export default {
        name: 'employee-liquidation',
        components: {
            CreatePayment2
        }
    }
</script>