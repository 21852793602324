<template>
    <MohPerDiem></MohPerDiem>
</template>
<script>
    import MohPerDiem from '@/components/MohPerDiem.vue'
    export default {
        name: 'funding-management',
        components: {
            MohPerDiem
        }
    }
</script>