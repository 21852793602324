<template>
  <div class="w-full h-screen bg-white overflow-x-hidden">
    <Navbar @sidebarDismissed=" (value) => {expand(value)}"  class=" w-full sticky top-0 z-50"/>
    <div>
      <Sidebar class="sidebar"/>
      <div :class="[!expandBody ? 'main px-5 space-y-2 margin mb-16' : 'main px-5 space-y-2 mb-16']">
        <!-- the top route and username grid -->
        <div class="justify-between flex pt-5 text-size-md font-medium text-gray-500 w-full">
          <span class=" text-emerald-500" >> Home > Payment Liquidation Step 4</span>
          <span v-if="employee !== null">{{ employee.firstname }} {{ employee.surname }} </span>
        </div>
        <!-- the requests charts -->
        <div class="grid gap-6 grid-rows-1 grid-cols-1">
          <div class=" shadow-md text-gray-400 col-span-full border hover:shadow-lg mt-4 bg-white">
            <!-- The chart title -->
            <div class="ml-8 flex mt-6 mb-4 items-center space-x-1">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-emerald-500 fill-current" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,24H3a3,3,0,0,1-3-3V0H2V21a1,1,0,0,0,1,1H24Z"/><rect x="14" y="11" width="2" height="9"/><rect x="6" y="11" width="2" height="9"/><rect x="18" y="6" width="2" height="14"/><rect x="10" y="6" width="2" height="14"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m12,12c3.309,0,6-2.691,6-6S15.309,0,12,0s-6,2.691-6,6,2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm9,17v5h-2v-5c0-1.654-1.346-3-3-3h-2l-1.333,2,1.333,6h-4l1.333-6-1.333-2h-2c-1.654,0-3,1.346-3,3v5h-2v-5c0-2.757,2.243-5,5-5h8c2.757,0,5,2.243,5,5Z"/>
              </svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M18.4,7.379a1.128,1.128,0,0,1-.769-.754h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H11a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5.683a3.614,3.614,0,0,1-3.646-2.981,3.456,3.456,0,0,1,1.376-3.313A3.021,3.021,0,0,0,4.4,11.141a6.113,6.113,0,0,1-.073-4.126A5.956,5.956,0,0,1,9.215,3.05,6.109,6.109,0,0,1,9.987,3a5.984,5.984,0,0,1,5.756,4.28,2.977,2.977,0,0,0,2.01,1.99,5.934,5.934,0,0,1,.778,11.09.976.976,0,0,0-.531.888h0a.988.988,0,0,0,1.388.915c4.134-1.987,6.38-7.214,2.88-12.264A6.935,6.935,0,0,0,18.4,7.379Z"/><path d="M18.707,16.707a1,1,0,0,0,0-1.414l-1.586-1.586a3,3,0,0,0-4.242,0l-1.586,1.586a1,1,0,0,0,1.414,1.414L14,15.414V23a1,1,0,0,0,2,0V15.414l1.293,1.293a1,1,0,0,0,1.414,0Z"/></svg> -->
              <!-- <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7,2h10c1.654,0,3,1.346,3,3H4c0-1.654,1.346-3,3-3Zm10,20H7c-1.654,0-3-1.346-3-3V7h16v12c0,1.654-1.346,3-3,3Zm0-11c0,.553-.448,1-1,1h-8c-.552,0-1-.447-1-1s.448-1,1-1h8c.552,0,1,.447,1,1Zm-4,4c0,.553-.448,1-1,1h-4c-.552,0-1-.447-1-1s.448-1,1-1h4c.552,0,1,.447,1,1Z"/></svg> -->
              <svg class="h-4 w-4 text-emerald-500 fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m13,5v2H5v-2h8Zm-7,6h6v-2h-6v2Zm17,4.764v5.236c0,1.654-1.346,3-3,3H1V0h17c1.654,0,3,1.346,3,3v8.764l2,4ZM3,22h12V2H3v20Zm18-5.764l-2-4V3c0-.551-.448-1-1-1h-1v20h3c.552,0,1-.449,1-1v-4.764Z"/>
              </svg>
              <p class="text-left text-size-md font-medium text-gray-600">Payment Liquidation Summary</p>
            </div>
            <!-- {{ selectedLiquidationPaymentData }} -->
            <!-- The activity creation steps -->
            <div class="flex items-center col-span-full mx-4 mt-5 pr-2">
              <span class="ml-4 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">1</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">2</span>
              <div class="flex-auto cursor-pointer text-size border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-0.5 cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">3</span>
              <div class="flex-auto cursor-pointer border-t-2 transition duration-500 ease-in-out border-emerald-600"></div>
              <span class="ml-0.5 mr-2 animate-pulse cursor-pointer text-size-sm font-medium bg-emerald-600 ring-emerald-600 ring-2 text-white py-0 px-1">4</span>
            </div>
            <!--  chart -->
            <div class="ml-8 pb-8 mt-4">
              <div
                class="ring-1 py-2 text-left cursor-pointer ring-gray-200 shadow-md mr-8 pl-1 chart-height"
              >
                <div class="px-1 py-3 space-y-2.5">
                  <div class="mb-5 px-4 py-2 grid grid-flow-row grid-cols-1 gap-6">
                    <!-- the selector tab -->
                    <div class="flex justify-center">
                      <div class="my-auto">
                        <button @click="switchView(1)" :class="[ currentView == 1 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Payment Details & Attachments</button>
                        <button @click="switchView(2)" :class="[ currentView == 2 ? 'shadow inline-block box-shadow text-size-sm px-20 py-2 bg-emerald-700  text-white font-medium leading-tight transform focus:bg-emerald-700 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out' : 'shadow inline-block box-shadow text-size-sm px-10 py-2 bg-emerald-600  text-white font-medium leading-tight transform focus:bg-emerald-600 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out']">View Spreadsheet</button>
                      </div>
                    </div>
                    <!-- the payment details -->
                    <table v-if="paymentLiquidationAttachments !== null && paymentLiquidationPaymentMethod !== undefined && currentView === 1" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                        <thead>
                          <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                            <td class="py-2.5 px-2 text-left text-white font-bold">Item</td>
                            <td class="py-2.5 px-2 text-left text-white font-bold">Description</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Focal Person</td>
                            <td class="py-2.5 px-2 border text-left">{{ selectedLiquidationPaymentData.firstname }} {{ selectedLiquidationPaymentData.surname }}</td>
                          </tr>
                          <tr class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left font-medium">Selected Payment Method</td>
                            <td class="py-2.5 px-2 border text-left">{{ paymentLiquidationPaymentMethod.name }}</td>
                          </tr>
                        </tbody>
                    </table>
                    <!-- attachments -->
                    <table v-if="paymentAttachments !== null && currentView === 1" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                        <thead>
                          <tr class="bg-emerald-600 text-size-xs pointer-events-none" style="">
                            <td class="py-2.5 px-2 text-left text-white font-bold">#</td>
                            <td class="py-2.5 px-2 text-left text-white font-bold">File Name</td>
                            <td class="py-2.5 px-2 border-l text-left text-white font-bold">File Size</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, i) in paymentLiquidationAttachments" :key="i" class=" text-gray-600 text-size-sm" style="">
                            <td class="py-2.5 px-2 border text-left">{{ (i + 1) }}</td>
                            <td class="py-2.5 px-2 border text-left">{{ data.realName }}</td>
                            <td class="py-2.5 px-2 border text-left">{{ fileSizeFormatter(data.size) }}</td>
                          </tr>
                        </tbody>
                    </table>
                    <!-- the spreadsheet -->
                    <div class=" overflow-y-auto">
                      <table v-if="paymentSpreadsheet !== null && currentView === 2" id="datatable" class="table-auto w-full shadow-md cursor-pointer">
                              <thead>
                                <tr class="bg-emerald-600 pointer-events-none text-size-xs" style="">
                                  <td class="py-3 px-2 border-l text-left text-white font-bold">Name</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Origin</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">NIB</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Travel Day Per Diem</td>

                                <td class="py-3 px-2 border-l text-left text-white font-bold">No Overnight Per Diem</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">No Overnight Days</td>

                                <td class="py-3 px-2 border-l text-left text-white font-bold">Overnight Per Diem</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Overnight Days</td>

                                <td class="py-3 px-2 border-l text-left text-white font-bold">Activity Day Per Diem</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Activity No Days</td>


                                <td class="py-3 px-2 border-l text-left text-white font-bold">Return Day Per Diem</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Other Fees</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Other Days</td>

                                <td class="py-3 px-2 border-l text-left text-white font-bold">Total</td>
                                
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Paid</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Difference</td>
                                <td class="py-3 px-2 border-l text-left text-white font-bold">Comments</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(data, i) in paymentLiquidationSpreadsheet" :key="i" class=" text-gray-600 text-size-sm" style="">
                                  <td class="py-3 px-2 border text-left">{{ data.fullname }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.origin.name }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.nib }}</td>
                                <!-- the travel day -->
                                <td v-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(data.travelPerDiem.allData.rate) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <!-- no overnight event day -->
                                <td v-if="data.noOvernightDays === 0" class="py-3 px-2 border text-left">0</td>
                                <td v-else-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(data.noOvernightPerDiem.allData.rate) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <td class="py-3 px-2 border text-left">{{ data.noOvernightDays }}</td>
                                <!-- overnight event day  -->
                                <td v-if="data.overnightDays === 0" class="py-3 px-2 border text-left">0</td>
                                <td v-else-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(data.overnightPerDiem.allData.rate) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <td class="py-3 px-2 border text-left">{{ data.overnightDays }}</td>
                                <!-- activity day -->
                                <td v-if="data.activityDays === 0" class="py-3 px-2 border text-left">0</td>
                                <td v-else-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(data.activityDaysPerDiem.allData.rate) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <td class="py-3 px-2 border text-left">{{ data.activityDays }}</td>
                                <!-- return day -->
                                <td v-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(data.returnDayPerDiem.allData.rate) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <!-- other -->
                                <td v-if="data.otherDays === 0" class="py-3 px-2 border text-left">0</td>
                                <td v-else-if="data.travelPerDiem.allData !== undefined" class="py-3 px-2 border text-left">{{ formatData(otherSum(data.otherFees)) }}</td>
                                <td v-else class="py-3 px-2 border text-left"></td>
                                <td v-if="data.otherDays === 'Total'" class="py-3 px-2 border text-left font-medium">{{ data.otherDays }}</td>
                                <td v-else class="py-3 px-2 border text-left">{{ data.otherDays }}</td>
                                <!-- total -->
                                <td v-if="data.otherDays === 'Total'" class="py-3 px-2 border text-left font-medium">{{ formatData(data.total) }}</td>
                                <td v-else class="py-3 px-2 border text-left">{{ formatData(data.total) }}</td>


                                <td v-if="data.otherDays === 'Total'" class="py-3 px-2 border text-left font-medium">{{ formatData(data.paid) }}</td>
                                <td v-else class="py-3 px-2 border text-left">{{ formatData(data.paid) }}</td>
                                <td class="py-3 px-2 border text-center font-medium">{{ data.difference }}</td>
                                <td class="py-3 px-2 border text-left">{{ data.comment }}</td>
                                </tr>
                              </tbody>
                      </table>
                    </div>
                  </div>
                <div class="w-full flex text-center mx-auto pt-1">
                  <div class="flex-1 mb-2">
                      <button
                        @click="back()"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Back
                      </button>
                      <button
                        @click="submit()"
                        class="shadow inline-block box-shadow text-size-sm px-16 py-2 bg-emerald-500  text-white font-medium leading-tight transform focus:bg-emerald-500 focus:outline-none focus:ring-0 active:bg-emerald-800 transition duration-150 ease-in-out">
                          Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the loader modal -->
    <Loader ref="loaderRef"/>
    <!-- the message loader -->
    <Message :title="title" :message="processMessage" ref="messageRef"/>
  </div>
</template>

<script>
import Navbar from '@/components/EmployeeNavbar.vue'
import Sidebar from '@/components/EmployeeSideBar.vue'
import { mapState } from 'vuex'
import { ajax } from 'rxjs/ajax'
import Message from './modals/Message.vue';
import Loader from './modals/Loader.vue';

export default {
  computed: {
    ...mapState([
      'employee',
      'paymentLiquidationAttachments',
      'paymentLiquidationPaymentMethod',
      'selectedLiquidationPaymentData',
      'paymentLiquidationSpreadsheet',
    ])
  },
  components: {
    Navbar,
    Sidebar,
    Loader,
    Message
  },
  setup () {
    return {
    }
  },
  name: 'admin-home',
  props: {
  },
  data: () => {
    return {
      title: 'Message Error',
      processMessage: '',
      documentsView: [true, false],
      currentView: 1,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    // removing the submenu items selection
    if (this.employee !== null) {
      this.$store.commit('mutateActiveListItemIndex', 300)
      this.$store.commit('mutateSelectedSubMenuItem', 304)
    }
  },
  methods: {
    otherSum (otherData) {
      if (Array.isArray(otherData)) {
        const size = otherData.length
        let total = 0

        for (let i = 0; i < size; i++) {
          const item = otherData[i]
          const rowTotal = item.allData.rate
          
          total += parseFloat(rowTotal)
        }
        return total
      }
      return 0
    },
    // file size converter
    fileSizeFormatter (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) {
        return '0 Bytes'
      }
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    // switch the view
    switchView (index) {
      this.documentsView[(index - 1)] = true
      this.currentView = index
    },
    // date difference in days
    dateDiff (startDate, endDate) {
      const date2 = new Date(endDate)
      const date1 = new Date(startDate)

      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // remove the time and time-zone details.
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return (Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1);
    },
    // format the table data
    formatData (data) {
      var formattedData
      // check if it is a number
      if (data !== null && data !== undefined && !isNaN(data) && String(data).length > 2) {
        // format the number to a currency format
        var currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MWK' })
        formattedData = currencyFormat.format(String(data)).substring(4)
      } else if (data !== null && data !== undefined && data.toString().includes('-') && !isNaN(Date.parse(data))) {
        // check if the data is a date
        // formating the dates supplied
        var textDate = new Date(data)
        var formattedTextDate = textDate.getDate() + ' ' + this.months[textDate.getMonth()] + ' ' + textDate.getFullYear()
        formattedData = formattedTextDate
      } else {
        formattedData = data
      }
      return formattedData
    },
    // calculates the date difference in days for the schedule days
    dateDiffInDays () {
        // the date which the activity starts
        const days = []
        const scheduleSize = this.scheduleData.length

        for (let i = 0; i < scheduleSize; i++) {
          const startDate = this.scheduleData[i].startDate
          days.push(startDate)
        }

        // sort the days and get the minimum day
        days.sort()
        const miniDate = days[0]
        const dateFrom = new Date(miniDate)


        // today's date
        const dateTo = new Date()

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // remove the time and time-zone details.
        const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
        const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      },
    // move to the next page
    back () {
      this.$router.push('/create-payment-liquidation-3')
    },
    // expands or collapses the main body
    expand (value) {
      this.expandBody = value
    },
    clearData () {
      this.$store.commit('mutateSelectedLiquidationPaymentData', null)
      this.$store.commit('mutateLiquidationPayment', null)
      this.$store.commit('mutatePaymentLiquidationPaymentMethod', null)
      this.$store.commit('mutatePaymentLiquidationAttachments', [])
      this.$store.commit('mutatePaymentLiquidationSpreadsheet', null)
    },
    // submits the signature
    submit () {
      if (!this.documentsView.every(value => value === true)) {
        var message = 'Before resubmitting your request: \n'
        if (this.documentsView[1] === false) {
          message += '  - Please view & verify your Spreadsheet. \n'
        }
        message += 'Click on the buttons above to view & verify the entered details.'
        this.processMessage = message
        // open the error modal
        this.$refs.messageRef.openModal()
      } else {
        // open the loading modal
        this.$refs.loaderRef.openModal()

        const observable = ajax({
          url: process.env.VUE_APP_BASE_URL + '/payment-liquidation/insert',
          method: 'POST',
          body: {
            employee_id: this.selectedLiquidationPaymentData.employee_id,
            attachments: JSON.stringify(this.paymentLiquidationAttachments),
            payment_method_id: this.paymentLiquidationPaymentMethod.id,
            sheet: JSON.stringify(this.paymentLiquidationSpreadsheet),
            payment_id: this.selectedLiquidationPaymentData.id
          },
          headers: {
            Authorization: `Bearer ${this.employee.token}`
          }
        })

        const observer = {
          next: () => {
            this.currentView = 100
            this.clearData()
            // move to the profile page
            this.$router.push('/payment-liquidations')
          },
          error: () => {
            // close the loader modal
            this.$refs.loaderRef.closeModal()
            // showing the dialog for the error
            this.processMessage = 'Failed to submit the payment, please try again.'
            // open the error modal
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
            
          }
        }

        observable.subscribe(observer)
      }
    }
  }
}
</script>

<style>

#chart-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 40vh;
}

</style>
