<template>
    <Edit75Liquidation1></Edit75Liquidation1>
</template>
<script>
    import Edit75Liquidation1 from '@/components/Edit75Liquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            Edit75Liquidation1
        }
    }
</script>