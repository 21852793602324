<template>
    <CreatePaymentLiquidation1></CreatePaymentLiquidation1>
</template>
<script>
    import CreatePaymentLiquidation1 from '@/components/CreatePaymentLiquidation1.vue'
    export default {
        name: 'employee-sign',
        components: {
            CreatePaymentLiquidation1
        }
    }
</script>